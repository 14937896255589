import * as _ from "lodash";
import axios from "axios";

import { numberToStringFormat } from "./utils";
import { objectAttributeInLanguage } from "@/mixins";
import { getAllMatches, parseSentence } from "@/filterSentenceParser";

const langSuffixTable = {
  de: "_De",
  fr: "_Fr",
  en: "_En",
};

export const CONTINENTS = ["AF", "AS", "EU", "NA", "SA", "OC"];

export function buildEntityQuery(
  mainPart,
  numRows = 1000,
  solrCore = "search",
  category = "stats",
) {
  let basePart = `/solr/${solrCore}/select/?rows=${numRows}`;
  if (mainPart) {
    return `${basePart}&q=Category:${category}+AND+${mainPart}`;
  }
  return basePart;
}

export function buildQuery(
  entityPart,
  selectedParts,
  ignoreParts,
  numRows = 2000,
  solrCore = "search",
  category = "stats",
) {
  const queryParts = _.chain(selectedParts)
    .keys()
    .map((partsId) => {
      if (_.includes(ignoreParts, partsId)) {
        return null;
      }

      const value = selectedParts[partsId];

      if (value.solrPartOverride) {
        return value.solrPartOverride;
      }

      // check for date range which is in two separate `selectedParts`
      const value2 = _.find(selectedParts, (v) => {
        return v.solrAttribute === value.solrAttribute && v.id !== value.id;
      });
      if (value2) {
        const min = Math.min(value.solrValue, value2.solrValue);
        const max = Math.max(value.solrValue, value2.solrValue);
        return `${value.solrAttribute}:[${min} TO ${max}]`;
      }

      if (value.solrValue === "-" || value.solrAttribute === "") {
        return null;
      }

      return buildAttributePart(value);
    })
    .filter((k) => {
      return k;
    })
    .uniq()
    .value();

  const baseQuery = buildEntityQuery(entityPart, numRows, solrCore, category);

  if (queryParts.length > 0) {
    return baseQuery + `+AND+${queryParts.join("+AND+")}`;
  }
  return baseQuery;
}

export function buildAttributePart(selected) {
  return `${selected.solrAttribute}:"${selected.solrValue}"`;
}

const dataFields = [
  "Grants",
  "Grants_Submitted",
  "Funding",
  "Funding_Submitted",
  "Number",
  "CountryIsoCode",
  "ContinentCode",
  "CallDecisionYear",
  "DecisionYear",
];

export function createDataFromResponse(solrResponse, levelInfo) {
  let level1LangFields = [];
  if (levelInfo.level1Field) {
    level1LangFields = ["_De", "_En", "_Fr"].map((lang) => {
      return `${levelInfo.level1Field}${lang}`;
    });
  }

  let level2LangFields = [];
  if (levelInfo.level2Field) {
    level2LangFields = ["_De", "_En", "_Fr"].map((lang) => {
      return `${levelInfo.level2Field}${lang}`;
    });
  }

  const pickFields = [...level1LangFields, ...level2LangFields, ...dataFields];
  return _.map(
    _.sortBy(solrResponse.response.docs, [
      `${levelInfo.level1Field}SortCode`,
      `${levelInfo.level2Field}SortCode`,
      `${levelInfo.level1Field}_De`,
      `${levelInfo.level1Field}_Fr`,
      `${levelInfo.level1Field}_En`,
      `${levelInfo.level2Field}_De`,
      `${levelInfo.level2Field}_Fr`,
      `${levelInfo.level2Field}_En`,
      `${levelInfo.level1Field}Id`,
      `${levelInfo.level1Field}Code`,
      `${levelInfo.level2Field}Id`,
      `${levelInfo.level2Field}Code`,
    ]),
    (d) => {
      return _.pick(d, pickFields);
    },
  );
}

export function createPictureData(
  parsedSolrResponse,
  levelInfo,
  selectedParts,
  selectedLang = "de",
) {
  let numberDataField = "Number";

  if (!selectedParts["*1"].solrAttribute && !selectedParts["*2"].solrAttribute) {
    const s1 = selectedParts["*1"].solrValue;
    const s2 = selectedParts["*2"].solrValue;

    if (s1 === "1" && s2 === "1") {
      numberDataField = "Grants";
    } else if (s1 === "2" && s2 === "1") {
      numberDataField = "Grants_Submitted";
    } else if (s1 === "1" && s2 === "2") {
      numberDataField = "Funding";
    } else if (s1 === "2" && s2 === "2") {
      numberDataField = "Funding_Submitted";
    }
  } else if (!selectedParts["*1"].solrAttribute) {
    const s1 = selectedParts["*1"].solrValue;
    if (s1 === "1") {
      numberDataField = "Grants";
    } else if (s1 === "2") {
      numberDataField = "Funding";
    }
  }

  const langSuffix = langSuffixTable[selectedLang];

  let levelField = levelInfo.level2Field + langSuffix;
  if (
    levelInfo.fieldIndex &&
    selectedParts[levelInfo.fieldIndex].solrAttribute.replace(/(Id$|Code$)/, "") ===
      levelInfo.level2Field
  ) {
    levelField = levelInfo.level1Field + langSuffix;
  }

  let data = _.filter(
    _.map(parsedSolrResponse, (p) => {
      return _.assign(
        {
          label: p[levelField],
          count: parseInt(p[numberDataField] || 0, 10),
        },
        _.pick(p, dataFields),
      );
    }),
    (p) => {
      return p.label;
    },
  );

  return data.map((d) => {
    if (d.DecisionYear) {
      d.CallDecisionYear = d.DecisionYear;
    }
    return d;
  });
}

export function createSelectedPartsInitial(parsedEntries) {
  let selectedParts = {};
  Object.keys(parsedEntries).forEach((k) => {
    selectedParts[k] =
      parsedEntries[k].selectionValues[parsedEntries[k].selectedIndex || 0];
  });
  return selectedParts;
}

export function createSelectedSentence(parsedSentence, selectedParts) {
  let result = parsedSentence;
  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });
  return result;
}

function getResultNumbers(selectedParts, pictureData) {
  let numberField = "Number";
  let moneyField = "";

  // remove `Total` entry from data
  const filteredPictureData = pictureData.filter((p) => {
    return p.label !== "Total";
  });

  if (!selectedParts["*1"].solrAttribute && selectedParts["*2"].solrAttribute) {
    numberField = "Grants";
    moneyField = "Funding";
  } else if (
    !selectedParts["*1"].solrAttribute &&
    selectedParts["*1"].solrValue === "1"
  ) {
    numberField = "Grants";
    moneyField = "Funding";
  } else if (
    !selectedParts["*1"].solrAttribute &&
    selectedParts["*1"].solrValue === "2"
  ) {
    numberField = "Grants_Submitted";
    moneyField = "Funding_Submitted";
  }

  let number = _.sumBy(filteredPictureData, (p) => {
    return parseInt(p[numberField], 10) || 0;
  });
  let moneySum = _.sumBy(filteredPictureData, (p) => {
    return parseInt(p[moneyField] || 0, 10);
  });

  // const totalEntry = _.find(filteredPictureData, { label: "Total" });
  // if (totalEntry) {
  //   number = parseInt(totalEntry[numberField], 10);
  //   moneySum = parseInt(totalEntry[moneyField], 10);
  // }

  return { totalNumber: number, totalMoneySum: moneySum };
}

export function createResultSentence(
  cfSolrSentences,
  selectedParts,
  totalNumber,
  totalMoneySum,
  selectedLanguage = "de",
  solrEntity = "",
) {
  let resultEntry = _.find(cfSolrSentences, {
    Entity: "CoreFigures_ResultSentences",
    Condition: `*1==${selectedParts["*1"].solrValue}`,
  });

  if (!resultEntry) {
    // FIXME: workaround for selectible success chart
    resultEntry = _.find(cfSolrSentences, {
      Entity: "CoreFigures_ResultSentences",
      Condition: `*1==2`,
    });
  }

  let result = resultEntry[langFilterSentenceKey[selectedLanguage]];

  // FIXME: hardcoded fix for multiple and single years
  const resultSentenceMultipleYearsWorkaround = true;
  if (resultSentenceMultipleYearsWorkaround) {
    if (
      [
        "ResearchInstitutionsFundingInstrumentsL1",
        "FundingInstruments",
        "Disciplines",
      ].includes(solrEntity)
    ) {
      if (selectedParts["*5"].solrValue !== selectedParts["*6"].solrValue) {
        result = result.replace("in *5", "between *5 and *6");
        result = result.replace("Im Jahr *5", "In den Jahren *5 bis *6");
        result = result.replace("En *5", "Entre *5 et *6");
      }
    }
    if (solrEntity === "SupplementaryFunding") {
      if (selectedParts["*2"].solrValue !== selectedParts["*3"].solrValue) {
        result = result.replace("In *2", "Between *2 and *3");
        result = result.replace("Im Jahr *2", "In den Jahren *2 bis *3");
        result = result.replace("En *2", "Entre *2 et *3");
      }
    }
    if (solrEntity === "Internationality") {
      if (selectedParts["*3"].solrValue === selectedParts["*4"].solrValue) {
        result = result.replace("Between *3 and *4", "In *3");
        result = result.replace("In den Jahren *3 bis *4", "Im Jahr *3");
        result = result.replace("Entre *3 et *4", "En *3");
      }
    }
  }

  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });

  result = result.replace(
    "#ANZAHL#",
    `<span class="cf-result-number">${numberToStringFormat(
      totalNumber,
      selectedLanguage,
    )}</span>`,
  );
  result = result.replace(
    "#SUMME#",
    `<span class="cf-result-number" data-original="${totalMoneySum}">${numberToStringFormat(
      totalMoneySum,
      selectedLanguage,
    )}</span>`,
  );

  return result;
}

export function createResultSentenceMedian(
  cfSolrSentences,
  selectedParts,
  pictureData,
  selectedLanguage = "de",
) {
  let result = _.find(cfSolrSentences, {
    Entity: "CoreFigures_ResultSentences",
    Condition: `*1==Total`,
  })[langFilterSentenceKey[selectedLanguage]];

  _.forEach(["*1", "*2", "*3", "*4", "*5", "*6", "*7"], (n) => {
    if (selectedParts[n]) {
      result = result.replace(n, selectedParts[n].text);
    }
  });

  if (pictureData && pictureData.length) {
    const medianValue = Math.floor(_.sumBy(pictureData, "value") / 2);
    let runningValue = 0;
    let medianAge = pictureData[0].name;

    for (let d of pictureData) {
      runningValue += d.value;
      if (runningValue >= medianValue) {
        medianAge = d.name;
        break;
      }
    }

    result = result.replace(
      "#AGE#",
      `<span class="cf-result-number">${medianAge}</span>`,
    );
    result = result.replace(
      "#ANZAHL#",
      `<span class="cf-result-number">${numberToStringFormat(
        _.sumBy(pictureData, "value"),
        selectedLanguage,
      )}</span>`,
    );
  } else {
    result = result
      .replace("#AGE#", `<span class="cf-result-number">0</span>`)
      .replace("#ANZAHL#", `<span class="cf-result-number">0</span>`);
  }

  return result;
}

export function querySentences(
  sentenceEntity,
  solrCore = "search",
  singleYear = false,
) {
  return axios({
    url: `/solr/${solrCore}/select/?q=Entity%3A*_*Sentences+AND+Page:${sentenceEntity}`,
  }).then((response) => {
    let solrSentences = response.data.response.docs;

    if (solrSentences[0]) {
      if (solrSentences[0].German.includes("2023-2005")) {
        solrSentences[0].German = solrSentences[0].German.replace(
          "2023-2005",
          "2023--2005",
        );
      }
      if (solrSentences[0].French.includes("2023-2005")) {
        solrSentences[0].French = solrSentences[0].French.replace(
          "2023-2005",
          "2023--2005",
        );
      }
      if (solrSentences[0].English.includes("2023-2005")) {
        solrSentences[0].English = solrSentences[0].English.replace(
          "2023-2005",
          "2023--2005",
        );
      }
    }

    /* FIXME: hardcoded test data
    if (sentenceEntity === "Demographics") {
      solrSentences[1].German =
        "Die #ANZAHL# *2 im Jahr *3 waren im Median #AGE# Jahre alt.";
      solrSentences[2].German =
        "Die #ANZAHL# *2 (*1) im Jahr *3 waren im Median #AGE# Jahre alt.";
      solrSentences[3].German =
        "Die #ANZAHL# *2 (*1) im Jahr *3 waren im Median #AGE# Jahre alt.";
    }
     */

    // TODO: hardcoded test data
    // if(sentenceEntity === 'Internationality') {
    //   solrSentences[0].German = '*1[{Zielländer von Mobilitätsstipendien;Type;Fellowship},{Länder der Forschungszusammenarbeiten;Type;Collaboration}] in *2[{allen Kontinenten;CountryId;00000000-0000-0000-0000-000000000000},{Afrika;ContinentCode;AF},{Asien;ContinentCode;AS},{Nordamerika;ContinentCode;NA},{Südamerika;ContinentCode;SA},{Europa;ContinentCode;EU},{Ozeanien;ContinentCode;OC}] in den Jahren *3[2020--2005] bis *4[2020--2005]';
    //   solrSentences[1].German = 'In den Jahren *3 bis *4 lag die Anzahl Mobilitätsstipendien in *2 bei #ANZAHL#.';
    //   solrSentences[2].German = 'In den Jahren *3 bis *4 lag die Anzahl Projektzusammenarbeiten in *2 bei #ANZAHL#.';
    // }

    // FIXME remove hardcoded sentence entity
    // Add multiple call decision years to sentence entity

    /*
    if (
      [
        "FundingInstruments",
        "ResearchInstitutions",
        "Disciplines",
        "SupplementaryFunding",
      ].includes(sentenceEntity)
    ) {
      if (!singleYear) {
        const germanRegex = /im Jahr \*(?<yearKey>.)\[20[0-2]\d--20[0-2]\d]$/;
        solrSentences[0].German = solrSentences[0].German.replace(
          germanRegex,
          (match, yearKey) => {
            const yearKeyNext = parseInt(yearKey, 10) + 1;

            solrSentences[1].German = solrSentences[1].German.replace(
              `Im Jahr *${yearKey}`,
              `In den Jahren *${yearKey} bis *${yearKeyNext}`,
            );
            solrSentences[2].German = solrSentences[2].German.replace(
              `Im Jahr *${yearKey}`,
              `In den Jahren *${yearKey} bis *${yearKeyNext}`,
            );

            return `in den Jahren *${yearKey}[2023--2005] bis *${yearKeyNext}[2023--2005]`;
          },
        );

        const frenchRegex = /en \*(?<yearKey>.)\[20[0-2]\d--20[0-2]\d]$/;
        solrSentences[0].French = solrSentences[0].French.replace(
          frenchRegex,
          (match, yearKey) => {
            const yearKeyNext = parseInt(yearKey, 10) + 1;

            solrSentences[1].French = solrSentences[1].French.replace(
              `En *${yearKey}`,
              `Entre *${yearKey} et *${yearKeyNext}`,
            );
            solrSentences[2].French = solrSentences[2].French.replace(
              `En *${yearKey}`,
              `Entre *${yearKey} et *${yearKeyNext}`,
            );

            return `entre *${yearKey}[2023--2005] et *${yearKeyNext}[2023--2005]`;
          },
        );

        const englishRegex = /\*(?<yearKey>.)\[20[0-2]\d--20[0-2]\d]$/;
        solrSentences[0].English = solrSentences[0].English.replace(
          englishRegex,
          (match, yearKey) => {
            const yearKeyNext = parseInt(yearKey, 10) + 1;

            solrSentences[1].English = solrSentences[1].English.replace(
              `in *${yearKey}`,
              `between *${yearKey} and *${yearKeyNext}`,
            );
            solrSentences[2].English = solrSentences[2].English.replace(
              `in *${yearKey}`,
              `between *${yearKey} and *${yearKeyNext}`,
            );

            return `between *${yearKey}[2023--2005] and *${yearKeyNext}[2023--2005]`;
          },
        );
      }

      // TODO used for comparison chart, which is not used yet
      // const fundingGrantRegex = /\*1\[{(.*?);1},{(.*?);2}]/;
      // solrSentences[0].German = solrSentences[0].German.replace(
      //   fundingGrantRegex,
      //   (match, approvedText, requestedText) => {
      //     // return `*1[{${approvedText};1},{${requestedText};2},{Vergleiche;3}]`;
      //     return `*1[{${approvedText};1},{${requestedText};2}]`;
      //   },
      // );
      // solrSentences[0].French = solrSentences[0].French.replace(
      //   fundingGrantRegex,
      //   (match, approvedText, requestedText) => {
      //     // return `*1[{${approvedText};1},{${requestedText};2},{comparaison;3}]`;
      //     return `*1[{${approvedText};1},{${requestedText};2}]`;
      //   },
      // );
      // solrSentences[0].English = solrSentences[0].English.replace(
      //   fundingGrantRegex,
      //   (match, approvedText, requestedText) => {
      //     // return `*1[{${approvedText};1},{${requestedText};2},{Compare;3}]`;
      //     return `*1[{${approvedText};1},{${requestedText};2}]`;
      //   },
      // );
    }
     */

    if (solrSentences.length <= 0) {
      console.error(`could not find filter sentence for ${sentenceEntity}!`);
    }

    return solrSentences;
  });
}

const langFilterSentenceKey = {
  fr: "French",
  en: "English",
  de: "German",
};

export function fetchFullKeyFiguresData(
  selectedLanguage,
  cfPageData,
  routeQuery = {},
  singleYear = false,
) {
  let cfSelectedParts = {};
  let cfParseSentenceResult = {};
  let cfSolrSentences = [];

  return querySentences(cfPageData.sentenceEntity, "search", singleYear).then(
    (solrSentences) => {
      cfSolrSentences = solrSentences;

      if (solrSentences.length <= 0) {
        return new Promise.resolve({
          cfSolrSentences: cfSolrSentences,
        });
      }

      let filterSentence = _.find(solrSentences, {
        Entity: "CoreFigures_FilterSentences",
      })[langFilterSentenceKey[selectedLanguage]];

      // TODO: hardcoded test filter sentence data
      if (cfPageData.solrEntity === "Demographics") {
        if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
          filterSentence = filterSentence.replace(
            "{male;Gender;m},",
            "{male;Gender;m},{non-binary;Gender;x},",
          );
          // filterSentence = filterSentence.replace('*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m}]', '*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m},{Comparison Female and Male;Gender;Total}]')
          // filterSentence = filterSentence.replace('*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}]', '*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}, {Vergleich Frauen und Männer;Gender;Total}]')
          // filterSentence = filterSentence.replace('*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m}]', '*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m},{Comparaison Femmes et Hommes;Gender;Total}]')
        }
      }

      if (cfPageData.solrEntity === "FundingInstruments") {
        if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
          filterSentence = filterSentence.replace(
            "{men;ResponsibleApplicantGender;m}",
            "{men;ResponsibleApplicantGender;m},{non-binary persons;ResponsibleApplicantGender;x}",
          );
        }
        // if (!filterSentence.includes("Gender;x}") && selectedLanguage === "en") {
        //   filterSentence = filterSentence.replace(
        //     "{male;Gender;m},",
        //     "{male;Gender;m},{non-binary;Gender;x},",
        //   );
        // filterSentence = filterSentence.replace('*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m}]', '*1[{All;Gender;Total},{Female;Gender;f},{Male;Gender;m},{Comparison Female and Male;Gender;Total}]')
        // filterSentence = filterSentence.replace('*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}]', '*1[{Alle;Gender;Total}, {Frauen;Gender;f}, {Männer;Gender;m}, {Vergleich Frauen und Männer;Gender;Total}]')
        // filterSentence = filterSentence.replace('*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m}]', '*1[{Total;Gender;Total},{Femmes;Gender;f},{Hommes;Gender;m},{Comparaison Femmes et Hommes;Gender;Total}]')
      }

      // if (cfPageData.solrEntity === "FundingInstruments") {
      //   filterSentence = "*4[{allen Förderungsinstrumenten;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000},{der Projektförderung;FundingInstrumentGaLevel1Id;73FAD883-E405-4E14-8322-38DC5441D1C0},{der Karriereförderung;FundingInstrumentGaLevel1Id;37AC4964-CACD-4529-A963-C74E6C0EAEE7},{der Programmförderung;FundingInstrumentGaLevel1Id;336AED0A-4277-4BCC-8DE9-F2A0CDFE3895},{der Infrastrukturförderung;FundingInstrumentGaLevel1Id;06B677BA-53EA-4A5E-B7FF-0BEF952A01D0},{der Wissenschaftskommunikation;FundingInstrumentGaLevel1Id;56098B1D-5B8C-4AC1-904A-66EE7B75845C}] *1[{Bewilligte;1},{Beantragte;2}] *2[{Projekte;1},{finanzielle Mittel;2}] von *3[{allen Gesuchstellenden;ResponsibleApplicantGender;Total},{Frauen;ResponsibleApplicantGender;f},{Männern;ResponsibleApplicantGender;m}] im Jahr *5[2021--2005]";
      // }

      cfParseSentenceResult = parseSentence(
        filterSentence,
        cfPageData.solrLevelAttribute,
        cfPageData.solrAttributeYear,
      );

      // if (cfPageData.solrEntity === "FundingInstruments") {
      //   cfParseSentenceResult.levelInfo.highlight = true;
      // }

      _.forEach(cfParseSentenceResult.parsedEntries, (e) => {
        const routeIndexKey = e.id.replace("*", "s");
        e.selectedIndex = routeQuery[routeIndexKey] || 0;

        if (!singleYear) {
          // set a date range for sentences with multiple call decision years
          if (cfPageData.entityUrl === "internationality" && e.id === "*3") {
            // selected 5th year entry for internationality date range
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "fundingInstruments" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "researchInstitutions" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "disciplines" && e.id === "*5") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
          if (cfPageData.entityUrl === "supplementalFunding" && e.id === "*2") {
            e.selectedIndex = routeQuery[routeIndexKey] || 6;
          }
        }

        e.selectedIndex = parseInt(e.selectedIndex);
        if (e.selectedIndex >= e.selectionValues.length) {
          e.selectedIndex = 0;
        }
      });

      cfSelectedParts = createSelectedPartsInitial(cfParseSentenceResult.parsedEntries);

      return fetchKeyFiguresData(
        selectedLanguage,
        cfPageData,
        cfParseSentenceResult,
        cfSelectedParts,
        cfSolrSentences,
      );
    },
  );
}

export function fetchKeyFiguresData(
  selectedLanguage,
  cfPageData,
  cfParseSentenceResult,
  cfSelectedParts,
  cfSolrSentences,
) {
  let cfPictureData = null;
  let cfResultSentence = "";
  let cfSolrQuery = "";

  function createResult() {
    return {
      cfSelectedParts: cfSelectedParts,
      cfPictureData: cfPictureData,
      cfParseSentenceResult: cfParseSentenceResult,
      cfSolrSentences: cfSolrSentences,
      cfResultSentence: cfResultSentence,
      cfSolrQuery: cfSolrQuery,
    };
  }

  const ignoreParts = _.chain(cfSelectedParts)
    .values()
    .filter((p) => {
      return !p.solrAttribute;
    })
    .map((p) => {
      return p.id;
    })
    .value();

  if (ignoreParts.length > 2) {
    console.warn("mehr als 2 `ignoreParts` !!!");
  }

  // always fetch country data directly for internationality page
  if (cfPageData.entityUrl === "internationality") {
    cfSelectedParts["*2"].solrPartOverride =
      "(" +
      _.join(
        _.map(CONTINENTS, (cc) => {
          return `ContinentCode:${cc}`;
        }),
        "+OR+",
      ) +
      ")";
  }

  if (cfPageData.entityUrl === "demographics") {
    if (cfSelectedParts["*1"].index === 4) {
      ignoreParts.push("*1");
    }
  }

  cfSolrQuery = buildQuery(
    `Entity:${cfPageData.solrEntity}`,
    cfSelectedParts,
    ignoreParts,
  );

  return axios({
    url: cfSolrQuery,
  }).then((response) => {
    if (cfPageData.entityUrl === "demographics") {
      const genders = _.uniq(_.map(response.data.response.docs, "Gender"));

      let dataObject = {};
      genders.forEach((g) => {
        dataObject[g] = {};
      });

      _.forEach(_.range(20, 76), (n) => {
        genders.forEach((g) => {
          dataObject[g][n] = 0;
        });
      });
      genders.forEach((g) => {
        dataObject[g][76] = 0;
      });

      // merge <20 and >75
      _.forEach(response.data.response.docs, (d) => {
        if (d.Age <= 20) {
          dataObject[d.Gender][20] = d.NumberOfPersons + (dataObject[20] || 0);
        } else if (d.Age >= 75) {
          dataObject[d.Gender][75] = d.NumberOfPersons + (dataObject[75] || 0);
        } else {
          dataObject[d.Gender][d.Age] = d.NumberOfPersons;
        }
      });

      // try to add total value
      // genders.forEach((g) => {
      //   dataObject[g][76] = 4500;
      // });

      const dataArray = _.map(_.keys(dataObject[genders[0]]), (key) => {
        const result = {
          name: key,
          value: dataObject[genders[0]][key],
        };
        genders.forEach((g) => {
          result[g] = dataObject[g][key];
        });
        return result;
      });

      if (response.data.response.docs.length <= 0) {
        cfPictureData = [];
      } else {
        cfPictureData = _.orderBy(dataArray, "name", "desc");
      }

      cfResultSentence = createResultSentenceMedian(
        cfSolrSentences,
        cfSelectedParts,
        cfPictureData,
        selectedLanguage,
      );

      return createResult();
    } else if (cfPageData.entityUrl === "internationality") {
      let parsedSolrResponse = createDataFromResponse(
        response.data,
        cfParseSentenceResult.levelInfo,
      );

      // remove unused "Total" parts from data
      parsedSolrResponse = _.filter(parsedSolrResponse, (e) => {
        return e.Country_De !== "Total";
      });

      // convert Number from string to number
      parsedSolrResponse = _.map(parsedSolrResponse, (e) => {
        e.Number = parseInt(e.Number, 10);
        return e;
      });

      const pictureDataWithDoubleCountries = _.reduce(
        parsedSolrResponse,
        (result, country) => {
          if (!_.find(result, { CountryIsoCode: country.CountryIsoCode })) {
            country.Number = _.sumBy(
              _.filter(parsedSolrResponse, {
                CountryIsoCode: country.CountryIsoCode,
              }),
              "Number",
            );
            return result.concat(country);
          }
          return result;
        },
        [],
      );

      const langSuffix = langSuffixTable[selectedLanguage];
      cfPictureData = _.map(pictureDataWithDoubleCountries, (c) => {
        c.label = c["Country" + langSuffix];
        c.labelContinent = c["Continent" + langSuffix];
        c.count = parseInt(c.Number, 10);

        if (c.CountryIsoCode === "KV") {
          // replace country code for Kosovo
          c.CountryIsoCode = "XK";
        }
        return c;
      });

      // calculate total number based on selected continent
      const selectedContinent = cfSelectedParts["*2"].solrValue;
      let totalNumber = _.sumBy(cfPictureData, "count");
      if (_.includes(CONTINENTS, selectedContinent)) {
        totalNumber = _.sumBy(
          _.filter(cfPictureData, { ContinentCode: selectedContinent }),
          "count",
        );
      }

      cfResultSentence = createResultSentence(
        cfSolrSentences,
        cfSelectedParts,
        totalNumber,
        0,
        selectedLanguage,
        cfPageData.solrEntity,
      );

      return createResult();
    } else {
      const parsedSolrResponse = createDataFromResponse(
        response.data,
        cfParseSentenceResult.levelInfo,
      );

      cfPictureData = createPictureData(
        parsedSolrResponse,
        cfParseSentenceResult.levelInfo,
        cfSelectedParts,
        selectedLanguage,
      );

      const numbers = getResultNumbers(cfSelectedParts, cfPictureData);
      cfResultSentence = createResultSentence(
        cfSolrSentences,
        cfSelectedParts,
        numbers.totalNumber,
        numbers.totalMoneySum,
        selectedLanguage,
        cfPageData.solrEntity,
      );

      return createResult();
    }
  });
}

export function loadKpiSentence(selectedLanguage, addTags = true) {
  return axios
    .all([querySentences("Kpi"), axios.get("/solr/search/select/?q=Entity%3AKpi")])
    .then(([r1, r2]) => {
      let kpiSentence = r1[0][langFilterSentenceKey[selectedLanguage]];
      //log.debug(this.kpiSentence);

      const kpiData = r2.data.response.docs;
      //log.debug(kpiData);

      const re = /\*{KpiId; (.*?)}/g;
      const results = getAllMatches(kpiSentence, re);
      //log.debug(results);

      _.forEach(results, (kpiResult) => {
        const kpiId = kpiResult.groups[0];
        const value = _.find(kpiData, { id: kpiId }).Value;
        if (addTags) {
          kpiSentence = kpiSentence.replace(
            kpiResult.match,
            `<span class="cf-result-number">${numberToStringFormat(
              value,
              selectedLanguage,
            )}</span>`,
          );
        } else {
          kpiSentence = kpiSentence.replace(
            kpiResult.match,
            `${numberToStringFormat(value, selectedLanguage)}`,
          );
        }
      });

      return kpiSentence;
    });
}

export function loadDataStories(lang, tagIds, limit, ignoreStory) {
  const queryStoryIds = function () {
    const fields = ["DataStoryName", "PublicationDate", "Languages", "Language", "id"];

    const selectedLanguage = lang;
    let query = buildEntityQuery(`Entity:DataStories`, limit ? limit * 3 + 3 : 150);

    if (tagIds && tagIds.length > 0) {
      const solrTagPart = _.join(
        _.map(tagIds, (t) => {
          return `Tags:*${t}*`;
        }),
        "+OR+",
      );
      query += `+AND+(${solrTagPart})`;
    }

    query +=
      "&sort=PublicationDate%20desc,DataStoryName%20asc&fl=" + _.join(fields, ",");

    // ignore stories with publication date in future
    query += "&fq=PublicationDate:[2020-01-01T00:00:00Z%20TO%20NOW]";

    return axios.get(query).then((response) => {
      let distinctStories = _.reduce(
        response.data.response.docs,
        function (stories, story) {
          if (
            stories.length &&
            stories[stories.length - 1].DataStoryName === story.DataStoryName
          ) {
            // check language preference and priority
            // TODO: language priority en,fr,de
            if (story.Language === _.upperCase(selectedLanguage)) {
              stories[stories.length - 1] = story;
            }
          } else {
            stories.push(story);
          }
          return stories;
        },
        [],
      );

      if (ignoreStory) {
        distinctStories = _.reject(distinctStories, {
          DataStoryName: ignoreStory,
        });
      }

      return _.map(distinctStories, (s) => {
        return s.id;
      });
    });
  };

  return queryStoryIds().then((storyIds) => {
    if (storyIds.length === 0) {
      return [];
    }

    const solrFields = [
      "Author",
      "AuthorURL",
      "Category",
      "Created",
      "DOI",
      "DataStoryCategory",
      "DataStoryName",
      "Entity",
      "GitHub_URL",
      "Language",
      "Languages",
      "Modified",
      "Name",
      "PublicationDate",
      "ShortDescription_De",
      "ShortDescription_En",
      "ShortDescription_Fr",
      "Tags",
      "ThumbnailExtension",
      "Title_De",
      "Title_En",
      "Title_Fr",
      "TopStory",
      "id",
      "ThumbnailLink",
    ];

    const query =
      buildEntityQuery(
        `Entity:DataStories+AND+(` +
          _.join(
            _.map(storyIds, (n) => {
              return `id:${n}`;
            }),
            "+OR+",
          ),
        limit ? limit : 100,
      ) +
      ")&sort=PublicationDate%20desc&fl=" +
      _.join(solrFields, ",");

    return axios.get(query).then((response) => {
      //console.log(response);
      //this.stories = response.data.response.docs;
      return _.map(response.data.response.docs, (s, index) => {
        s.title =
          objectAttributeInLanguage(s, "Title", lang) ||
          s.Title_En ||
          s.Title_Fr ||
          s.Title_De;
        s.shortDescription = _.truncate(
          objectAttributeInLanguage(s, "ShortDescription", lang) ||
            s.ShortDescription_En ||
            s.ShortDescription_Fr ||
            s.ShortDescription_De,
          {
            length: 260,
          },
        );
        return s;
      });
    });
  });
}
