const margin = 20;

export default function exportChart(dimensions, filename) {
  const widthX2 = 2 * dimensions.graphWidth;
  const heightX2 = 2 * dimensions.graphHeight;
  const legendWidthX2 = 2 * dimensions.legendWidth;
  const legendHeightX2 = 2 * dimensions.legendHeight;

  const canvasWidth = widthX2 + legendWidthX2 + 4 * margin;
  const canvasHeight = Math.max(heightX2, legendHeightX2) + 9 * margin;

  const canvas = createWhiteCanvas(canvasWidth, canvasHeight);

  drawTitle(canvas, 2000, 200);
  drawChart(canvas, widthX2, heightX2);
  drawLegend(
    canvas,
    legendWidthX2,
    legendHeightX2,
    widthX2 + 2 * margin,
    initDownload(canvas, filename),
  );
}

function createWhiteCanvas(canvasWidth, canvasHeight) {
  let canvas = document.createElement("canvas");
  // uncomment below for debugging (shows canvas in browser)
  // document.body.appendChild(canvas);
  let ctx = canvas.getContext("2d");
  canvas.width = canvasWidth;
  canvas.height = canvasHeight;
  ctx.fillStyle = "white";
  ctx.fillRect(0, 0, canvas.width, canvas.height);

  return canvas;
}

function drawTitle(canvas, width, height) {
  drawSvg(canvas, width, height, margin, 2 * margin, ".export-title", null);
}

function drawChart(canvas, width, height) {
  drawSvg(canvas, width, height, margin, 8 * margin, ".export-chart", null);
}

function drawLegend(canvas, width, height, leftMargin, callback) {
  drawSvg(canvas, width, height, leftMargin, 8 * margin, ".export-legend", callback);
}

function drawSvg(canvas, width, height, marginLeft, marginTop, selector, callback) {
  let image = new Image(width, height);
  let serializer = new XMLSerializer();
  let ctx = canvas.getContext("2d");

  let svgStrTitle = serializer.serializeToString(document.querySelector(selector));
  image.src = "data:image/svg+xml;charset=utf-8," + svgStrTitle;

  image.onload = function () {
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(image, marginLeft, marginTop, width, height);

    if (callback) {
      callback();
    }
  };
}

function initDownload(canvas, filename) {
  return function () {
    let url = canvas.toDataURL("image/png");
    let link = document.createElement("a");

    link.href = url;
    link.download = `${filename}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
}
