<template>
  <div class="pandemic-database-application" v-if="pageData.colorClass">
    <core-figures-header
      :title="pageData.pageTitle"
      :text="pageData.pageHeaderText"
      :color-class="'cf-header--' + pageData.colorClass"
    />

    <section class="section snf-section padding-top-2">
      <div class="columns snf-section__columns pandemic__columns">
        <div class="container column is-full">
          <h2 class="page-header">
            {{ personData.FirstName }} {{ personData.Surname }}
          </h2>

          <table>
            <tbody>
              <tr v-show="personData.ORCID">
                <td class="table-header">
                  {{ cmsTranslationByKey("ORCID") }}
                </td>
                <td>{{ personData.ORCID }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("Institute") }}
                </td>
                <td>{{ personData.Institute }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("ResearchInstitution") }}
                </td>
                <td>
                  {{ objectAttributeInLanguage(personData, "ResearchInstitution") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>

    <ApplicationsTable
      v-if="this.applicationsAsApplicant.length > 0"
      :applications="this.applicationsAsApplicant"
      :title="cmsTranslationByKey('SubheaderProjectsAsApplicant')"
    />

    <ApplicationsTable
      v-if="this.applicationsAsPartner.length > 0"
      :applications="this.applicationsAsPartner"
      :title="cmsTranslationByKey('SubheaderProjectsAsPartner')"
    />

    <ScientificPublications
      :publications="scientificPublicationsOpenAccess"
      :open-access="true"
    />

    <ScientificPublications
      :publications="scientificPublicationsOther"
      :open-access="false"
    />

    <div class="padding-top-3" />
  </div>
</template>

<script>
import axios from "axios";

import * as _ from "lodash";
import * as log from "loglevel";
import SolrSelect from "../components/keyFigures/SolrSelect.vue";
import CoreFiguresHeader from "../components/keyFigures/CoreFiguresHeader.vue";
import { mapGetters } from "vuex";
import {
  numberToStringPandemic,
  PANDEMIC_APPLICANT_ROLE_ID,
  PANDEMIC_CO_APPLICANT_ROLE_ID,
  PANDEMIC_PROJECT_PARTNER_ROLE_ID,
  pandemicApplicationEntitySubquery,
} from "../utils";
import { cmsTranslationMixin } from "../mixins";
import { buildEntityQuery } from "../solrHelpers";
import ScientificPublications from "../components/ScientificPublications";
import ApplicationsTable from "../components/ApplicationsTable";

export default {
  name: "PandemicDatabasePerson",
  mixins: [cmsTranslationMixin],
  components: { CoreFiguresHeader, ScientificPublications, ApplicationsTable },
  props: ["personNumber"],
  metaInfo: function () {
    if (this.selectedLanguage && this.cmsData) {
      return {
        title: this.cmsTranslationByKey("TitleCovid"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleCovid"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("ProjektregisterIntroShort"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_covid19.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      entity: "pandemic",
      pageData: {},
      personData: {},
      applications: [],
      applicationsAsApplicant: [],
      applicationsAsPartner: [],
      applicationPersonData: [],
      scientificPublicationsOpenAccess: [],
      scientificPublicationsOther: [],
    };
  },
  mounted: function () {
    log.debug("PandemicDatabasePerson mounted");
    this.loadPageData();
  },
  directives: { SolrSelect },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "cmsPageDataArray", "screenSize"]),
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    numberToStringPandemic(num) {
      return numberToStringPandemic(num, this.selectedLanguage);
    },
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.pageData = _.find(this.cmsPageDataArray, {
          entityUrl: this.entity,
        });
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.refetchSolrData();
    },
    refetchSolrData: function () {
      log.debug(this.personNumber);
      axios
        .get(
          buildEntityQuery(
            `Entity:ApplicationPerson+AND+PersonNumber:${this.personNumber}`,
            1000,
            "pandemic",
            "pandemic",
          ),
        )
        .catch((error) => {
          this.$router.replace("/not-found");
        })
        .then((r1) => {
          log.debug(r1);
          if (
            r1.data.responseHeader.status >= 400 ||
            r1.data.response.docs.length <= 0
          ) {
            this.$router.replace("/not-found");
          }

          this.applicationPersonData = r1.data.response.docs;
          this.personData = _.first(this.applicationPersonData);
          const applicationNumbers = _.map(
            this.applicationPersonData,
            "ApplicationNumber",
          );
          log.debug(applicationNumbers);

          const applicationNumbersSubQuery = _.join(
            _.map(applicationNumbers, (n) => {
              return `ApplicationNumber:${n}`;
            }),
            "+OR+",
          );
          log.debug(applicationNumbersSubQuery);

          axios
            .get(
              buildEntityQuery(
                `(${pandemicApplicationEntitySubquery}+OR+Entity:ScientificPublication)+AND+(${applicationNumbersSubQuery})&sort=EffectiveStartDate%20desc`,
                100,
                "pandemic",
                "pandemic",
              ),
            )
            .then((r2) => {
              let pubs, applications;
              [pubs, applications] = _.partition(r2.data.response.docs, {
                Entity: "ScientificPublication",
              });
              [
                this.scientificPublicationsOther,
                this.scientificPublicationsOpenAccess,
              ] = _.partition(
                _.filter(_.orderBy(pubs, ["PublishedDate"], ["desc"]), {
                  Entity: "ScientificPublication",
                }),
                {
                  OpenAccessStatus: "closed",
                },
              );

              const applicationNumbersAsPartner = _.map(
                _.filter(this.applicationPersonData, {
                  RoleId: PANDEMIC_PROJECT_PARTNER_ROLE_ID,
                }),
                "ApplicationNumber",
              );
              this.applicationsAsPartner = _.filter(applications, (a) => {
                return _.includes(applicationNumbersAsPartner, a.ApplicationNumber);
              });

              const applicationNumbersAsApplicant = _.map(
                _.filter(this.applicationPersonData, (p) => {
                  return _.includes(
                    [PANDEMIC_APPLICANT_ROLE_ID, PANDEMIC_CO_APPLICANT_ROLE_ID],
                    p.RoleId,
                  );
                }),
                "ApplicationNumber",
              );

              this.applicationsAsApplicant = _.filter(applications, (a) => {
                return _.includes(applicationNumbersAsApplicant, a.ApplicationNumber);
              });
            });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.pandemic__columns {
  @include tablet {
    max-width: 1000px !important;
  }
}

.page-header {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1em;
}

.section-header {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.table-header {
  font-weight: 700;
  width: 40%;
}

.pub-title {
  font-weight: 700;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.publication-link {
  color: $snf-blue-dark;

  &:hover {
    color: $snf-blue-dark;
    text-decoration: underline;
  }
}
</style>
