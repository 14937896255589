<template>
  <div class="select cf-select-wrapper">
    <label>
      <select class="cf-select" v-model="selected" @change="solrValueChanged">
        <option
          v-for="(option, index) in parsedEntry.selectionValues"
          :value="option"
          :key="`${option}${index}`"
        >
          {{ option.text }}
        </option>
      </select>
    </label>
  </div>
</template>

<script>
import * as log from "loglevel";

export default {
  name: "SolrSelect",
  props: {
    parsedEntry: null,
  },
  data: function () {
    return {
      selected: this.parsedEntry.selectionValues[this.parsedEntry.selectedIndex || 0],
    };
  },
  watch: {
    "parsedEntry.selectedIndex": function (val) {
      log.debug("watch parsedEntry.selectedIndex", val);
      this.selected =
        this.parsedEntry.selectionValues[this.parsedEntry.selectedIndex || 0];
    },
  },
  mounted: function () {
    log.debug("SolrSelect mounted", this.options);
  },
  methods: {
    solrValueChanged: function () {
      log.debug(this.selected);
      this.$emit(
        // eslint-disable-next-line vue/custom-event-name-casing
        "solrSelectValueChanged",
        Object.assign(
          {
            id: this.parsedEntry.id,
          },
          this.selected,
        ),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/colors";

.cf-select {
  border: 1px solid $snf-gray-medium;
  border-radius: unset !important;
}

.cf-select-wrapper:not(.is-multiple):not(.is-loading)::after {
  border-color: #363636 !important;
}
</style>
