<template>
  <article class="grant">
    <div class="main-section">
      <router-link
        :to="{
          name: 'GrantRegistryProject',
          params: { grantNumber: grant.GrantNumber },
        }"
      >
        <h2 class="grant-title" v-html="highlightSearchInput(grant.Title)"></h2>
      </router-link>
      <!-- prettier-ignore -->
      <div class="applicant-section">
        <a
            class="person-link"
            :href="`/grants/person/${grant.ResponsibleApplicant_PersonId}`"
            v-html="highlightSearchInput(grant.ResponsibleApplicantName_Frontend)"
        ></a>
        <a
            class="person-link"
            :href="`/grants/grant/${grant.GrantNumber}`"
            v-if="coApplicantsLinkDisplay"
        >{{ coApplicantsLinkDisplay }}</a>
        <span
            v-if="
            objectAttributeInLanguage(grant, 'ResearchInstitutionOrFellowshipCountries')
          "
        >, {{ objectAttributeInLanguage(grant, 'ResearchInstitutionOrFellowshipCountries') }}</span>
      </div>

      <div class="field-section grant-hide-mobile">
        <div v-if="grant.IsSubmittedWithFieldOfResearch">
          {{ cmsTranslationByKey("MainFieldOfResearch") }}:
          <span
            v-html="
              highlightSearchInput(
                joinSplitMvfField(
                  objectAttributeInLanguage(grant, 'MainFieldOfResearch', '_mvf'),
                ),
              )
            "
          ></span>
        </div>
        <div v-else>
          {{ cmsTranslationByKey("MainDiscipline") }}:
          <span
            v-html="
              highlightSearchInput(
                joinSplitMvfField(
                  objectAttributeInLanguage(grant, 'MainDiscipline', '_mvf'),
                ),
              )
            "
          ></span>
        </div>

        <div>
          {{ cmsTranslationByKey("FundingInstrument") }}:
          <span
            v-html="
              highlightSearchInput(
                objectAttributeInLanguage(grant, 'FundingInstrumentPublished'),
              )
            "
          ></span>
        </div>
      </div>

      <div
        class="field-section grant-hide-mobile"
        v-if="invisibleAttributesWithSearchHighlights.length"
      >
        <div
          :key="index"
          v-for="(field, index) in invisibleAttributesWithSearchHighlights"
        >
          {{ cmsTranslationByKey(field.title) }}:
          <span v-html="highlightSearchInput(field.value)"></span>
        </div>
      </div>

      <div class="field-section grant-hide-mobile">
        <span v-html="highlightSearchInput(keywordsDisplay)"></span>
      </div>
    </div>

    <div class="beside-section">
      <div class="beside-state-dates">
        <div class="state">
          {{ cmsTranslationByKey("State") }}:
          {{ objectAttributeInLanguage(grant, "State") }}
          <span class="mobile-comma">,</span>
        </div>
        <div class="start-date">
          {{ cmsTranslationByKey("EffectiveGrantStartDate") }}:
          {{ grant.EffectiveGrantStartDate | dateFormat }}
        </div>
        <div class="end-date grant-hide-mobile" v-show="grant.EffectiveGrantEndDate">
          {{ cmsTranslationByKey("EffectiveGrantEndDate") }}:
          {{ grant.EffectiveGrantEndDate | dateFormat }}
        </div>
        <div class="end-date grant-hide-mobile">
          {{ cmsTranslationByKey("ApprovedAmount") }}:
          {{ numberToStringPandemic(grant.AmountGrantedAllSets) }} CHF
        </div>
      </div>

      <div class="beside-state-publications">
        <div v-if="grant.OutputPublicationCount > 0">
          {{ grant.OutputPublicationCount }}
          <span v-if="grant.OutputPublicationCount === 1">
            {{ cmsTranslationByKey("PublicationCountSingle") }}
          </span>
          <span v-else>
            {{ cmsTranslationByKey("PublicationCountMultiple") }}
          </span>

          <img
            v-if="grant.Is100PctOA"
            style="height: 1em"
            :src="'/img/open_access.svg'"
            alt="Open Access Logo"
          />
        </div>

        <div v-if="grant.OutputDatasetsCount > 0">
          {{ grant.OutputDatasetsCount }}
          <span v-if="grant.OutputDatasetsCount === 1">
            {{ cmsTranslationByKey("DatesetCountSingle") }}
          </span>
          <span v-else>
            {{ cmsTranslationByKey("DatasetCountMultiple") }}
          </span>
        </div>

        <div v-if="grant.OutputOtherCount > 0">
          {{ grant.OutputOtherCount }}
          <span v-if="grant.OutputOtherCount === 1">
            {{ cmsTranslationByKey("OutputOtherCountSingle") }}
          </span>
          <span v-else>
            {{ cmsTranslationByKey("OutputOtherMultiple") }}
          </span>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import * as _ from "lodash";
import * as log from "loglevel";
import { mapGetters } from "vuex";
import { cmsTranslationMixin, highlighterMixin } from "@/mixins";
import { firstIndexOfMultiple, trimWords } from "@/utils";

export default {
  name: "GrantSearchEntry",
  mixins: [cmsTranslationMixin, highlighterMixin],
  props: {
    grant: {
      type: Object,
      default: null,
    },
    searchInput: {
      type: String,
      default: "",
    },
  },
  components: {},
  data: function () {
    return {
      invisibleAttributesWithSearchHighlights: [],
      keywordsDisplay: _.join(_.take(_.split(this.grant.Keywords_mvf, ";"), 5), ", "),
      coApplicantsLinkDisplay: "",
    };
  },
  watch: {},
  mounted: function () {
    log.debug("GrantSearchEntry mounted", this.selectedLanguage);
    this.calcInvisibleAttributesWithSearchHighlights();
    if (this.grant.AllApplicant_PersonId_mvf) {
      const coApplicants = _.drop(_.split(this.grant.AllApplicant_PersonId_mvf, ";"));
      if (coApplicants.length > 0) {
        let displayMessage = "";
        const count = coApplicants.length;

        // Define translations for different languages
        const translations = {
          en: {
            one: " and 1 other",
            multiple: (count) => ` and ${count} others`,
          },
          de: {
            one: " und 1 andere",
            multiple: (count) => ` und ${count} andere`,
          },
          fr: {
            one: " et 1 autre",
            multiple: (count) => ` et ${count} autres`,
          },
        };

        // Select the appropriate translation based on the selected language and count
        const translate = translations[this.selectedLanguage];
        if (count === 1) {
          displayMessage = translate.one;
        } else {
          displayMessage = translate.multiple(count);
        }

        this.coApplicantsLinkDisplay = displayMessage;
      }
    }
  },
  computed: {
    ...mapGetters(["selectedLanguage"]),
  },
  methods: {
    highlightSearchInput: function (input) {
      return this.highlightString(input, _.trim(this.searchInput));
    },

    calcInvisibleAttributesWithSearchHighlights() {
      const fields = [
        // TODO define all relevant fields
        "Abstract",
        this.attributeInLanguage("LaySummary"),
        this.attributeInLanguage("LaySummaryLead"),
        "CoApplicantName_mvf",
        "ProjectPartnerName_mvf",
        "EmployeeName_mvf",

        this.selectedLanguage === "en"
          ? this.attributeInLanguage("ResearchInstitution", "", false)
          : this.attributeInLanguage("ResearchInstitution"),
        this.attributeInLanguage("SecondaryDiscipline", "_mvf"),
        "ProjectPartnerInstitute_mvf",
        "ResponsibleApplicantInstitute",
      ];

      _.forEach(fields, (field) => {
        let fieldValue = this.grant[field];

        if (
          fieldValue &&
          _.trim(this.searchInput) &&
          _.trim(this.searchInput).length >= 3
        ) {
          let fieldValues = [fieldValue];
          if (_.endsWith(field, "_mvf")) {
            fieldValues = _.split(fieldValue, ";");
          }
          fieldValues = _.compact(fieldValues);

          _.forEach(fieldValues, (v) => {
            const matchIndex = firstIndexOfMultiple(v, _.trim(this.searchInput));

            if (matchIndex >= 0) {
              let resultValue = v;
              if (resultValue.length > 200) {
                const startIndex = Math.max(0, matchIndex - 75);
                const partStr = resultValue.substring(startIndex, 200);
                resultValue = trimWords(partStr, _.trim(this.searchInput), "...");
              }

              this.invisibleAttributesWithSearchHighlights.push({
                title: field.replace("_mvf", ""),
                value: resultValue,
              });
            }
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

$right-side-width: 306px;

.grant {
  padding: 1em 0;
}

.person-link {
  color: $snf-blue-font;

  &:hover {
    color: $snf-blue-dark;
    text-decoration: underline;
  }
}

h2.grant-title {
  font-size: 20px;
  line-height: 1.5em;
  margin-bottom: 8px;

  &:hover {
    text-decoration: underline;
    color: inherit;
  }
}

.applicant-section {
  margin-bottom: 8px;
}

.field-section {
  margin-bottom: 8px;
}

.beside-section {
  font-size: 14px;
  line-height: 20px;
  color: $snf-gray-darker;
}

.beside-state-dates {
  display: flex;
  margin-bottom: 8px;
}

.beside-state-publications {
  margin-bottom: 8px;
}

.mobile-comma {
  margin-right: 3px;
}

@include tablet() {
  .mobile-comma {
    display: none;
  }

  .grant {
    display: flex;

    .main-section {
      flex: 1 1 auto;
    }

    .beside-section {
      flex: 0 0 $right-side-width;
      padding-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .beside-state-dates {
    flex-direction: column;
  }
}
</style>
