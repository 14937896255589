<template>
  <div class="page-404">
    <div class="content">
      <section class="content-section">
        <h1 class="home-title">{{ translate("title") }}</h1>
        <h3>{{ translate("subtitle") }}</h3>
        <p>{{ translate("helpfulLinks") }}</p>

        <ul>
          <li>
            <router-link to="/key-figures">
              {{ cmsTranslationByKey("NavigationMainEntryCF") }}
            </router-link>
          </li>
          <li>
            <router-link to="/stories">
              {{ cmsTranslationByKey("NavigationMainEntryDataStories") }}
            </router-link>
          </li>
          <li>
            <router-link to="/grants">
              {{ cmsTranslationByKey("GrantRegistry") }}
            </router-link>
          </li>
          <li>
            <router-link to="/datasets">
              {{ cmsTranslationByKey("Datasets") }}
            </router-link>
          </li>
        </ul>
      </section>
      <section class="content-section">
        <div class="magnifying-glass-icon"></div>
      </section>
    </div>
  </div>
</template>

<script>
import { cmsTranslationMixin } from "@/mixins";
import { mapGetters } from "vuex";

export default {
  name: "NotFoundPage",
  mixins: [cmsTranslationMixin],
  computed: {
    ...mapGetters(["selectedLanguage"]),
  },
  data() {
    return {
      translations: {
        en: {
          title: "Sorry",
          subtitle: "We can't seem to find the page you're looking for.",
          helpfulLinks: "Here are some helpful links instead:",
        },
        de: {
          title: "Entschuldigung",
          subtitle: "Wir können die Seite, die Sie suchen, nicht finden.",
          helpfulLinks: "Hier sind stattdessen einige nützliche Links:",
        },
        fr: {
          title: "Désolé",
          subtitle: "Nous ne pouvons pas trouver la page que vous recherchez.",
          helpfulLinks: "Voici quelques liens utiles à la place:",
        },
      },
    };
  },
  methods: {
    translate(key) {
      return this.translations[this.selectedLanguage][key];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/_colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.page-404 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: $snf-blue-dark;
}

.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.content-section {
  padding: 20px 12px;
}

@include desktop() {
  .page-404 {
    padding: 120px 0;
  }

  .content {
    max-width: 1200px;
    flex-direction: row;
  }

  .content-section {
    padding: 40px 80px;
  }
}

ul {
  list-style-type: none;
  padding: 0;
}

ul li {
  margin: 8px 0;
  font-size: 18px;
}

ul li a {
  color: $snf-blue-dark;
  text-decoration: underline;
}

.magnifying-glass-icon {
  background-image: url("../../public/img/magnifying-glass.png");
  background-size: contain;
  height: 200px;
  width: 200px;
  margin-top: 20px;
}
</style>
