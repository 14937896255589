var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.pageData.colorClass)?_c('div',{staticClass:"pandemic-database"},[_c('core-figures-header',{attrs:{"title":_vm.pageData.pageTitle,"text":_vm.pageData.pageHeaderText,"color-class":'cf-header--' + _vm.pageData.colorClass}}),_c('section',{staticClass:"section snf-section snf-section__gray padding-top-1"},[_c('div',{staticClass:"columns snf-section__columns pandemic__columns"},[_c('div',{staticClass:"container column is-full"},[_c('div',{staticClass:"search-form"},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("SearchHeader"))+" ")])]),_c('div',{staticClass:"field is-grouped"},[_c('div',{staticClass:"control is-expanded"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchInputValue),expression:"searchInputValue"}],staticClass:"input",staticStyle:{"border-radius":"0"},attrs:{"type":"text","placeholder":_vm.cmsTranslationByKey('ProjektregisterSearchSuggestion')},domProps:{"value":(_vm.searchInputValue)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchInputValue=$event.target.value},_vm.debounceSearchInput]}})]),_c('div',{staticClass:"control"},[_c('button',{staticClass:"snf-button"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("SearchButton"))+" ")])])])]),(_vm.facetDataFetched)?_c('div',{staticClass:"field is-grouped filter-sentence-form",staticStyle:{"flex-wrap":"wrap","justify-content":"flex-start"}},_vm._l((_vm.parseSentenceResult.sentenceParts),function(part,index){return _c('div',{key:part + index,staticClass:"control cf-control",style:({
              'margin-bottom': '0.5em',
              'margin-right': part[0] === '*' ? '0.75rem' : 0,
            })},[(part[0] === '*')?_c('SolrSelect',{attrs:{"parsed-entry":_vm.parseSentenceResult.parsedEntries[part]},on:{"solrSelectValueChanged":_vm.solrSelectValueChanged}}):_vm._e(),(part[0] !== '*')?_c('input',{staticClass:"input cf-input",style:({
                border: 'none',
                width: _vm.getTextWidth(part) + 20 + 'px',
                background: '#f0f0f0',
              }),attrs:{"type":"text","tabindex":"-1","aria-label":part,"readonly":""},domProps:{"value":part}}):_vm._e()],1)}),0):_vm._e()])])]),_c('ApplicationsTable',{attrs:{"applications":_vm.parsedSolrResponse,"show-summary":true}}),(_vm.$route.query.debug)?_c('section',{staticClass:"section snf-section column-download"},[(_vm.solrQuery)?_c('div',{staticStyle:{"margin-top":"3em"}},[_c('div',{staticStyle:{"margin-top":"2em"}},[_c('a',{attrs:{"href":_vm.solrQuery,"target":"_blank"}},[_vm._v(_vm._s(_vm.solrQuery))])]),_c('pre',{staticStyle:{"margin-top":"2em"}})]):_vm._e()]):_vm._e()],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }