var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section snf-section margin-top-1"},[_c('div',{staticClass:"columns snf-section__columns pandemic__columns"},[_c('div',{staticClass:"container column is-full"},[(_vm.title)?_c('h4',{staticClass:"section-header"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.applications.length && _vm.showSummary)?_c('div',{staticClass:"color-gray"},[_vm._v(" "+_vm._s(_vm.applications.length)+" "+_vm._s(_vm.cmsTranslationByKey("SearchResultNumber"))+" ")]):_vm._e(),_c('div',{staticClass:"pandemic-table"},[_c('table',{staticClass:"pandemic-table"},[_c('tbody',{staticClass:"pandemic-table__body"},[_vm._l((_vm.applications),function(row){return [_c('tr',{key:row.id + 'title',staticClass:"pandemic-table__row"},[_c('td',{staticClass:"code pandemic-table__cell pandemic-cell",attrs:{"colspan":_vm.columnIds.length}},[_c('span',{staticClass:"cell__content cell__content-full code application-title"},[_c('router-link',{staticClass:"application-link",attrs:{"to":{
                        name: 'PandemicDatabaseApplication',
                        params: {
                          applicationNumber:
                            row['ApplicationNumber'] ||
                            row['ApplicationNumberString'],
                          origin: row['Funder_En'].toLowerCase(),
                        },
                      }}},[_vm._v(" "+_vm._s(row["Title"])+" ")])],1)])]),_c('tr',{key:row.id + 'columns1',staticClass:"pandemic-table__row"},[_c('td',{staticClass:"code pandemic-table__cell pandemic-cell"},[_c('span',{staticClass:"cell__content cell__content-full code"},[(row.ParsedApplicants)?_c('span',_vm._l((row.ParsedApplicants),function(person){return _c('router-link',{key:person.personNumber,staticClass:"application-link",attrs:{"to":{
                          name: 'PandemicDatabasePerson',
                          params: {
                            personNumber: person.personNumber,
                          },
                        }}},[_vm._v(" "+_vm._s(person.personName)+"; ")])}),1):_vm._e(),(!row.ParsedApplicants)?_c('span',[_vm._v(" "+_vm._s(row.Applicants)+" ")]):_vm._e()])]),_c('td',{staticClass:"code pandemic-table__cell pandemic-cell"},[_c('span',{staticClass:"cell__content cell__content-full code color-gray"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("Funder"))+": "+_vm._s(_vm.objectAttributeInLanguage(row, "Funder"))+" ")])])]),_c('tr',{key:row.id + 'columns2',staticClass:"pandemic-table__row"},[_c('td',{staticClass:"code pandemic-table__cell pandemic-cell"},[_c('span',{staticClass:"cell__content cell__content-full code"},[_vm._v(" "+_vm._s(_vm.objectAttributeInLanguage(row, "ResearchInstitution"))+" ")])]),_c('td',{staticClass:"code pandemic-table__cell pandemic-cell"},[_c('span',{staticClass:"cell__content cell__content-full code color-gray"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("State"))+": "+_vm._s(_vm.objectAttributeInLanguage(row, "State"))+" ")])])]),_c('tr',{key:row.id + 'keywords',staticClass:"pandemic-table__row"},[_c('td',{staticClass:"code pandemic-table__cell pandemic-cell",attrs:{"colspan":_vm.columnIds.length}},[_c('span',{staticClass:"cell__content cell__content-full code color-gray"},[_vm._v(" "+_vm._s(row["Keywords"])+" ")])])])]})],2)])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }