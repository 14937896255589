var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-story",attrs:{"lang":_vm.selectedLanguage}},[(_vm.showLoader)?_c('Loader'):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.showLoader),expression:"!showLoader"}]},[_c('iframe',{ref:"storyHolder",style:(_vm.storyFrameStyle),attrs:{"scrolling":"no","src":"about:blank"}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.story.id),expression:"story.id"}],staticClass:"data-story-meta"},[_c('div',{staticClass:"symbols"},[_c('a',{staticClass:"symbol-email",attrs:{"href":_vm.shareEmailUrl,"target":"_blank"}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.story.GitHub_URL),expression:"story.GitHub_URL"}],staticClass:"symbol-github",attrs:{"target":"_blank","href":_vm.story.GitHub_URL}}),_c('a',{directives:[{name:"show",rawName:"v-show",value:(_vm.story.DOI),expression:"story.DOI"}],staticClass:"symbol-doi",attrs:{"target":"_blank","href":_vm.story.DOI}})]),_c('div',{staticClass:"tags"},_vm._l((_vm.story.tagList),function(tagId){return _c('a',{key:tagId,staticClass:"tag",attrs:{"href":`/stories?tag=${tagId}`}},[_vm._v(" "+_vm._s(_vm.tags.length ? _vm.tags.find((t) => t.TagId === tagId).label : tagId)+" ")])}),0),_c('div',{staticClass:"authors",domProps:{"innerHTML":_vm._s(_vm.authorsHtml)}}),_c('div',{staticClass:"authors"},[_c('h3',[_vm._v(_vm._s(_vm.cmsTranslationByKey("ContactPerson")))]),_vm._m(0)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.similarStories.length),expression:"similarStories.length"}],staticClass:"similar-stories"},[_c('h2',{staticClass:"similar-stories--title"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("SubheaderSimilarStories"))+" ")]),_vm._l((_vm.similarStories),function(story){return _c('div',{key:story.id,staticClass:"similar-story",on:{"click":function($event){return _vm.openSimilarStory(story)}}},[_c('hr'),_c('h3',{staticClass:"story-title"},[_c('router-link',{attrs:{"to":{
                name: 'DataStory',
                params: {
                  htmlFilename: story.Name,
                },
              }}},[_vm._v(" "+_vm._s(story.title)+" ")])],1),_c('p',{staticClass:"story-date"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(story.PublicationDate))+" ")]),_c('div',{staticClass:"story-link"},[_c('router-link',{attrs:{"to":{
                name: 'DataStory',
                params: {
                  htmlFilename: story.Name,
                },
              }}},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("ButtonToStory"))+" "+_vm._s(_vm.readOnLanguagesText(story.Languages))+" ")])],1)])})],2)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('a',{staticStyle:{"text-decoration":"underline"},attrs:{"href":"mailto:datastories@snf.ch"}},[_vm._v(" datastories@snf.ch ")])])
}]

export { render, staticRenderFns }