<template>
  <div>
    <section
      class="snf-section home-content section__blue-light"
      v-if="cmsPageDataObject"
    >
      <div class="snf-section__columns columns home__columns">
        <div class="column title-column">
          <h1 class="home-title">
            {{ cmsTranslationByKey("DataPortalTitle") }}
          </h1>

          <p class="home-lead">
            {{ cmsTranslationByKey("DataPortalIntro") }}
          </p>
        </div>
      </div>
    </section>

    <section v-if="cmsPageDataObject" class="snf-section">
      <div class="snf-section__columns columns home__columns">
        <div class="grid-container">
          <div class="grid-cell">
            <div class="home-icon">
              <img src="/img/home-icon-key-figures.png" alt="Key Figures Icon" />
            </div>
            <h2>{{ cmsTranslationByKey("NavigationMainEntryCF") }}</h2>
            <p v-html="cfKpiSentenceShort"></p>
            <router-link to="/key-figures" class="home-link">
              {{ cmsTranslationByKey("CoreFiguresButtonAll") }}
            </router-link>
          </div>
          <div class="grid-cell">
            <div class="home-icon">
              <img src="/img/home-icon-data-stories.png" alt="Data Stories Icon" />
            </div>
            <h2>{{ cmsTranslationByKey("TitleDataStories") }}</h2>
            <p>
              {{ cmsTranslationByKey("DataStoriesIntro") }}
            </p>
            <router-link to="/stories" class="home-link">
              {{ cmsTranslationByKey("DataStoriesButtonAll") }}
            </router-link>
          </div>
          <div class="grid-cell">
            <div class="home-icon">
              <img src="/img/home-icon-grants.png" alt="Grant Registry Icon" />
            </div>
            <h2>{{ cmsTranslationByKey("TitleGrantRegistry") }}</h2>
            <p>
              {{ cmsTranslationByKey("GrantRegistryIntro") }}
            </p>
            <router-link to="/grants" class="home-link">
              {{ cmsTranslationByKey("GrantRegistryLink") }}
            </router-link>
          </div>
          <div class="grid-cell">
            <div class="home-icon">
              <img src="/img/home-icon-datasets.png" alt="Data Stories Icon" />
            </div>

            <h2>{{ cmsTranslationByKey("DatasetsTitle") }}</h2>

            <p>
              {{ cmsTranslationByKey("DatasetsIntroShort") }}
            </p>
            <router-link to="/datasets" class="home-link">
              {{ cmsTranslationByKey("DatasetsButtonAll") }}
            </router-link>
          </div>
        </div>
      </div>
    </section>

    <section
      v-if="cmsPageDataObject"
      class="snf-section section__blue-light-background"
    >
      <div class="snf-section__columns columns home__columns">
        <div class="covid-cell">
          <h2 class="contentheader-section__title title">
            {{ cmsTranslationByKey("TitleCovid") }}
          </h2>
          <p class="contentheader-section__description" v-if="cmsPageDataObject">
            {{ cmsTranslationByKey("ProjektregisterIntro") }}
          </p>

          <div>
            <router-link
              :to="{ name: 'PandemicDatabase' }"
              class="home-link covid-link"
            >
              {{ cmsTranslationByKey("ProjektregisterLink") }}
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as _ from "lodash";
import { cmsTranslationMixin } from "../mixins";
import { loadDataStories, loadKpiSentence } from "@/solrHelpers";
import { splitTrimWords } from "@/utils";

export default {
  name: "Home",
  mixins: [cmsTranslationMixin],
  data: function () {
    return {
      cfKpiSentence: "",
      cfPageData: {},
      cfPictureData: [],
      cfResultSentence: "",
      cfTitleSentence: "",
      dataStory: {},
      searchInputValueCovid: "",
      searchInputValueGrantRegistry: "",
    };
  },
  mounted: function () {
    this.loadPageData();
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "cmsData",
      "screenSize",
      "cmsPageDataArray",
      "cmsPageDataObject",
      "enableGrantSearch",
    ]),
    cfKpiSentenceShort: function () {
      return this.cfKpiSentence
        .replace(/Informieren Sie sich.*/, "")
        .replace(/Pour en savoir.*/, "")
        .replace(/You can find more.*/, "");
    },
  },
  watch: {
    $route(to, from) {
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.cfPageData = _.find(this.cmsPageDataArray, {
          entityUrl: _.camelCase("funding-instruments"),
        });

        this.changeLanguage();
      }
    },
    startSearchCovid: function () {
      if (this.searchInputValueCovid) {
        this.searchInput = _.trim(this.searchInputValue);
        const trimmedSearchInput = _.join(
          splitTrimWords(this.searchInput, 3, true, false),
          " ",
        );
        window.location.assign(`/covid-19?q=${this.searchInputValueCovid}`);
      } else {
        window.location.assign(`/covid-19`);
      }
    },
    startSearchGrantRegistry: function () {
      this.searchInputValueGrantRegistry = _.trim(this.searchInputValueGrantRegistry);
      const trimmedSearchInput = _.join(
        splitTrimWords(this.searchInputValueGrantRegistry, 3, true, false),
        " ",
      );
      if (trimmedSearchInput) {
        window.location.assign(`/grants?q=${trimmedSearchInput}`);
      } else {
        window.location.assign(`/grants`);
      }
    },
    changeLanguage: function () {
      // fetchFullKeyFiguresData(this.selectedLanguage, this.cfPageData).then(result => {
      //   this.cfPictureData = result.cfPictureData;
      //   this.cfResultSentence = result.cfResultSentence;
      //
      //   this.cfTitleSentence = result.cfParseSentenceResult.parsedSentence;
      //   _.forEach(result.cfSelectedParts, (part) => {
      //     this.cfTitleSentence = this.cfTitleSentence.replace(part.id, part.text);
      //   });
      // });
      //
      // this.loadStories();

      loadKpiSentence(this.selectedLanguage).then((kpiSentence) => {
        this.cfKpiSentence = kpiSentence;
      });
    },

    loadStories: function () {
      loadDataStories(this.selectedLanguage, null, 1).then((stories) => {
        this.dataStory = stories[0];
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.section-border-top {
  border-top: 1px solid $snf-gray-light;
}

.home-content {
  background: $snf-blue-light-background;
}

.home__columns {
  margin: 0 auto;
  max-width: 1028px;
}

.home-title {
  color: $snf-blue-dark;
  font-size: 36px;
  font-weight: 700;
}

.home-lead {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 18px;
  line-height: 1.5em;
  max-width: 700px;
}

.home-link {
  color: inherit;
  font-weight: 700;
  border-bottom: 2px solid $snf-red-light;
  text-decoration: none;
  width: fit-content;

  &:hover {
    color: $snf-red-light;
  }
}

.covid-link {
  border-bottom: 2px solid $snf-blue-dark;
}

.home-icon {
  margin-bottom: 32px;
}

.home-icon > img {
  height: 80px;
}

.grid-container {
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  gap: 48px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.grid-cell {
  padding: 12px;
}

.covid-cell {
  padding: 12px 12px 36px;
}

@include tablet() {
  .home-icon > img {
    height: 80px;
  }

  .grid-container {
    grid-template-columns: 1fr 1fr;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .covid-cell {
    max-width: 48%;
    padding: 36px 12px;
  }

  .title-column {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

$square-margin: 12px;

.column-left {
  padding-right: 1.5em;
}

.column-right {
  padding-left: 1.5em;
}

.square {
  background: white;
  padding: 24px 24px;
  margin-bottom: $square-margin;

  h3 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 0.5em;
  }
}

.story-img {
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $snf-gray-light;
}

@include tablet() {
  .home-title {
    font-size: 68px;
    margin-bottom: 0;
  }

  .home-lead {
    margin-bottom: 0;
  }

  .home__columns {
    margin: 1em auto !important;
  }

  .waffle__columns {
    flex-direction: row;
  }

  .square-container {
    flex-wrap: nowrap;
    display: none;
  }

  .square {
    flex: 25%;
  }
}

.section__blue-light-background {
  background: $snf-blue-light-background;
}

.section__blue-light {
  background: $snf-blue-light-70 !important;
}

@include desktop() {
  .home-content {
    background: white;
  }

  .home-title {
    color: $snf-black;
  }
}
</style>
