<template>
  <div class="grant-registry-person">
    <div v-if="errorMessage">
      <section class="grant-section title-section">
        {{ errorMessage }}
      </section>
    </div>
    <div v-if="person && cmsData && !errorMessage">
      <section class="grant-section title-section">
        <h2>
          {{ person.PersonName }}
        </h2>

        <div v-if="person.Institute">
          {{ person.Institute.replaceAll(";", "; ") }}
        </div>

        <div v-if="person.ORCID">
          <a class="orcid-link" :href="`https://orcid.org/${person.ORCID}`">
            <span class="orcid-icon">
              <img src="/img/orcid.svg" alt="down" />
            </span>
            <span class="text">{{ person.ORCID }}</span>
          </a>
        </div>

        <div style="margin-top: 1em" v-if="$route.query.debug">
          SOLR Queries for Testing/Debugging (-> remove before deployment)
          <div
            :key="index"
            v-for="(solrQuery, index) in solrQueries"
            style="margin-bottom: 4px"
          >
            <a style="color: blue" :href="solrQuery">{{ solrQuery }}</a>
          </div>
        </div>
      </section>

      <section class="grant-section discipline-section">
        <h3>{{ cmsTranslationByKey("DisciplinesAndKeywordsFromGrants") }}</h3>
        <div>{{ this.person.disciplinesDisplay }}</div>
        <div>{{ this.person.keywordsDisplay }}</div>
      </section>

      <section v-if="applicantGrants.length" class="grant-section grants-section">
        <h3>{{ cmsTranslationByKey("GrantsAsResponsibleApplicant") }}</h3>

        <GrantSearchEntry
          class="grant-entry"
          v-for="(grant, index) in applicantGrants"
          :key="grant.GrantNumber"
          :grant="grant"
          :index="index"
        ></GrantSearchEntry>
      </section>

      <section v-if="employeeGrants.length" class="grant-section grants-section">
        <h3>{{ cmsTranslationByKey("GrantsAsEmployee") }}</h3>

        <GrantSearchEntry
          class="grant-entry"
          v-for="(grant, index) in employeeGrants"
          :key="grant.GrantNumber"
          :grant="grant"
          :index="index"
        ></GrantSearchEntry>
      </section>

      <section v-if="projectPartnerGrants.length" class="grant-section grants-section">
        <h3>{{ cmsTranslationByKey("GrantsAsProjectPartner") }}</h3>

        <GrantSearchEntry
          class="grant-entry"
          v-for="(grant, index) in projectPartnerGrants"
          :key="grant.GrantNumber"
          :grant="grant"
          :index="index"
        ></GrantSearchEntry>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as log from "loglevel";
import * as _ from "lodash";
import { mapGetters } from "vuex";
import { cmsTranslationMixin } from "@/mixins";
import { createSolrGetRequestUrl, makeSolrPostRequest } from "@/utils";
import GrantSearchEntry from "@/components/GrantSearchEntry";

export default {
  name: "GrantRegistryPerson",
  mixins: [cmsTranslationMixin],
  components: { GrantSearchEntry },
  props: ["personId"],
  metaInfo: function () {
    if (this.person && this.cmsData) {
      return {
        title: this.person.PersonName,
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: `${this.cmsTranslationByKey("TitleGrantRegistry")}: ${
              this.person.PersonName
            }`,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("GrantRegistryIntro"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: "https://data.snf.ch/img/meta_image_grant_search.png",
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      person: null,
      grants: [],
      solrQueries: [],
      errorMessage: null,
    };
  },
  mounted: function () {
    log.debug("GrantRegistryPerson mounted");
    this.loadPageData();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "cmsPageDataArray", "screenSize"]),
    applicantGrants: function () {
      return _.orderBy(
        _.filter(this.grants, (g) => {
          return (
            (g.ResponsibleApplicant_PersonId &&
              g.ResponsibleApplicant_PersonId.toString().indexOf(this.personId) >= 0) ||
            (g.CoApplicantId_PersonId_mvf &&
              g.CoApplicantId_PersonId_mvf.indexOf(this.person.PersonId) >= 0) ||
            (g.ApplicantAbroad_PersonId_mvf &&
              g.ApplicantAbroad_PersonId_mvf.toString().indexOf(this.personId) >= 0)
          );
        }),
        ["EffectiveGrantStartDate"],
        ["desc"],
      );
    },
    employeeGrants: function () {
      return _.filter(this.grants, (g) => {
        return (
          g.Employee_PersonId_mvf &&
          g.Employee_PersonId_mvf.indexOf(this.person.PersonId) >= 0
        );
      });
    },
    projectPartnerGrants: function () {
      return _.filter(this.grants, (g) => {
        return (
          g.ProjectPartner_PersonId_mvf &&
          g.ProjectPartner_PersonId_mvf.indexOf(this.person.PersonId) >= 0
        );
      });
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData: function () {
      if (this.selectedLanguage) {
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.refetchSolrData();
    },
    refetchSolrData: function () {
      this.solrQueries = [];
      const solrQuery = `/solr/search/select/?start=0&rows=1&q=Category:search+AND+Entity:Person+AND+PersonId:${this.personId}`;
      this.solrQueries.push(solrQuery);
      axios
        .get(solrQuery)
        .then((r) => {
          if (r.data.response.numFound <= 0) {
            this.$router.push({ name: "NotFound404WithPath" });
            return;
          }

          this.person = r.data.response.docs[0];

          this.person.disciplinesDisplay = _.join(
            _.split(
              this.objectAttributeInLanguage(this.person, "Discipline", "_mvf"),
              ";",
            ),
            ", ",
          );
          this.person.keywordsDisplay = _.join(
            _.split(this.person.Keywords_mvf, ";"),
            ", ",
          );

          const grantValues = _.compact(
            _.flatten([
              _.split(this.person.ResponsibleApplicantGrantId_mvf, ";"),
              _.split(this.person.CoApplicantGrantId_mvf, ";"),
              _.split(this.person.ApplicantAbroadGrantId_mvf, ";"),
              _.split(this.person.EmployeeGrantId_mvf, ";"),
              _.split(this.person.ProjectPartnerGrantId_mvf, ";"),
            ]),
          );

          if (grantValues.length > 0) {
            const solrGrantIdPart = _.join(
              _.map(grantValues, (v) => {
                return `GrantId:${v}`;
              }),
              "+OR+",
            );

            const solrGrantQueryData = {
              start: 0,
              rows: 200,
              sort: "EffectiveGrantStartDate%20desc",
              q: `(${solrGrantIdPart})+AND+Entity:Grant`,
            };

            log.debug(
              "solrPersonGrantQuery:",
              createSolrGetRequestUrl(solrGrantQueryData),
            );
            this.solrQueries.push(createSolrGetRequestUrl(solrGrantQueryData));

            makeSolrPostRequest(solrGrantQueryData)
              .then((response) => {
                this.grants = response.data.response.docs;
              })
              .catch((error) => {
                console.error(error);
                this.errorMessage = this.cmsTranslationByKey("ErrorOnLoadingData");
                this.$router.push({ name: "NotFound404WithPath" });
              });
          }
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = this.cmsTranslationByKey("ErrorOnLoadingData");
          this.$router.push({ name: "NotFound404WithPath" });
        });
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.grant-registry-person {
  border-top: 1px solid $snf-gray-light;
}

.title-section {
  margin-top: 32px;
}

.grant-section {
  padding: 0 24px;
  margin-bottom: 48px;
}

.grant-entry {
  border-top: $snf-gray-light 1px solid;
}

.orcid-link {
  .orcid-icon {
    margin-right: 6px;
    margin-top: 3px;
  }

  cursor: pointer;
  margin-top: 16px;
  display: flex;
  color: $snf-blue-font;

  &:hover {
    color: $snf-blue-dark;
  }
}

@include desktop() {
  .grant-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1046px;
  }
}
</style>
