<template>
  <section class="section snf-section padding-top-2" v-if="publications.length > 0">
    <div class="columns snf-section__columns pandemic__columns">
      <div class="container column is-full">
        <h4 class="section-header">
          <span v-if="openAccess">
            {{ cmsTranslationByKey("SubheaderPublications1") }}
          </span>
          <span v-if="!openAccess">
            {{ cmsTranslationByKey("SubheaderPublications2") }}
          </span>
        </h4>

        <div v-for="pub in publications" :key="pub.DoiUrl">
          <h3 class="pub-title">
            <a :href="pub.DoiUrl || pub.Url" target="_blank" class="publication-link">
              {{ pub.Title }}
              <img
                v-if="openAccess"
                style="height: 1.2rem"
                :src="'/img/open_access.svg'"
                alt="Open Access Logo"
              />
            </a>
          </h3>
          <div v-html="`${pub.Author} (${pub.Year}). ${pub.Title}. ${pub.Journal}.`" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
import { cmsTranslationMixin } from "../mixins";

export default {
  name: "ScientificPublications",
  mixins: [cmsTranslationMixin],
  props: ["publications", "openAccess"],
  components: {},
  computed: {
    ...mapGetters(["selectedLanguage"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped></style>
