<template>
  <div
    class="dropdown"
    @keydown.up.stop.prevent="$emit('key-up-down', showDropdown, -1)"
    @keydown.down.stop.prevent="$emit('key-up-down', showDropdown, +1)"
    @keyup.esc="hideDropdown(true)"
    @keydown.tab="hideDropdown()"
    :class="{ 'is-active': showDropdown }"
    v-on-click-outside="onClickOutside"
  >
    <div class="dropdown-trigger">
      <button
        :id="id"
        :data-menu="id"
        @click="toggleDropdown"
        class="dropdown-trigger__button"
        :class="{ selected: selected }"
        aria-haspopup="true"
        :aria-expanded="showDropdown"
        :aria-controls="id"
      >
        {{ buttonText }}
        <img src="/img/arrow-down-light.svg" class="arrow-down" />
      </button>
    </div>
    <div @click="hideDropdown()" class="dropdown-menu" role="menu" ref="dropdown">
      <div class="dropdown-content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import { vOnClickOutside } from "@vueuse/components";

export default {
  name: "Dropdown",
  props: {
    buttonText: String,
    id: String,
    selected: Boolean,
  },
  directives: {
    onClickOutside: vOnClickOutside,
  },
  data: function () {
    return {
      showDropdown: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    onClickOutside() {
      this.showDropdown = false;
    },
    hideDropdown(focusMenuElement = false) {
      this.showDropdown = false;

      if (focusMenuElement) {
        document.querySelectorAll(`[data-menu=${this.id}]`)[0].focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.dropdown-trigger__button {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent !important;
  //font-weight: 700;
  &:focus,
  &:hover {
    background-color: transparent !important;
  }
}

.selected {
  border-top: 1px solid !important;
  border-color: $snf-red-light !important;
  padding-top: 4px !important;
}

.arrow-down {
  margin-left: 0;
  filter: invert(12%) sepia(21%) saturate(2919%) hue-rotate(162deg) brightness(99%)
    contrast(97%);
}
</style>
