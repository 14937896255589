<template>
  <div class="funding-instruments">
    <core-figures-header
      :title="pageData.pageTitle"
      :text="pageData.pageHeaderText"
      :color-class="'cf-header--' + pageData.colorClass"
    />

    <section class="section snf-section padding-top-2">
      <div class="columns snf-section__columns">
        <div class="container column is-full">
          <div class="table" v-for="(table, index) in tableEntries" :key="table.title">
            <h2 class="table-heading">
              {{ table.title }}
            </h2>
            <data-table
              v-if="table.solrData"
              :with-gradient="index === 0"
              :data="table.solrData"
              :lang="selectedLanguage"
            />
          </div>
        </div>
      </div>
    </section>

    <nav class="cf-nav snf-section snf-section__gray">
      <div class="cf-nav__section snf-section__columns columns is-mobile">
        <div class="cf-nav__left cf-nav__item column">
          <router-link class="text-decoration-none" to="/key-figures">
            <span>{{ cmsTranslationByKey("BackCoreFiguresHome") }}</span>
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import axios from "axios";
import * as _ from "lodash";
import * as log from "loglevel";
import SolrSelect from "../components/keyFigures/SolrSelect.vue";
import CoreFiguresHeader from "../components/keyFigures/CoreFiguresHeader.vue";
import DataTable from "../components/DataTable";
import { mapGetters } from "vuex";
import { buildEntityQuery } from "../solrHelpers";
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "NCCR",
  components: { CoreFiguresHeader, DataTable },
  mixins: [cmsTranslationMixin],
  props: {},
  metaInfo: function () {
    if (this.pageData.colorClass) {
      return {
        title: `${this.cmsTranslationByKey("NavigationMainEntryCF")}: ${
          this.pageData.pageTitle
        }`,
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: `${this.cmsTranslationByKey("NavigationMainEntryCF")}: ${
              this.pageData.pageTitle
            }`,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.pageData.pageHeaderText,
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_key_figures.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      entity: "nccr",
      pageData: {},
      solrQueries: [],
      parsedSolrResponses: [],
      tableEntries: [],
    };
  },
  mounted: function () {
    log.debug("NCCR mounted");
    this.loadPageData();
  },
  directives: { SolrSelect },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "screenSize", "cmsPageDataArray"]),
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.pageData = _.find(this.cmsPageDataArray, {
          entityUrl: _.camelCase(this.entity),
        });
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.refetchSolrData();
    },
    buildNccrQuery: function (entity) {
      return buildEntityQuery(`Entity:${entity}`, 1000, "search", "excel");
    },
    refetchSolrData: function () {
      this.tableEntries = [];

      const cmsTableTitleData = _.find(this.cmsData, {
        DocumentType: "table_pages",
      })[this.selectedLanguage].data;

      _.forEach(["1", "2", "3", "4", "5", "6"], (num) => {
        const tableKey = `table${num}solrentity`;
        const titleKey = `table${num}title`;

        if (
          cmsTableTitleData[tableKey] &&
          cmsTableTitleData[tableKey][0].text &&
          cmsTableTitleData[titleKey] &&
          cmsTableTitleData[titleKey][0].text
        ) {
          this.tableEntries.push({
            solrQuery: this.buildNccrQuery(cmsTableTitleData[tableKey][0].text),
            title: cmsTableTitleData[titleKey][0].text,
          });
        }
      });

      const solrQueries = _.map(this.tableEntries, (tableEntry) => {
        return axios.get(tableEntry.solrQuery);
      });

      axios.all(solrQueries).then((responses) => {
        _.map(responses, (response, index) => {
          this.$set(
            this.tableEntries,
            index,
            _.assign(this.tableEntries[index], {
              solrData: response.data.response.docs,
            }),
          );
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.table {
  margin-top: 3em;

  &:first-of-type {
    margin-top: 0;
  }
}

.table-heading {
  font-size: 1.5rem;
  margin-bottom: 1em;
}
</style>
