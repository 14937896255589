<template>
  <div v-show="grantCheckedFilterValues.length" class="active-filters">
    <div v-for="filterValue in filterEntriesLimited" :key="filterValue.value">
      <ActiveGrantFilter
        :filter-value="filterValue"
        :key="renderActiveFiltersCount"
        @toggle-search-filter="toggleSearchFilter"
        @toggle-date-filter="toggleDateFilter"
      ></ActiveGrantFilter>
    </div>

    <div class="show-more-button" v-if="moreThanShowLimit" @click="toggleShowMore">
      <div class="button-title">
        <span v-if="showMore">{{ cmsTranslationByKey("ShowLess") }}</span>
        <span v-else>{{ cmsTranslationByKey("ShowMore") }}</span>
      </div>

      <div class="button-icon">
        <img
          :src="showMore ? '/img/arrow-up-light.svg' : '/img/arrow-down-light.svg'"
          style="width: 10px; height: 10px"
          alt="reset-search"
        />
      </div>
    </div>

    <GrantSearchFilterResetButton
      style="margin-top: 4px"
      @reset-filters="$emit('reset-filters')"
    />
  </div>
</template>

<script>
import * as _ from "lodash";

import { mapGetters, mapState } from "vuex";
import { cmsTranslationMixin } from "@/mixins";
import ActiveGrantFilter from "@/components/ActiveGrantFilter";
import GrantSearchFilterResetButton from "@/components/GrantSearchFilterResetButton";
import { FILTER_ENTRIES_LIST_SORT_VALUE } from "@/utils";

export default {
  name: "ActiveGrantFilterList",
  mixins: [cmsTranslationMixin],
  components: { GrantSearchFilterResetButton, ActiveGrantFilter },
  data() {
    return {
      showMore: false,
      showLimit: 10,
    };
  },
  computed: {
    ...mapState([
      "renderActiveFiltersCount",
      "grantSearchStartMinYear",
      "grantSearchStartMaxYear",
      "grantSearchEndMinYear",
      "grantSearchEndMaxYear",
    ]),
    ...mapGetters([
      "grantCheckedFilterEntries",
      "grantCheckedFilterValues",
      "findGrantFilterValue",
    ]),
    filterEntriesSorted: function () {
      let checkedFilterValues = this.grantCheckedFilterEntries.flatMap((entry) => {
        if (entry.filterEntity.type === "date") {
          // do nothing
          const years = entry.values[0].split(":");
          if (years[0] === "*") {
            if (entry.param === "end") {
              years[0] = this.grantSearchEndMinYear.toString();
            } else {
              years[0] = this.grantSearchStartMinYear.toString();
            }
          }
          if (years[1] === "*") {
            if (entry.param === "end") {
              years[1] = this.grantSearchEndMaxYear.toString();
            } else {
              years[1] = this.grantSearchStartMaxYear.toString();
            }
          }

          let title = years.join(" - ");
          if (years[0] === years[1]) {
            title = years[0];
          }
          return {
            type: "date",
            urlQueryParam: entry.param,
            title: title,
            entityTitleKey: entry.filterEntity.titleKey,
            filterEntityId: entry.filterEntity.id,
          };
        }
        return entry.values.map((v) => {
          return _.assign({ type: "filter" }, this.findGrantFilterValue(v));
        });
      });
      checkedFilterValues = _.compact(checkedFilterValues);
      return _.orderBy(
        checkedFilterValues,
        (v) => {
          return FILTER_ENTRIES_LIST_SORT_VALUE[v.filterEntityId];
        },
        "asc",
      );
    },
    filterEntriesSortedChildrenRemoved: function () {
      return this.filterEntriesSorted.filter((v) => {
        if (v.parentFieldValue) {
          const parentEntry = this.findGrantFilterValue(v.parentFieldValue);
          const checkedChildren = _.intersection(
            this.grantCheckedFilterValues,
            _.map(parentEntry.children, "value"),
          );
          return checkedChildren.length < parentEntry.children.length;
        }

        return true;
      });
    },
    moreThanShowLimit: function () {
      return this.filterEntriesSortedChildrenRemoved.length > this.showLimit;
    },
    filterEntriesLimited: function () {
      if (this.showMore) {
        return this.filterEntriesSortedChildrenRemoved;
      } else {
        return this.filterEntriesSortedChildrenRemoved.slice(0, this.showLimit);
      }
    },
  },
  methods: {
    toggleSearchFilter: function (filterStatusEntry) {
      this.$emit("toggle-search-filter", filterStatusEntry);
    },
    toggleDateFilter: function (param) {
      this.$emit("toggle-date-filter", param);
    },
    toggleShowMore: function () {
      this.showMore = !this.showMore;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";

.active-filters {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 16px;
}

.show-more-button {
  background: $snf-blue-font;
  display: flex;
  margin-right: 16px;
  margin-bottom: 8px;
  padding: 4px 12px;
  align-items: center;
  cursor: pointer;
  color: white;

  .button-title {
    margin-right: 8px;
  }

  .button-icon {
    img {
      @include snf-white-filter();
    }
  }
}
</style>
