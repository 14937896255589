<template>
  <div
    class="reset-filter"
    v-show="grantCheckedFilterValues.length"
    @click="$emit('reset-filters')"
  >
    <img style="width: 16px; height: 24px" src="/img/reset.svg" alt="reset" />
    <div>
      {{ cmsTranslationByKey("GrantFilterReset") }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "GrantSearchFilterResetButton",
  mixins: [cmsTranslationMixin],
  props: [],
  components: {},
  computed: {
    ...mapGetters(["grantCheckedFilterValues"]),
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.reset-filter {
  color: $snf-blue-font;
  cursor: pointer;
  display: flex;

  img {
    margin-right: 4px;
  }
}

@include touch() {
  .reset-filter {
    padding: 24px 0 16px 0;
  }
}
</style>
