<template>
  <div id="app" class="app">
    <div
      class="header"
      :class="{
        'header--freeze':
          mobileMenuShown || dataStoriesTagFilterShown || grantShowFilterMobile,
      }"
    >
      <Header />
    </div>
    <div
      class="content"
      :class="{
        'content--freeze': mobileMenuShown || dataStoriesTagFilterShown,
      }"
    >
      <router-view />
    </div>
    <div class="footer" :class="{ hidden: mobileMenuShown || grantShowFilterMobile }">
      <Footer />
    </div>
  </div>
</template>

<script>
import * as log from "loglevel";
import { mapGetters, mapState } from "vuex";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import store from "./store";
import { cmsTranslationMixin } from "@/mixins";
import * as _ from "lodash";

export default {
  name: "App",
  metaInfo() {
    log.info("App metaInfo run");
    return {
      title:
        this.cmsTranslationData && this.selectedLanguage
          ? this.cmsTranslationByKey("DataPortalTitle")
          : "SNSF Data Portal",
      meta: [
        {
          vmid: "og:title",
          property: "og:title",
          content:
            this.cmsTranslationData && this.selectedLanguage
              ? this.cmsTranslationByKey("DataPortalTitle")
              : "SNSF Data Portal",
        },
        {
          vmid: "og:url",
          property: "og:url",
          content: window.location.href,
        },
        {
          vmid: "og:image",
          property: "og:image",
          content: `${window.location.origin}/img/meta_image.png`,
        },
      ],
    };
  },
  mixins: [cmsTranslationMixin],
  components: {
    Header,
    Footer,
  },
  computed: {
    ...mapState(["grantShowFilterMobile"]),
    ...mapGetters(["mobileMenuShown", "dataStoriesTagFilterShown"]),
  },
  beforeMount() {
    log.debug("App beforeMount");
  },
  mounted() {
    log.debug("App mounted");
    if (window.localStorage.getItem("selectedLanguage")) {
      store.dispatch(
        "setSelectedLanguage",
        window.localStorage.getItem("selectedLanguage"),
      );
    } else {
      const browserLanguage = navigator.language.split("-")[0];
      if (_.includes(["de", "en", "fr"], browserLanguage)) {
        store.dispatch("setSelectedLanguage", browserLanguage);
      } else {
        store.dispatch("setSelectedLanguage", "en");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
@import "./assets/css/colors";
@import "./assets/css/mixins";
@import "./assets/css/bulma_utils";

.app {
  display: flex;
  flex-direction: column;
  @include tablet() {
    height: 100%;
  }
}

.header--freeze {
  @include mobile() {
    position: fixed;
    width: 100%;
  }
}

.hidden {
  display: none;
}

.content {
  min-height: 65vh;
  flex: 1 0 auto;

  &--freeze {
    @include mobile() {
      position: fixed;
      visibility: hidden;
    }
  }
}

.footer {
  flex: 0 0 auto;
}
</style>
