var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"data-story-index",attrs:{"lang":_vm.selectedLanguage}},[_c('section',{staticClass:"tag-filter",class:{
      'tag-filter--visible': _vm.dataStoriesTagFilterShown,
    },attrs:{"id":"tag-filter"}},[_c('div',[_c('div',{staticClass:"tag-filter-header"},[_c('h2',{staticClass:"tag-filter-title"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("FilterTagsMenuTitle"))+" ")]),_c('div',{staticClass:"tag-filter-close"},[_c('a',{staticClass:"tag-filter-close-button",attrs:{"role":"button"},on:{"click":function($event){return _vm.setDataStoriesTagFilterShown(false)}}})])]),_c('div',{staticClass:"tag-filter-list"},_vm._l((_vm.sortedTagsUsed),function(tag){return _c('a',{key:tag.TagId,staticClass:"tag-filter-tag",class:[
            _vm.selectedTag && tag.TagId === _vm.selectedTag.TagId
              ? 'tag-filter-tag--selected'
              : '',
          ],on:{"click":function($event){return _vm.selectTag(tag)}}},[_vm._v(" "+_vm._s(tag.label)+" ")])}),0)])]),(_vm.cmsPageDataArray.length > 0)?_c('section',{staticClass:"ds-section title-section"},[_c('div',{staticClass:"data-story-header"},[_c('h1',{staticClass:"data-story-title"},[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("TitleDataStories"))+" ")]),_c('div',{staticClass:"data-story-filter",on:{"click":function($event){return _vm.clickTagFilter()}}},[_c('span',[_vm._v(" "+_vm._s(_vm.cmsTranslationByKey("FilterTagsButton"))+" "+_vm._s(_vm.selectedTag.TagId ? _vm.selectedTag.label : _vm.cmsTranslationByKey("FilterTagsMenuAll"))+" ")]),_c('span',{staticStyle:{"float":"right"}},[_c('img',{attrs:{"src":_vm.selectedTag.TagId ? '/img/close.svg' : '/img/arrow-right.svg',"width":"16","height":"16"}})])])])]):_vm._e(),(_vm.showLoader)?_c('Loader'):_c('section',{staticClass:"ds-section story-container"},_vm._l((_vm.stories),function(story,index){return _c('div',{key:story.name,staticClass:"story-tile",class:[
        story.cssClass,
        index === 0 || (story.ThumbnailExtension && index % 3 === 0)
          ? 'story-tile--tall'
          : '',
        story.ThumbnailExtension && index % 3 === 1 ? 'story-tile--wide' : '',
        story.ThumbnailExtension && index % 3 === 2 ? 'story-tile--wide-reverse' : '',
      ],on:{"click":function($event){return _vm.openStory(story)}}},[_c('router-link',{directives:[{name:"show",rawName:"v-show",value:(index !== 0),expression:"index !== 0"}],staticClass:"story-tile-image",attrs:{"to":{
          name: 'DataStory',
          params: {
            htmlFilename: story.Name,
          },
        }}},[(story.ThumbnailLink)?_c('div',{staticClass:"story-tile-img__img",style:({
            'background-image': `url(${story.ThumbnailLink})`,
            'min-height': '200px',
          })}):(story.ThumbnailExtension)?_c('div',{staticClass:"story-tile-img__img",staticStyle:{"min-height":"200px"}}):_vm._e()]),_c('div',{staticClass:"story-tile-text"},[_c('div',{staticClass:"story-category"},[(story.TopStory)?_c('span',[_vm._v("Feature Story")]):_vm._e()]),_c('h2',{staticClass:"story-title",class:{ 'story-title--main': index === 0 }},[_c('router-link',{attrs:{"to":{
              name: 'DataStory',
              params: {
                htmlFilename: story.Name,
              },
            }}},[_vm._v(" "+_vm._s(story.title)+" ")])],1),_c('p',{directives:[{name:"show",rawName:"v-show",value:(index === 0),expression:"index === 0"}],staticClass:"story-description"},[_vm._v(" "+_vm._s(story.shortDescription)+" ")]),_c('div',{staticClass:"story-author"},[_vm._v(" "+_vm._s(_vm._f("dateFormat")(story.PublicationDate))+" ")]),_c('div',{staticClass:"story-link"})])],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }