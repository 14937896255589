export default function (store, tabletBreakpoint, desktopBreakpoint) {
  const tabletQuery = `screen and (min-width: ${tabletBreakpoint}px) and (max-width: ${
    desktopBreakpoint - 1
  }px)`;
  const mobileQuery = `screen and (max-width: ${tabletBreakpoint - 1}px)`;

  let initialValue = determineInitialScreensize(tabletBreakpoint, desktopBreakpoint);
  store.dispatch("setScreenSize", initialValue);

  let tabletMediaQuery = window.matchMedia(tabletQuery);
  let mobileMediaQuery = window.matchMedia(mobileQuery);

  tabletMediaQuery.addListener((mediaQueryList) => {
    if (mediaQueryList.matches) {
      store.dispatch("setScreenSize", "tablet");
    } else {
      store.dispatch("setScreenSize", "desktop");
    }
  });

  mobileMediaQuery.addListener((mediaQueryList) => {
    if (mediaQueryList.matches) {
      store.dispatch("setScreenSize", "mobile");
    }
  });
}

function determineInitialScreensize(tabletBreakpoint, desktopBreakpoint) {
  if (window.innerWidth >= desktopBreakpoint) {
    return "desktop";
  } else if (
    window.innerWidth < desktopBreakpoint &&
    window.innerWidth >= tabletBreakpoint
  ) {
    return "tablet";
  } else {
    return "mobile";
  }
}
