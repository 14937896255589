import * as _ from "lodash";
import * as log from "loglevel";
import Vue from "vue";
import Router from "vue-router";
import store from "./store";

import NCCR from "./views/NCCR";
import Documentation from "./views/Documentation";
import PandemicDatabase from "./views/PandemicDatabase";
import PandemicDatabaseApplication from "./views/PandemicDatabaseApplication";

import pageDataArray from "./pageDataArray";
import PandemicDatabasePerson from "./views/PandemicDatabasePerson";
import DataStory from "@/views/DataStory";
import DataStoryIndex from "@/views/DataStoryIndex";
import Home from "@/views/Home";
import KeyFiguresPage from "@/views/KeyFiguresPage";
import KeyFiguresHome from "@/views/KeyFiguresHome";
import GrantRegistry from "@/views/GrantRegistry";
import GrantRegistryProject from "@/views/GrantRegistryProject";
import GrantRegistryPerson from "@/views/GrantRegistryPerson";
import DocumentationRaw from "@/views/DocumentationRaw";
import { PRISMIC_ID_404 } from "@/utils";
import NotFound404 from '@/views/NotFound404.vue';

const pageDataObject = _.keyBy(pageDataArray, "entityUrl");

const keyFiguresEntityUrl = `/key-figures/:entity(${_.join(
  _.map(_.filter(pageDataArray, { pageType: "CoreFigures" }), (p) => {
    return _.join(p.url.replace("/key-figures/", ""), "");
  }),
  "|",
)})`;

log.debug(keyFiguresEntityUrl);

Vue.use(Router);

const router = new Router({
  // TODO: check server settings before using history mode
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (store.state.scrollPosition) {
      return store.state.scrollPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      component: Home,
      name: "Home",
    },
    {
      path: "/impressum",
      component: Documentation,
      props: { cmsId: "XoY6-xAAAA4EoH9f" },
    },
    {
      path: "/datasets",
      component: DocumentationRaw,
      name: "Datasets",
      props: { cmsId: "YYpS5hEAACQAm2Xx", maxWidth: "1024px" },
    },
    // {
    //   path: "/not-found",
    //   component: Documentation,
    //   props: { cmsId: PRISMIC_ID_404 },
    // },
    {
      path: "/stories/documentation",
      component: DocumentationRaw,
      props: { cmsId: "YYpmIBEAACIAm7z7" },
    },
    {
      path: "/stories/:htmlFilename",
      component: DataStory,
      name: "DataStory",
      props: true,
    },
    {
      path: "/stories",
      component: DataStoryIndex,
      name: "DataStoryIndex",
    },
    {
      path: "/about/dataportal",
      component: DocumentationRaw,
      props: { cmsId: "YYpVYREAACIAm3GB" },
    },
    {
      path: "/about/open-data-policy",
      component: DocumentationRaw,
      props: { cmsId: "YYpWuxEAACQAm3fZ" },
    },
    {
      path: "/about/glossary",
      component: DocumentationRaw,
      props: {
        cmsId: [
          "YY6GoBEAACMAYJ2O",
          "YY6HQxEAACMAYKCN",
          "YY6HuxEAACQAYKK3",
          "YYvbOBEAACQAVQC6",
          "YYvmLBEAACIAVRzL",
        ],
      },
    },
    {
      path: "/grants",
      component: GrantRegistry,
      name: "GrantRegistry",
    },
    {
      path: "/grants/documentation",
      component: DocumentationRaw,
      props: { cmsId: "YR5isxIAACEAdguB" },
    },
    {
      path: "/grants/grant/:grantNumber",
      component: GrantRegistryProject,
      name: "GrantRegistryProject",
      props: true,
    },
    {
      path: "/grants/person/:personId",
      component: GrantRegistryPerson,
      name: "GrantRegistryPerson",
      props: true,
    },
    {
      path: "/covid-19",
      name: "PandemicDatabase",
      component: PandemicDatabase,
    },
    {
      path: "/covid-19/documentation",
      name: "Covid19FAQ",
      component: Documentation,
      props: { cmsId: pageDataObject.covidDocumentation.cmsId },
    },
    {
      path: "/covid-19/dataset",
      name: "Covid19Dataset",
      component: Documentation,
      props: { cmsId: pageDataObject.covidDataset.cmsId },
    },
    {
      path: "/covid-19/person/:personNumber",
      name: "PandemicDatabasePerson",
      component: PandemicDatabasePerson,
      props: true,
    },
    {
      path: "/covid-19/:origin/:applicationNumber",
      name: "PandemicDatabaseApplication",
      component: PandemicDatabaseApplication,
      props: true,
    },
    {
      path: "/key-figures",
      name: "cfHome",
      component: KeyFiguresHome,
    },
    {
      path: "/key-figures/documentation",
      name: "Documentation",
      component: DocumentationRaw,
      props: {
        cmsId: "YZTUuREAACIAfBpm",
      },
    },
    {
      path: "/key-figures/nccr",
      name: "NCCR",
      component: NCCR,
    },
    {
      path: keyFiguresEntityUrl,
      component: KeyFiguresPage,
      props: true,
    },
    {
      path: "/not-found",
      component: NotFound404,
      name: "NotFound404WithPath",
    },
    {
      path: "*",
      component: NotFound404,
      name: "NotFound404",
    },
    // {
    //   path: "*",
    //   component: Documentation,
    //   props: { cmsId: PRISMIC_ID_404 },
    // },
  ],
});

export default router;
