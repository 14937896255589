<template>
  <div class="grant-registry-project">
    <div v-if="errorMessage">
      <section class="grant-section title-section">
        {{ errorMessage }}
      </section>
    </div>
    <div v-if="grant && cmsData && !errorMessage">
      <section class="grant-section title-section">
        <h2>
          {{ grant.Title }}
        </h2>

        <div class="applicants">
          <!-- prettier-ignore -->
          <span
              :key="applicant.personId"
              v-for="(applicant, index) in grant.frontendApplicants"
          >
            <a class="person-link" :href="`/grants/person/${applicant.personId}`">{{ applicant.personName }}</a>
            <span v-if="index !== grant.frontendApplicants.length - 1">, </span>
          </span>
        </div>

        <div class="dates">
          {{ grant.EffectiveGrantStartDate | dateFormat }}
          <span v-if="grant.EffectiveGrantEndDate">
            – {{ grant.EffectiveGrantEndDate | dateFormat }}
          </span>
        </div>

        <div style="margin-top: 1em" v-if="$route.query.debug">
          SOLR Queries for Testing/Debugging (-> remove before deployment)
          <div :key="index" v-for="(solrQuery, index) in solrQueries">
            <a style="color: #005531" :href="solrQuery">{{ solrQuery }}</a>
          </div>
        </div>
      </section>

      <section class="grant-section detail-section">
        <div class="main">
          <section
            class="grant-main-section grant-summary"
            v-show="grant.State !== 'approved'"
          >
            <div class="summary-titles">
              <h3
                @click="abstractShow = false"
                class="summary-title"
                :class="{ selected: !abstractShow }"
              >
                {{ cmsTranslationByKey("ProjectSummary") }}
              </h3>
              <h3
                @click="abstractShow = true"
                class="summary-title"
                :class="{ selected: abstractShow }"
              >
                {{ cmsTranslationByKey("ScientificAbstract") }}
              </h3>
            </div>

            <div class="long-text">
              <div v-if="abstractShow">
                {{
                  objectAttributeInLanguage(grant, "Abstract")
                    | truncate(!abstractShowMore, { length: 430 })
                }}
              </div>
              <div v-else v-html="laySummaryTruncated"></div>

              <div
                v-if="
                  (abstractShow && grant.Abstract && grant.Abstract.length > 420) ||
                  (!abstractShow && laySummary.length > 420)
                "
              >
                <div
                  v-if="abstractShowMore"
                  class="icon-link"
                  @click="abstractShowMore = false"
                >
                  <div class="text">
                    {{ cmsTranslationByKey("ShowLess") }}
                  </div>
                  <div class="icon">
                    <img src="/img/arrow-up-light.svg" alt="down" />
                  </div>
                </div>
                <div v-else class="icon-link" @click="abstractShowMore = true">
                  <div class="text">
                    {{ cmsTranslationByKey("ShowMore") }}
                  </div>
                  <div class="icon">
                    <img src="/img/arrow-down-light.svg" alt="down" />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="grant-main-section grant-people">
            <h3>{{ cmsTranslationByKey("PeopleInvolved") }}</h3>

            <div>
              <h4>
                {{ cmsTranslationByKey("ResponsibleApplicantAndCoApplicants") }}
              </h4>
              <ul class="people-list">
                <!-- prettier-ignore -->
                <li :key="person.personId" v-for="person in grant.frontendApplicants">
                  <a class="person-link" :href="`/grants/person/${person.personId}`">{{ person.personName }}</a>
                  <span v-if="person.personInstitute">, {{ person.personInstitute }}</span>
                </li>
              </ul>
            </div>

            <div v-if="grant.employees.length">
              <h4>{{ cmsTranslationByKey("Employees") }}</h4>
              <ul class="people-list">
                <!-- prettier-ignore -->
                <li :key="person.personId" v-for="person in grant.employees">
                  <a class="person-link" :href="`/grants/person/${person.personId}`">{{ person.personName }}</a>
                  <span v-if="person.personInstitute">, {{ person.personInstitute }}</span>
                </li>
              </ul>
            </div>

            <div v-if="grant.projectPartners.length">
              <h4>{{ cmsTranslationByKey("ProjectPartners") }}</h4>
              <ul class="people-list">
                <!-- prettier-ignore -->
                <li :key="person.personId" v-for="person in grant.projectPartners">
                  <a class="person-link" :href="`/grants/person/${person.personId}`">{{ person.personName }}</a>
                  <span v-if="person.personInstitute">, {{ person.personInstitute }}</span>
                </li>
              </ul>
            </div>

            <div v-if="grant.contactPersons.length">
              <h4>{{ cmsTranslationByKey("ContactPersons") }}</h4>
              <ul class="people-list">
                <!-- prettier-ignore -->
                <li :key="person.personId" v-for="person in grant.contactPersons">
                  <a class="person-link" :href="`/grants/person/${person.personId}`">{{ person.personName }}</a>
                  <span v-if="person.personInstitute">, {{ person.personInstitute }}</span>
                </li>
              </ul>
            </div>
          </section>

          <section class="grant-main-section grant-disciplines">
            <h3>{{ cmsTranslationByKey("DisciplinesAndKeywords") }}</h3>
            <div v-if="grant.IsSubmittedWithFieldOfResearch">
              <div class="grant-disciplines-main">
                <strong>
                  {{ cmsTranslationByKey("FieldsOfResearchByResearchers") }}:
                </strong>
                {{ fieldsOfResearchDisplay }}
              </div>
              <div class="grant-disciplines-main">
                <strong>
                  {{ cmsTranslationByKey("DisciplinesMappedFromFieldsOfResearch") }}:
                </strong>
                {{ disciplineDisplay }}
              </div>
            </div>
            <div v-else>
              <div class="grant-disciplines-main">
                <strong>{{ cmsTranslationByKey("DisciplinesByResearchers") }}:</strong>
                {{ disciplineDisplay }}
              </div>
              <div class="grant-disciplines-main">
                <strong>
                  {{ cmsTranslationByKey("FieldsOfResearchMappedFromDisciplines") }}:
                </strong>
                {{ fieldsOfResearchDisplay }}
              </div>
            </div>
            <div v-if="grant.keywordsDisplay">
              <strong>{{ cmsTranslationByKey("Keywords") }}:</strong>
              {{ grant.keywordsDisplay }}
            </div>
          </section>

          <section
            v-if="filterOutputDocs('scientificPublications').length"
            class="grant-main-section grant-publications"
            id="output-scientificPublications"
          >
            <h3>{{ cmsTranslationByKey("ScientificPublications") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('scientificPublications')"
            >
              <div class="output-entry-main">
                <h3>
                  <a
                    v-if="output.OutputLink"
                    :href="output.OutputLink"
                    class="output-link"
                  >
                    {{ output.Title || output.OutputLink }}
                  </a>
                  <span v-else>
                    {{ output.Title || "TODO: Title missing in data" }}
                  </span>
                </h3>

                <div class="output-entry-section" v-if="output.AuthorOrEditor_Frontend">
                  {{ output.AuthorOrEditor_Frontend }}
                </div>

                <div
                  class="output-entry-section"
                  v-if="output.OutputBibliographicReference"
                  v-html="output.OutputBibliographicReference"
                ></div>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.Year">
                  {{ output.Year }}
                </div>
                <div v-show="output.IsOpenAccessStatus" class="output-entry-section">
                  <span class="mobile-comma">,</span>
                  {{ cmsTranslationByKey("OpenAccess") }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('dataSets').length"
            class="grant-main-section grant-publications"
            id="output-dataSets"
          >
            <h3>{{ cmsTranslationByKey("Datasets") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('dataSets')"
            >
              <div class="output-entry-main">
                <h3>
                  <a
                    v-if="output.RepositoryLink"
                    :href="output.RepositoryLink"
                    class="output-link"
                  >
                    {{ output.Title }}
                  </a>
                  <span v-else>
                    {{ output.Title }}
                  </span>
                </h3>

                <div v-if="output.OutputDataSetAuthor" class="output-entry-section">
                  {{ output.OutputDataSetAuthor }}
                </div>

                <div v-if="output.RepositoryName" class="output-entry-section">
                  {{ cmsTranslationByKey("Repository") }}:
                  {{ output.RepositoryName }}
                </div>
              </div>
              <div class="output-entry-second">
                <div v-if="output.PublicationDate" class="output-entry-section">
                  {{ output.PublicationDate | dateFormat }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('collaborations').length"
            class="grant-main-section grant-publications"
            id="output-collaborations"
          >
            <h3>{{ cmsTranslationByKey("Collaborations") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('collaborations')"
            >
              <div class="output-entry-main">
                <h3>
                  <a v-if="output.Url" :href="output.Url" class="output-link">
                    {{ output.ResearchGroup }}
                  </a>
                  <span v-else>
                    {{ output.ResearchGroup }}
                  </span>
                </h3>

                <div class="output-entry-section">
                  <ul
                    v-if="objectAttributeInLanguage(output, 'OutputCollaborationType')"
                  >
                    <li
                      :key="index"
                      v-for="(e, index) in objectAttributeInLanguage(
                        output,
                        'OutputCollaborationType',
                      ).split(';')"
                    >
                      {{ e }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="output-entry-second">
                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'Country')"
                >
                  {{ objectAttributeInLanguage(output, "Country") }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('academicEvents').length"
            class="grant-main-section grant-publications"
            id="output-academicEvents"
          >
            <h3>{{ cmsTranslationByKey("AcademicEvents") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('academicEvents')"
            >
              <div class="output-entry-main">
                <h3>
                  <a v-if="output.Url" :href="output.Url" class="output-link">
                    {{ output.Event || "TODO: Event missing in data" }}
                  </a>
                  <span v-else>
                    {{ output.Event || "TODO: Event missing in data" }}
                  </span>
                </h3>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputSubtype')"
                >
                  {{ cmsTranslationByKey("OutputSubtype") }}:
                  {{ objectAttributeInLanguage(output, "OutputSubtype") }}
                </div>

                <div class="output-entry-section" v-if="output.ContributionTitle">
                  {{ cmsTranslationByKey("ContributionTitle") }}:
                  {{ output.ContributionTitle }}
                </div>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.Date">
                  {{ output.Date | dateFormat }}
                </div>
                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputPlace')"
                >
                  <span class="mobile-comma">,</span>
                  {{ objectAttributeInLanguage(output, "OutputPlace") }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('knowledgeTransferEvents').length"
            class="grant-main-section grant-publications"
            id="output-knowledgeTransferEvents"
          >
            <h3>{{ cmsTranslationByKey("KnowledgeTransferEvents") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('knowledgeTransferEvents')"
            >
              <div class="output-entry-main">
                <h3>
                  <a v-if="output.Url" :href="output.Url" class="output-link">
                    {{ output.Event || "TODO: Event missing in data" }}
                  </a>
                  <span v-else>
                    {{ output.Event || "TODO: Event missing in data" }}
                  </span>
                </h3>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputSubtype')"
                >
                  {{ cmsTranslationByKey("OutputSubtype") }}:
                  {{ objectAttributeInLanguage(output, "OutputSubtype") }}
                </div>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.Date">
                  {{ output.Date | dateFormat }}
                </div>
                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputPlace')"
                >
                  <span class="mobile-comma">,</span>
                  {{ objectAttributeInLanguage(output, "OutputPlace") }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('awards').length"
            class="grant-main-section grant-publications"
            id="output-awards"
          >
            <h3>{{ cmsTranslationByKey("Awards") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('awards')"
            >
              <div class="output-entry-main">
                <h3>
                  <a v-if="output.Url" :href="output.Url" class="output-link">
                    {{ output.Title }}
                  </a>
                  <span v-else>
                    {{ output.Title }}
                  </span>
                </h3>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.Year">
                  {{ output.Year }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('publicCommunications').length"
            class="grant-main-section grant-publications"
            id="output-publicCommunications"
          >
            <h3>{{ cmsTranslationByKey("PublicCommunications") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('publicCommunications')"
            >
              <div class="output-entry-main">
                <h3>
                  <a class="output-link" v-if="output.Url" :href="output.Url">
                    {{ output.Title }}
                  </a>
                  <span v-else>
                    {{ output.Title }}
                  </span>
                </h3>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputSubtype')"
                >
                  {{ cmsTranslationByKey("OutputSubtype") }}:
                  {{ objectAttributeInLanguage(output, "OutputSubtype") }}
                </div>

                <div class="output-entry-section" v-if="output.Description">
                  {{ cmsTranslationByKey("Media") }}: {{ output.Description }}
                </div>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.Year">
                  {{ output.Year }}
                </div>
                <div
                  class="output-entry-section"
                  v-if="
                    objectAttributeInLanguage(output, 'OutputPublicCommunicationRegion')
                  "
                >
                  <span class="mobile-comma">,</span>
                  {{
                    objectAttributeInLanguage(output, "OutputPublicCommunicationRegion")
                  }}
                </div>
              </div>
            </article>
          </section>

          <section
            v-if="filterOutputDocs('inspirations').length"
            class="grant-main-section grant-publications"
            id="output-inspirations"
          >
            <h3>{{ cmsTranslationByKey("UseInspiredOutputs") }}</h3>

            <article
              class="output-entry"
              :key="output.id"
              v-for="output in filterOutputDocs('inspirations')"
            >
              <div class="output-entry-main">
                <h3>
                  <a v-if="output.Url" :href="output.Url" class="output-link">
                    {{ output.Title }}
                  </a>
                  <span v-else>
                    {{ output.Title }}
                  </span>
                </h3>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputSubtype')"
                >
                  {{ cmsTranslationByKey("OutputSubtype") }}:
                  {{ objectAttributeInLanguage(output, "OutputSubtype") }}
                </div>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'Owner')"
                >
                  {{ cmsTranslationByKey("Owner") }}:
                  {{ objectAttributeInLanguage(output, "Owner") }}
                </div>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'LicenseType')"
                >
                  {{ cmsTranslationByKey("LicenseType") }}:
                  {{ objectAttributeInLanguage(output, "LicenseType") }}
                </div>

                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'ReviewerActivityType')"
                >
                  {{ cmsTranslationByKey("ReviewerActivityType") }}:
                  {{ objectAttributeInLanguage(output, "ReviewerActivityType") }}
                </div>
                <div class="output-entry-section" v-if="output.Comment">
                  {{ cmsTranslationByKey("Comment") }}: {{ output.Comment }}
                </div>

                <div class="output-entry-section" v-if="output.PatentNumber">
                  {{ cmsTranslationByKey("PatentNumber") }}:
                  {{ output.PatentNumber }}
                </div>
                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'PatentStatus')"
                >
                  {{ cmsTranslationByKey("PatentStatus") }}:
                  {{ objectAttributeInLanguage(output, "PatentStatus") }}
                  <span v-if="output.PatentDecisionDate">
                    ({{ output.PatentDecisionDate | dateFormat }})
                  </span>
                </div>
                <div class="output-entry-section" v-if="output.PatentOwnerDescription">
                  {{ cmsTranslationByKey("PatentOwnerDescription") }}:
                  {{ output.PatentOwnerDescription }}
                </div>
                <div class="output-entry-section" v-if="output.Inventor">
                  {{ cmsTranslationByKey("Inventor") }}: {{ output.Inventor }}
                </div>
              </div>
              <div class="output-entry-second">
                <div class="output-entry-section" v-if="output.PriorityDate">
                  {{ output.PriorityDate | dateFormat }}
                </div>
                <div class="output-entry-section" v-else-if="output.Year">
                  {{ output.Year }}
                </div>
                <div
                  class="output-entry-section"
                  v-if="objectAttributeInLanguage(output, 'OutputPlace')"
                >
                  <span class="mobile-comma">,</span>
                  {{ objectAttributeInLanguage(output, "OutputPlace") }}
                </div>
              </div>
            </article>
          </section>
        </div>

        <div class="side-right">
          <section class="grant-side-section general-info">
            <h3>{{ cmsTranslationByKey("GeneralInformation") }}</h3>
            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("ProjectID") }}</h5>
              {{ grant.GrantNumber }}
            </div>

            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("FundingInstrument") }}</h5>
              {{ objectAttributeInLanguage(grant, "FundingInstrumentPublished") }}
            </div>

            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("Call") }}</h5>
              {{ objectAttributeInLanguage(grant, "CallFullTitle") }}
            </div>

            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("ApprovedAmount") }}</h5>
              {{ numberToStringPandemic(grant.AmountGrantedAllSets) }} CHF
            </div>

            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("State") }}</h5>
              {{ objectAttributeInLanguage(grant, "State") }}
            </div>

            <div
              class="general-info-entry"
              v-if="objectAttributeInLanguage(grant, 'ResearchInstitution')"
            >
              <h5>{{ cmsTranslationByKey("ResearchInstitution") }}</h5>
              {{ objectAttributeInLanguage(grant, "ResearchInstitution") }}
            </div>

            <div class="general-info-entry">
              <h5>{{ cmsTranslationByKey("Institution") }}</h5>
              {{ objectAttributeInLanguage(grant, "Institute") }}
            </div>
          </section>

          <section class="grant-side-section output-count" v-if="totalNumOutputs > 0">
            <h3>{{ cmsTranslationByKey("Outputs") }}</h3>

            <div
              :key="index"
              v-for="(output, index) in this.outputs"
              v-show="output.docs?.length"
            >
              <a class="person-link" @click="clickOutputLink(output.key)">
                {{ output.docs?.length }}
                {{ cmsTranslationByKey(output.title) }}
              </a>
            </div>
          </section>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import * as log from "loglevel";
import * as _ from "lodash";

import { buildEntityQuery } from "@/solrHelpers";
import { mapGetters } from "vuex";
import {
  makeSolrPostRequest,
  numberToStringPandemic,
  OUTPUT_DATA_TYPES,
} from "@/utils";
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "GrantRegistryProject",
  mixins: [cmsTranslationMixin],
  components: {},
  props: ["grantNumber"],
  metaInfo: function () {
    if (this.grant && this.cmsData) {
      return {
        title: this.grant.Title,
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: `${this.cmsTranslationByKey("TitleGrantRegistry")}: ${
              this.grant.Title
            }`,
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: _.truncate(this.grant.Abstract, {
              length: 150,
              separator: "...",
            }),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_grant_search.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      grant: null,
      errorMessage: null,
      solrQueries: [],
      abstractShow: true,
      abstractShowMore: false,
      outputs: [],
    };
  },
  mounted: function () {
    log.debug("GrantRegistryProject mounted");
    this.loadPageData();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "cmsPageDataArray", "screenSize"]),
    laySummary: function () {
      if (this.grant && this.objectAttributeInLanguage(this.grant, "LaySummary")) {
        return this.objectAttributeInLanguage(this.grant, "LaySummary");
      }
      return "";
    },
    laySummaryTruncated: function () {
      if (this.abstractShowMore) {
        return this.laySummary;
      } else {
        return _.truncate(this.laySummary, { length: 420 });
      }
    },
    disciplineDisplay: function () {
      const mainDiscipline = this.joinSplitMvfField(
        this.objectAttributeInLanguage(this.grant, "MainDiscipline", "_mvf"),
      );
      if (this.objectAttributeInLanguage(this.grant, "SecondaryDiscipline", "_mvf")) {
        return (
          mainDiscipline +
          ", " +
          this.joinSplitMvfField(
            this.objectAttributeInLanguage(this.grant, "SecondaryDiscipline", "_mvf"),
          )
        );
      } else {
        return mainDiscipline;
      }
    },
    fieldsOfResearchDisplay: function () {
      const mainField = this.joinSplitMvfField(
        this.objectAttributeInLanguage(this.grant, "MainFieldOfResearch", "_mvf"),
      );
      if (
        this.objectAttributeInLanguage(this.grant, "SecondaryFieldOfResearch", "_mvf")
      ) {
        return (
          mainField +
          ", " +
          this.joinSplitMvfField(
            this.objectAttributeInLanguage(
              this.grant,
              "SecondaryFieldOfResearch",
              "_mvf",
            ),
          )
        );
      } else {
        return mainField;
      }
    },
    totalNumOutputs: function () {
      return _.sumBy(this.outputs, (o) => {
        return o.docs ? o.docs.length : 0;
      });
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    numberToStringPandemic(num) {
      return numberToStringPandemic(num, this.selectedLanguage);
    },
    loadPageData: function () {
      if (this.selectedLanguage) {
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.refetchSolrData();
    },
    buildApplicationNumberQuery(attributeQueryPart) {
      return buildEntityQuery(attributeQueryPart, 100, "pandemic", "pandemic");
    },
    refetchSolrData: function () {
      this.solrQueries = [];
      const grantQuery = `/solr/search/select/?start=0&rows=1&q=Category:search+AND+Entity:Grant+AND+GrantNumber:${this.grantNumber}`;
      this.solrQueries.push(grantQuery);
      axios
        .get(grantQuery)
        .then((r) => {
          if (r.data.response.numFound <= 0) {
            this.$router.push({ name: "NotFound404WithPath" });
            return;
          }
          this.grant = r.data.response.docs[0];

          if (!this.grant) {
            this.errorMessage = this.cmsTranslationByKey("ErrorOnLoadingData");
            this.$router.push({ name: "NotFound404WithPath" });
            return;
          }

          if (
            ["non disponible", "nicht vorhanden", "not provided"].includes(
              this.objectAttributeInLanguage(this.grant, "Abstract"),
            )
          ) {
            this.abstractShow = false;
          }

          this.grant.frontendApplicants = this.createPeopleArray(
            "AllApplicant",
            "AllApplicant_PersonId_mvf",
          );
          this.grant.projectPartners = this.createPeopleArray("ProjectPartner");
          this.grant.employees = this.createPeopleArray("Employee");
          this.grant.contactPersons = this.createPeopleArray("ContactPerson");

          this.grant.keywordsDisplay = _.join(
            _.split(this.grant.Keywords_mvf, ";"),
            ", ",
          );

          // read outputs
          this.grant.outputs = _.map(OUTPUT_DATA_TYPES, (o) => {
            const fieldValue = this.grant[o.grantSolrField];
            return _.assign(
              {
                values: fieldValue ? _.split(fieldValue, ";") : [],
                solrGetRequest: null,
                docs: null,
                sortField: o.sortField,
              },
              o,
            );
          });

          // make queries for all outputs
          _.forEach(this.grant.outputs, (o) => {
            const queryPart = _.join(
              _.map(o.values, (v) => {
                return `${o.solrField}:${v}`;
              }),
              "+OR+",
            );

            const solrOutputQueryData = {
              start: 0,
              rows: 500,
              q: `(${queryPart})+AND+Entity:${o.entity}`,
            };
            if (o.sortField) {
              solrOutputQueryData.sort = `${o.sortField}+DESC`;
            }

            if (o.values.length > 0) {
              let solrQuery = `/solr/search/select/?rows=100&q=(${queryPart})+AND+Entity:${o.entity}`;
              if (o.sortField) {
                solrQuery += `&sort=${o.sortField}+DESC`;
              }
              this.solrQueries.push(solrQuery);
              o.solrPostRequest = makeSolrPostRequest(solrOutputQueryData);
            }
          });

          let outputs = _.cloneDeep(this.grant.outputs);
          axios
            .all(
              _.map(
                _.filter(this.grant.outputs, (o) => {
                  return o.solrPostRequest;
                }),
                "solrPostRequest",
              ),
            )
            .then((responses) => {
              _.forEach(responses, (response) => {
                let docs = response.data.response.docs;
                const entity = docs[0].Entity;
                const index = _.findIndex(outputs, { entity: entity });

                // DSS-993: remove "empty" entries
                // filter out country "Tauschland"
                docs = docs.filter((doc) => {
                  return doc.Country !== "Tauschland";
                });

                if (entity === "OutputScientificPublication") {
                  docs = docs.filter((doc) => {
                    return doc.Title;
                  });
                }

                if (entity === "OutputDataSet") {
                  docs = docs.filter((doc) => {
                    return doc.Title && doc.RepositoryLink;
                  });
                }

                if (entity === "OutputDataSet") {
                  docs = docs.filter((doc) => {
                    return doc.Title && doc.RepositoryLink;
                  });
                }

                if (
                  ["OutputAcademicEvent", "OutputKnowledgeTransferEvent"].includes(
                    entity,
                  )
                ) {
                  docs = docs.filter((doc) => {
                    return doc.Event;
                  });
                }

                if (entity === "OutputAward") {
                  docs = docs.filter((doc) => {
                    return doc.Title || doc.Url;
                  });
                }

                outputs[index].docs = docs;
              });
            })
            .finally(() => {
              this.outputs = outputs;
            })
            .catch((error) => {
              console.error(error);
              this.errorMessage = this.cmsTranslationByKey("ErrorOnLoadingData");
            });
        })
        .catch((error) => {
          console.error(error);
          this.errorMessage = this.cmsTranslationByKey("ErrorOnLoadingData");
          this.$router.push({ name: "NotFound404WithPath" });
        });
    },
    createPeopleArray: function (fieldPrefix, personIdField) {
      function nameSplit(str) {
        if (str) {
          return _.split(str.replaceAll("&amp;", "&"), ";");
        }
        return str;
      }

      if (
        personIdField
          ? this.grant[personIdField]
          : this.grant[`${fieldPrefix}_PersonId_mvf`]
      ) {
        return _.map(
          _.zip(
            nameSplit(
              personIdField
                ? this.grant[personIdField]
                : this.grant[`${fieldPrefix}_PersonId_mvf`],
            ),
            nameSplit(this.grant[`${fieldPrefix}Name_mvf`]),
            nameSplit(this.grant[`${fieldPrefix}Institute_mvf`]),
            nameSplit(
              this.objectAttributeInLanguage(
                this.grant,
                `${fieldPrefix}ResearchInstitution`,
                "_mvf",
              ),
            ),
          ),
          (data) => {
            return {
              personId: data[0],
              personName: data[1],
              personInstitute: data[2],
              personResearchInstitution: data[3],
            };
          },
        );
      }
      return [];
    },
    filterOutputDocs: function (outputKey) {
      const outputEntry = _.find(this.outputs, { key: outputKey });
      if (outputEntry && outputEntry.docs && outputEntry.docs.length > 0) {
        return outputEntry.docs;
      }
      return [];
    },
    clickOutputLink(key) {
      document.getElementById(`output-${key}`).scrollIntoView();
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.grant-registry-project {
  border-top: 1px solid $snf-gray-light;
}

.title-section {
  margin-top: 32px;
  margin-bottom: 32px;

  .applicants {
    margin-bottom: 8px;
  }
}

.people-list {
  line-height: 1.5;
  margin: 16px 0 32px 0;
  list-style-type: disc;

  li {
    margin: 1em;
    padding: 0;
  }
}

.person-link {
  color: $snf-blue-font;

  &:hover {
    color: $snf-blue-dark;
  }
}

.output-link {
  color: $snf-blue-font;

  &:hover {
    color: $snf-blue-dark;
  }
}

.grant-section {
  padding: 0 24px;
}

.grant-disciplines-main {
  margin-bottom: 8px;
}

.detail-section {
  display: flex;
  flex-direction: column-reverse;

  .main {
    .summary-titles {
      display: flex;

      .summary-title {
        flex: 1 1 33%;
        padding-bottom: 0;
        border-bottom: 1px solid $snf-gray-medium;
        cursor: pointer;
        font-weight: 400;

        &.selected {
          border-bottom: 3px solid $snf-gray-medium;
          font-weight: 700;
        }
      }
    }
  }
}

.grant-main-section {
  margin-bottom: 3em;
}

.grant-side-section {
  margin-bottom: 3em;
}

.general-info-entry {
  margin-bottom: 16px;

  h5 {
    margin-bottom: 6px;
  }
}

.icon-link {
  .icon {
    @include snf-blue-font-filter;
    margin-left: 8px;
  }

  cursor: pointer;
  margin-top: 16px;
  display: flex;
  color: $snf-blue-font;
}

.output-entry {
  border-top: $snf-gray-light 1px solid;
  padding-top: 16px;
  padding-bottom: 16px;

  ul {
    li {
      list-style-type: disc;
      margin: 8px 16px;
      padding: 0;
    }
  }

  .mobile-comma {
    margin-right: 3px;
  }

  .output-entry-second {
    font-size: 14px;
    line-height: 20px;
    color: $snf-gray-darker;
    display: flex;
  }

  .output-entry-section {
    margin-bottom: 8px;
  }

  h3 {
    font-size: 18px;
    line-height: 1.5em;
    margin-bottom: 8px;
  }
}

@include desktop() {
  .grant-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1046px;
  }

  .detail-section {
    flex-direction: row;

    .main {
      flex: 1 1 auto;
      margin-right: 40px;
    }

    .side-right {
      flex: 0 0 280px;
      margin-left: 40px;
    }
  }

  .output-entry {
    display: flex;

    .mobile-comma {
      display: none;
    }

    .output-entry-main {
      flex: 1 1 auto;
    }

    .output-entry-second {
      flex: 0 0 132px;
      padding-left: 18px;
      flex-direction: column;
    }

    h3 {
      font-size: 20px;
    }
  }
}
</style>
