<template>
  <div
    class="documentation-raw"
    :style="{ 'max-width': maxWidth ? maxWidth : '768px' }"
  >
    <div v-html="textHtml" class="prismic-raw" />
  </div>
</template>

<script>
// @ is an alias to /src
import { mapGetters } from "vuex";
import * as _ from "lodash";
import PrismicDOM from "prismic-dom";
import * as log from "loglevel";

export default {
  name: "Documentation",
  props: ["cmsId", "maxWidth"],
  data: function () {
    return {
      headerTitle: "",
      textData: [],
      textHtml: "",
    };
  },
  mounted: function () {
    log.debug(this.cmsId);
    this.loadPageData();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "screenSize"]),
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData() {
      if (this.selectedLanguage && this.cmsData) {
        log.debug("Documentation loadPageData!");
        const pageData = _.find(this.cmsData, { id: this.cmsId })[
          this.selectedLanguage
        ];
        this.textData = pageData.data.text;
        this.textHtml = PrismicDOM.RichText.asHtml(pageData.data.text);
        this.headerTitle = pageData.data.title[0].text;
      }
    },
  },
};
</script>

<style lang="scss"></style>
