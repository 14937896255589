<template>
  <div class="pandemic-database-application" v-if="pageData.colorClass">
    <core-figures-header
      :title="pageData.pageTitle"
      :text="pageData.pageHeaderText"
      :color-class="'cf-header--' + pageData.colorClass"
    />

    <section class="section snf-section padding-top-2">
      <div class="columns snf-section__columns pandemic__columns">
        <div class="container column is-full">
          <h2 class="page-header">
            {{ application.Title }}
          </h2>

          <table>
            <tbody>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("Applicants") }}
                </td>
                <td>
                  <span v-if="applicationApplicants.length">
                    <router-link
                      v-for="person in applicationApplicants"
                      :key="person.PersonNumber"
                      class="application-link"
                      :to="{
                        name: 'PandemicDatabasePerson',
                        params: {
                          personNumber: person.PersonNumber,
                        },
                      }"
                    >
                      {{ person.Name }};
                    </router-link>
                  </span>
                  <span v-if="!applicationApplicants.length">
                    {{ application.Applicants }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("ProjectPartners") }}
                </td>
                <td>
                  <span v-if="applicationProjectPartners.length">
                    <router-link
                      v-for="person in applicationProjectPartners"
                      :key="person.PersonNumber"
                      class="application-link"
                      :to="{
                        name: 'PandemicDatabasePerson',
                        params: {
                          personNumber: person.PersonNumber,
                        },
                      }"
                    >
                      {{ person.Name }};
                    </router-link>
                  </span>
                  <span v-if="!applicationProjectPartners.length">
                    {{ application.ProjectPartners }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("Funder") }}
                </td>
                <td>{{ objectAttributeInLanguage(application, "Funder") }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("Institute") }}
                </td>
                <td>{{ application.Institute }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("ResearchInstitution") }}
                </td>
                <td>
                  {{
                    objectAttributeInLanguage(application, "ResearchInstitutionGroup")
                  }}
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("EffectiveStartDate") }} /
                  {{ cmsTranslationByKey("EffectiveEndDate") }}
                </td>
                <td>
                  {{ application.EffectiveStartDate | dateFormat }}
                  <span v-if="application.EffectiveEndDate">
                    - {{ application.EffectiveEndDate | dateFormat }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("AmountGranted") }}
                </td>
                <td>{{ numberToStringPandemic(application.AmountGranted) }}</td>
              </tr>
              <tr v-show="application.AmountGrantedGlobal">
                <td class="table-header">
                  {{ cmsTranslationByKey("AmountGrantedGlobal") }}
                </td>
                <td>
                  {{ numberToStringPandemic(application.AmountGrantedGlobal) }}
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("State") }}
                </td>
                <td>{{ objectAttributeInLanguage(application, "State") }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("FundingInstrumentGaPublished") }}
                </td>
                <td>
                  {{
                    objectAttributeInLanguage(
                      application,
                      "FundingInstrumentGaPublished",
                    )
                  }}
                </td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("ApplicationNumber") }}
                </td>
                <td>
                  {{
                    application.ApplicationNumber || application.ApplicationNumberString
                  }}
                </td>
              </tr>

              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("Keywords") }}
                </td>
                <td>{{ application.Keywords }}</td>
              </tr>
              <tr>
                <td class="table-header">
                  {{ cmsTranslationByKey("MainDiscipline") }}
                </td>
                <td>
                  {{ objectAttributeInLanguage(application, "MainDiscipline") }}
                </td>
              </tr>
              <tr v-show="application.URL">
                <td class="table-header">
                  {{ cmsTranslationByKey("Pandemic_ApplicationEU.URL") }}
                </td>
                <td>
                  <a :href="application.URL" target="_blank">
                    {{ application.URL }}
                  </a>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="margin-top-2"
            v-show="application.LaySummary && application.LaySummary !== '-'"
          >
            <h4 class="section-header">
              {{ cmsTranslationByKey("LaySummary") }}
            </h4>
            <div v-html="application.LaySummary" />
          </div>

          <div class="margin-top-2">
            <h4 class="section-header">
              {{ cmsTranslationByKey("Abstract") }}
            </h4>
            <p>{{ application.Abstract }}</p>
          </div>
        </div>
      </div>
    </section>

    <ScientificPublications
      :publications="scientificPublicationsOpenAccess"
      :open-access="true"
    />

    <ScientificPublications
      :publications="scientificPublicationsOther"
      :open-access="false"
    />

    <section class="section snf-section padding-top-2" v-if="outputDataSets.length > 0">
      <div class="columns snf-section__columns pandemic__columns">
        <div class="container column is-full">
          <h4 class="section-header">
            {{ cmsTranslationByKey("SubheaderDatasets") }}
          </h4>

          <div v-for="pub in outputDataSets" :key="pub.PID">
            <h3 class="pub-title">
              <a :href="pub.RepositoryLink" target="_blank" class="publication-link">
                {{ pub.Title }}
              </a>
            </h3>
            <div>
              {{ pub.Author }}
            </div>
            <div class="color-gray">
              <table style="width: 100%">
                <tr>
                  <td class="table-header">
                    {{ cmsTranslationByKey("PublicationDate") }}
                  </td>
                  <td>{{ pub.PublicationDate | dateFormat }}</td>
                </tr>
                <tr>
                  <td class="table-header">
                    {{ cmsTranslationByKey("OutputDataSet.PID") }}
                  </td>
                  <td>
                    {{ pub.PID }}
                  </td>
                </tr>
                <tr>
                  <td class="table-header">
                    {{ cmsTranslationByKey("OutputDataSet.RepositoryName") }}
                  </td>
                  <td>
                    {{ pub.RepositoryName }}
                  </td>
                </tr>
              </table>
            </div>
            <div v-html="pub.Abstract" class="margin-top-1" />
          </div>
        </div>
      </div>
    </section>

    <div class="padding-top-3" />
  </div>
</template>

<script>
import axios from "axios";

import * as _ from "lodash";
import * as log from "loglevel";
import SolrSelect from "../components/keyFigures/SolrSelect.vue";
import { buildEntityQuery } from "../solrHelpers";
import CoreFiguresHeader from "../components/keyFigures/CoreFiguresHeader.vue";
import { mapGetters } from "vuex";
import {
  numberToStringPandemic,
  PANDEMIC_APPLICANT_ROLE_ID,
  PANDEMIC_CO_APPLICANT_ROLE_ID,
  PANDEMIC_PROJECT_PARTNER_ROLE_ID,
  pandemicApplicationEntitySubquery,
} from "../utils";
import ScientificPublications from "../components/ScientificPublications";
import { cmsTranslationMixin } from "../mixins";

export default {
  name: "PandemicDatabaseApplication",
  mixins: [cmsTranslationMixin],
  components: { CoreFiguresHeader, ScientificPublications },
  props: ["applicationNumber", "origin"],
  metaInfo: function () {
    if (this.selectedLanguage && this.cmsData) {
      return {
        title: this.cmsTranslationByKey("TitleCovid"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleCovid"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("ProjektregisterIntroShort"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_covid19.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      entity: "pandemic",
      pageData: {},
      pageIndex: null,
      application: {
        LaySummary_De: "",
      },
      applicationPersons: [],
      applicationProjectPartners: [],
      applicationApplicants: [],
      scientificPublicationsOpenAccess: [],
      scientificPublicationsOther: [],
      outputDataSets: [],
    };
  },
  mounted: function () {
    log.debug("PandemicDatabaseApplication mounted");
    this.loadPageData();
  },
  directives: { SolrSelect },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "cmsPageDataArray", "screenSize"]),
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    numberToStringPandemic(num) {
      return numberToStringPandemic(num, this.selectedLanguage);
    },
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.pageData = _.find(this.cmsPageDataArray, {
          entityUrl: this.entity,
        });
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      this.refetchSolrData();
    },
    buildApplicationNumberQuery(attributeQueryPart) {
      return buildEntityQuery(attributeQueryPart, 100, "pandemic", "pandemic");
    },
    refetchSolrData: function () {
      axios
        .get(
          this.buildApplicationNumberQuery(
            `(${pandemicApplicationEntitySubquery})+AND+${
              _.isInteger(this.applicationNumber) ||
              this.applicationNumber.match(/^[0-9]+$/)
                ? `ApplicationNumber:${this.applicationNumber}`
                : `ApplicationNumberString:"${this.applicationNumber}"`
            }`,
          ),
        )
        .then((r1) => {
          this.application = _.first(r1.data.response.docs);

          // get lay summary in selected language or in other language
          this.application.LaySummary =
            this.objectAttributeInLanguage(this.application, "LaySummary") ||
            this.application.LaySummary_De ||
            this.application.LaySummary_Fr ||
            this.application.LaySummary_En;

          if (this.application.ApplicationNumber) {
            axios
              .get(
                this.buildApplicationNumberQuery(
                  `ApplicationNumber:${this.application.ApplicationNumber}`,
                ),
              )
              .then((r2) => {
                [
                  this.scientificPublicationsOther,
                  this.scientificPublicationsOpenAccess,
                ] = _.partition(
                  _.orderBy(
                    _.filter(r2.data.response.docs, {
                      Entity: "ScientificPublication",
                    }),
                    ["PublishedDate"],
                    ["desc"],
                  ),
                  {
                    OpenAccessStatus: "closed",
                  },
                );
                log.debug(JSON.parse(JSON.stringify(this.scientificPublicationsOther)));
                log.debug(
                  JSON.parse(JSON.stringify(this.scientificPublicationsOpenAccess)),
                );

                this.applicationPersons = _.map(
                  _.filter(r2.data.response.docs, {
                    Entity: "ApplicationPerson",
                  }),
                  (p) => {
                    p.Name = `${p.Surname} ${p.FirstName}`;
                    return p;
                  },
                );
                log.debug(JSON.parse(JSON.stringify(this.applicationPersons)));

                this.applicationApplicants = _.concat(
                  _.filter(this.applicationPersons, {
                    RoleId: PANDEMIC_APPLICANT_ROLE_ID,
                  }),
                  _.filter(this.applicationPersons, {
                    RoleId: PANDEMIC_CO_APPLICANT_ROLE_ID,
                  }),
                );

                this.applicationProjectPartners = _.filter(this.applicationPersons, {
                  RoleId: PANDEMIC_PROJECT_PARTNER_ROLE_ID,
                });
                log.debug(JSON.parse(JSON.stringify(this.applicationProjectPartners)));

                this.outputDataSets = _.filter(r2.data.response.docs, {
                  Entity: "OutputDataSet",
                });
                log.debug(JSON.parse(JSON.stringify(this.outputDataSets)));
              });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.pandemic__columns {
  @include tablet {
    max-width: 1000px !important;
  }
}

.page-header {
  font-size: 1.5rem;
  margin-top: 0;
  margin-bottom: 1em;
}

.section-header {
  font-size: 1.2rem;
  font-weight: 700;
  margin-bottom: 0.5em;
}

.table-header {
  font-weight: 700;
  width: 40%;
}

.pub-title {
  font-weight: 700;
  margin-top: 1.5em;
  margin-bottom: 0.5em;
}

.publication-link {
  color: $snf-blue-dark;

  &:hover {
    color: $snf-blue-dark;
    text-decoration: underline;
  }
}
</style>
