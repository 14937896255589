<template>
  <div class="waffle-chart" :class="{ 'waffle-chart--mobile': isMobile }">
    <figure
      aria-hidden="true"
      class="waffle-chart__chart chart"
      :class="{ 'waffle-chart__chart--mobile': isMobile }"
    >
      <div
        class="chart__dummy chart-dummy"
        :class="{
          'chart__dummy--is-visible': isEmptyData(),
          'chart__dummy--mobile': isMobile,
        }"
      >
        <p class="chart-dummy__text">
          {{ cmsTranslationByKey("NoDataForSelectedAttributes") }}
        </p>
      </div>
    </figure>
    <div
      v-show="calcShowLegend"
      class="waffle-chart__legend"
      :class="{ 'waffle-chart__legend--tablet': isTablet }"
    >
      <div class="waffle-chart__legend__graph" />
    </div>
    <div class="waffle-chart__export export">
      <div class="export__title" />
      <div class="export__chart" />
      <div class="export__legend" />
    </div>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as d3Selection from "d3-selection";
import * as d3Array from "d3-array";
import { mapGetters } from "vuex";
import { EventBus } from "../../event-bus.js";
import { numberToStringFormat } from "../../utils";
import exportChart from "../../export";
import { cmsTranslationMixin } from "@/mixins";

const numberOfColors = 9;

const numberOfSquares = {
  // must be a multiple of numberOfSquaresOnXAxis
  desktop: 1080,
  tablet: 930,
  mobile: 840,
};

const numberOfSquaresOnXAxis = {
  desktop: 40,
  tablet: 30,
  mobile: 24,
};

const graphWidth = {
  // must be a multiple of numberOfSquaresOnXAxis
  desktop: 576,
  tablet: 468,
  mobile: 312,
};

const legendSquareHeight = 18;
const legendLineHeight = 24;

const labelHeight = 16;

export default {
  name: "WaffleChart",
  props: {
    graphInput: Array,
    filename: String,
    title: String,
    showLegend: Boolean,
    forceScreenSize: String,
  },
  mixins: [cmsTranslationMixin],
  data: function () {
    return {
      classificationData: {},
      graphWidth: 0,
      graphHeight: 0,
      squareLength: 0,
      legendWidth: 300,
      legendHeight: 0,
      total: 0,
      squaresOnXAxis: 0,
      roundedSquaresOnYAxis: 0,
      totalSquares: 0,
      adjustmentFactor: 0,
      labels: [],
    };
  },
  computed: {
    ...mapGetters(["screenSize", "cmsData", "selectedLanguage"]),
    isMobile() {
      return (this.forceScreenSize || this.screenSize) === "mobile";
    },
    isTablet() {
      return (this.forceScreenSize || this.screenSize) === "tablet";
    },
    calcShowLegend() {
      return this.showLegend && !this.isEmptyData();
    },
  },
  watch: {
    graphInput() {
      this.redraw();
    },
    screenSize() {
      this.redraw();
    },
  },
  methods: {
    redraw() {
      this.updateProperties();
      this.renderGraph();
    },
    updateProperties() {
      let filteredInput = [];

      this.graphWidth = graphWidth[this.forceScreenSize || this.screenSize];
      this.squaresOnXAxis =
        numberOfSquaresOnXAxis[this.forceScreenSize || this.screenSize];
      this.totalSquares = numberOfSquares[this.forceScreenSize || this.screenSize];

      if (this.isEmptyData()) {
        filteredInput = this.emptyDummyData();
      } else {
        filteredInput = this.filterInput(this.graphInput);
      }

      // watch and computed properties don't play along well with each other
      // as such we need to set the properties here
      this.labels = filteredInput.map(
        (item) =>
          `${item.label} (${numberToStringFormat(item.count, this.selectedLanguage)})`,
      );
      this.total = this.calculateTotal(filteredInput);
      this.roundedSquaresOnYAxis = this.calculateRoundedSquaresOnYAxis(
        this.totalSquares,
        this.squaresOnXAxis,
      );
      this.squareLength = this.calculateSquareLength(
        this.graphWidth,
        this.squaresOnXAxis,
      );
      this.graphHeight = this.calculateGraphHeight(
        this.squareLength,
        this.totalSquares,
        this.squaresOnXAxis,
      );
      this.adjustmentFactor = this.calculateAdujstmentFactor();

      // CF-106: we want to have at least one square per non zero value
      // so we count here how many classifications would not get a square
      let numAdjustedZeroes = filteredInput.reduce((previousValue, classification) => {
        const adjustedCount = this.adjustmentFactor * classification.count;
        if (adjustedCount < 0.99) {
          return previousValue + 1;
        }
        return previousValue;
      }, 0);

      this.classificationData = filteredInput
        .map((classification) => {
          // round up to 1 to have at least one square
          classification.adjustedCount = Math.max(
            this.adjustmentFactor * classification.count,
            1,
          );

          if (classification.adjustedCount > 50 && numAdjustedZeroes > 0) {
            // CF-106: handle value which would only get one square because of rounding
            // by subtracting one square from a bigger value
            classification.adjustedCount -= numAdjustedZeroes;
            numAdjustedZeroes = 0;
          }

          return classification;
        })
        .reduce((addedClassifications, item) => {
          // as we're basically concatenating all data to a big array in d3, we need to calculate
          // up to which index a class is in this big array.
          // e.g. class 1 with 10 items, class 2 with 20 items, class 3 with 5 items
          // => class 1 up to index 10, class 2 up to index 30, class 3 up to index 35
          let datapointSum = this.calculateDatapointSum(addedClassifications, item);
          let endIndex = Math.min(this.totalSquares, Math.round(datapointSum));
          addedClassifications = [
            ...addedClassifications,
            Object.assign({}, item, { endIndex }),
          ];
          return addedClassifications;
        }, []);

      // fix rounding error due to CF-106
      this.classificationData[this.classificationData.length - 1].endIndex =
        this.totalSquares;
    },

    isEmptyData() {
      return (
        this.graphInput.length === 0 ||
        this.graphInput.filter((e) => e.label !== "Total").every((e) => e.count === 0)
      );
    },

    emptyDummyData() {
      return [
        {
          count: 100,
          label: "",
        },
      ];
    },

    filterInput(input) {
      const inputWithoutTotal = input.filter((e) => {
        return e.label !== "Total";
      });
      const total = this.calculateTotal(inputWithoutTotal);
      return inputWithoutTotal.filter((e) => e.count / total > 1e-10);
    },

    calculateSquareLength(graphWidth, squaresOnXAxis) {
      return graphWidth / squaresOnXAxis;
    },

    calculateTotal(preProcessedInput) {
      return preProcessedInput.reduce((count, item) => count + item.count, 0);
    },

    calculateGraphHeight(squareLength, totalSquares, squaresOnXAxis) {
      return (squareLength * totalSquares) / squaresOnXAxis;
    },

    calculateRoundedSquaresOnYAxis(totalSquares, squaresOnXAxis) {
      return Math.round(totalSquares / squaresOnXAxis);
    },

    calculateAdujstmentFactor() {
      return this.totalSquares / this.total;
    },

    calculateDatapointSum(addedClassifications, newClassification) {
      let currentSum = addedClassifications.reduce(
        (sum, item) => sum + item.adjustedCount,
        0,
      );
      return currentSum + newClassification.adjustedCount;
    },

    renderGraph() {
      const waffleConfig = {
        waffleElementSelector: ".waffle-chart__chart",
        graphHeight: this.graphHeight,
        graphWidth: this.graphWidth,
        squareLength: this.squareLength,
        squaresOnXAxis: this.squaresOnXAxis,
        roundedSquaresOnYAxis: this.roundedSquaresOnYAxis,
        className: "waffle",
      };
      this.renderWaffleChartWithConfig(waffleConfig);
    },

    renderWaffleChartWithConfig(waffleConfig) {
      const waffleElement = d3Selection.select(waffleConfig.waffleElementSelector);

      this.renderWaffle(waffleElement, waffleConfig);
      this.renderGrid(waffleElement, waffleConfig);

      if (!this.isEmptyData()) {
        this.renderLabels(waffleElement, this);
        this.renderLegendHtml(".waffle-chart__legend__graph", this.legendWidth);
      }
    },

    renderWaffle(waffleElement, waffleConfig) {
      const squares = d3Array.range(this.totalSquares);
      waffleElement.select("svg").remove();

      waffleElement
        .append("svg")
        .attr("class", waffleConfig.className)
        .attr("width", `${waffleConfig.graphWidth}px`)
        .attr("height", () => `${waffleConfig.graphHeight}px`)
        .append("g")
        .selectAll("rect")
        .data(squares)
        .enter()
        .append("rect")
        .attr("height", `${waffleConfig.squareLength}px`)
        .attr("width", `${waffleConfig.squareLength}px`)
        .attr("x", (i) => (i % waffleConfig.squaresOnXAxis) * waffleConfig.squareLength)
        .attr(
          "y",
          (i) =>
            Math.floor(i / waffleConfig.squaresOnXAxis) * waffleConfig.squareLength,
        )
        .attr("class", (i) => {
          let colorIndex = 1;
          let firstElementClass = "";

          if (this.isEmptyData()) {
            return "chart__square chart__square--dummy";
          }

          for (let j = 0; j < this.classificationData.length; j++) {
            // add class to first element of classification data
            if (i === 0 || i === this.classificationData[j].endIndex) {
              firstElementClass = " chart__square--first-element";
            }

            if (
              i < this.classificationData[j].endIndex &&
              this.classificationData[j].adjustedCount > 0
            ) {
              colorIndex = (j % numberOfColors) + 1;
              break;
            }
          }

          return `chart__square chart__square--color-${colorIndex}${firstElementClass}`;
        });
    },

    renderGrid(waffleElement, waffleConfig) {
      const numbersOfLinesY = d3Array.range(waffleConfig.roundedSquaresOnYAxis);
      const numbersOfLinesX = d3Array.range(waffleConfig.squaresOnXAxis);

      waffleElement
        .select("svg")
        .append("g")
        .attr("class", "waffle__grid")
        .selectAll("line")
        .data(numbersOfLinesY)
        .enter()
        .append("line")
        .attr("x1", 0)
        .attr("x2", waffleConfig.graphWidth)
        .attr("y1", (i) => i * waffleConfig.squareLength)
        .attr("y2", (i) => i * waffleConfig.squareLength)
        .exit()
        .data(numbersOfLinesX)
        .enter()
        .append("line")
        .attr("x1", (i) => i * waffleConfig.squareLength)
        .attr("x2", (i) => i * waffleConfig.squareLength)
        .attr("y1", 0)
        .attr("y2", waffleConfig.graphHeight);
    },

    renderLegendHtml(legendElement, legendWidth) {
      let container = d3Selection.select(legendElement);
      container.select("div").remove();

      let legend = container
        .append("div")
        .attr("class", "legend")
        .attr("width", legendWidth)
        .selectAll(".legend__item")
        .data(this.labels)
        .enter()
        .append("div")
        .attr("class", "legend__item legend-item");

      legend
        .append("div")
        .attr("class", "legend-item__square")
        .append("svg")
        .attr("width", legendLineHeight)
        .attr("height", legendLineHeight)
        .append("rect")
        .attr("y", (legendLineHeight - legendSquareHeight) / 2)
        .attr("width", legendSquareHeight)
        .attr("height", legendSquareHeight)
        .attr(
          "class",
          (d, i) => `chart__square chart__square--color-${(i % numberOfColors) + 1}`,
        );
      legend
        .append("p")
        .attr("class", "legend-item__text")
        .text((label) => label);
    },

    renderLegendSvg(legendElement, legendWidth) {
      const maxCharsPerLine = 60;
      let container = d3Selection.select(legendElement);
      container.select("svg").remove();

      // ok it's only the first line that gets cut
      const multilineLabels = this.labels.map((label) =>
        this.separateText(label, maxCharsPerLine),
      );

      this.legendHeight = _.flatten(multilineLabels).length * legendLineHeight;

      const selection = container
        .append("svg")
        .attr("class", "export-legend")
        .attr("width", legendWidth)
        .attr("height", this.legendHeight)
        .append("g")
        .selectAll(".legend__item");

      selection
        .data(multilineLabels)
        .enter()
        .append("g")
        .attr("transform", (d, i) => {
          const lineFactor = this.lineHeightFactor(multilineLabels, i);
          return "translate(0," + lineFactor * legendLineHeight + ")";
        })
        .append("rect")
        .attr("class", "legend-item__square")
        .attr("y", (legendLineHeight - legendSquareHeight) / 2)
        .attr("width", legendSquareHeight)
        .attr("height", legendSquareHeight)
        .attr(
          "class",
          (d, i) => `chart__square chart__square--color-${(i % numberOfColors) + 1}`,
        );

      selection
        .data(_.flatten(multilineLabels))
        .enter()
        .append("g")
        .attr("transform", (d, i) => "translate(0," + i * legendLineHeight + ")")
        .append("text")
        .attr("x", 25)
        .attr("y", 16)
        .text((label) => label);
    },

    lineHeightFactor(multilineLabels, upperIndex) {
      return multilineLabels.reduce((lines, multiLine, lineIndex) => {
        if (lineIndex < upperIndex) {
          return (lines += multiLine.length);
        }
        return lines;
      }, 0);
    },

    renderLabels(waffleElement, component) {
      waffleElement.selectAll(".chart__square--first-element").each(function (d, i) {
        if (component.classificationData[i]) {
          component.classificationData[i].firstElementPosition = {
            x: +this.getAttribute("x"),
            y: +this.getAttribute("y"),
          };
        }
      });

      let container = waffleElement
        .select("svg")
        .append("g")
        .attr("class", "waffle__labels")
        .selectAll("rect")
        .data(this.classificationData)
        .enter()
        .append("g")
        .attr("transform", (d, i) => {
          let offset = this.labelOffset(i);
          return `translate(${offset.x}, ${offset.y})`;
        });

      // label background
      container
        .append("rect")
        .attr("height", `${labelHeight}px`)
        .attr("width", (d) => `${this.labelWidth(d)}px`)
        .attr("class", "waffle__label")
        .attr("x", () => 0)
        .attr("y", 0);

      // label text
      const textOffesetX = 5;
      const textOffesetY = 13;

      container
        .append("text")
        .attr("x", textOffesetX)
        .attr("y", textOffesetY)
        .attr("class", "chart-label")
        .text((classification) =>
          numberToStringFormat(classification.count, this.selectedLanguage),
        );
    },

    labelWidth(classification) {
      const marginSide = 7;
      return (
        2 * marginSide +
        numberToStringFormat(classification.count, this.selectedLanguage).length * 7
      );
    },

    labelOffset(index) {
      let baseOffsetX = 3;
      let baseOffsetY = 2;
      let offsetX = this.classificationData[index].firstElementPosition.x + baseOffsetX;
      let offsetY = this.classificationData[index].firstElementPosition.y + baseOffsetY;

      if (this.isLabelOutOfBoundsX(offsetX, this.classificationData[index])) {
        offsetX =
          offsetX -
          (this.labelXEndPosition(offsetX, this.classificationData[index]) -
            this.graphWidth);
      }

      if (this.isLabelOutOfBoundsY(offsetY)) {
        offsetY = offsetY - (this.labelYEndPosition(offsetY) - this.graphHeight);
      }

      return { x: offsetX, y: offsetY };
    },

    isLabelOutOfBoundsX(offsetX, classification) {
      return this.labelXEndPosition(offsetX, classification) > this.graphWidth;
    },

    isLabelOutOfBoundsY(offsetY) {
      return this.labelYEndPosition(offsetY) > this.graphHeight;
    },

    labelXEndPosition(offsetX, classification) {
      return offsetX + this.labelWidth(classification);
    },

    labelYEndPosition(offsetY) {
      return offsetY + labelHeight;
    },

    exportChart() {
      let exportDimensions = this.calculateExportDimensions();

      this.renderChartForExport(exportDimensions);
      this.renderLegendForExport(exportDimensions);
      this.renderTitle(this.title);
      // CF-574: `legendHeight` gets only calculated in `renderLegendForExport`, it depends on the number of lines
      exportDimensions.legendHeight = this.legendHeight;
      exportChart(exportDimensions, this.filename);
    },

    calculateExportDimensions() {
      return {
        squareLength: this.squareLength,
        roundedSquaresOnYAxis: this.roundedSquaresOnYAxis,
        graphHeight: this.graphHeight,
        graphWidth: this.graphWidth,
        legendWidth: this.legendWidth + 300,
        legendHeight: this.legendHeight,
        squaresOnXAxis: this.squaresOnXAxis,
      };
    },

    renderChartForExport(dimensions) {
      const waffleConfig = {
        waffleElementSelector: ".export__chart",
        graphHeight: dimensions.graphHeight,
        graphWidth: dimensions.graphWidth,
        squareLength: dimensions.squareLength,
        squaresOnXAxis: dimensions.squaresOnXAxis,
        roundedSquaresOnYAxis: dimensions.roundedSquaresOnYAxis,
        className: "export-chart",
      };

      this.renderWaffleChartWithConfig(waffleConfig);
      const waffleElement = d3Selection.select(waffleConfig.waffleElementSelector);
      this.copyAllStyles(waffleElement);
    },

    copyAllStyles(element) {
      const component = this;
      const propertiesToCopy = ["fill", "stroke", "stroke-width", "font-family"];

      element.selectAll("*").each(function () {
        propertiesToCopy.map((prop) => {
          component.copyStyles(this, prop);
        });
      });
    },

    copyStyles(element, property) {
      element.setAttribute(
        property,
        window.getComputedStyle(element).getPropertyValue(property),
      );
    },

    renderLegendForExport(dimensions) {
      const legendContainer = document.querySelector(".export__legend");
      this.renderLegendSvg(legendContainer, dimensions.legendWidth);
      this.copyAllStyles(d3Selection.select(legendContainer));
    },

    renderTitle(title) {
      const maxChars = 60;

      let fontSize = 44;
      let topMargin = 70;
      let separatedText = [];

      if (title && title.length > maxChars) {
        fontSize = 40;
        topMargin = 50;
        separatedText = this.separateText(title, maxChars);
      }

      let container = d3Selection.select(".export__title");
      container.select("svg").remove();

      let legend = container
        .append("svg")
        .attr("class", "export-title")
        .attr("width", 2000)
        .attr("height", 200);

      legend
        .append("text")
        .attr("fill", "black")
        .attr("font-size", `${fontSize}px`)
        .attr("x", 0)
        .attr("y", topMargin)
        .text(() => separatedText[0]);

      // second line
      if (separatedText.length > 1) {
        legend
          .append("text")
          .attr("fill", "black")
          .attr("font-size", `${fontSize}px`)
          .attr("x", 0)
          .attr("y", 100)
          .text(() => separatedText[1]);
      }

      this.copyAllStyles(legend);
    },

    separateText(text, maxChars) {
      let lastSpaceIndex = text.substring(maxChars - 1).indexOf(" ");
      if (lastSpaceIndex < 0) {
        return [text];
      } else {
        lastSpaceIndex += maxChars;
        return _.flatten([
          text.substring(0, lastSpaceIndex - 1),
          _.flatten(this.separateText(text.substring(lastSpaceIndex), maxChars)),
        ]);
      }
    },
  },

  mounted() {
    if (this.graphInput) {
      this.redraw();
    }

    EventBus.$on("exportGraph", this.exportChart);
  },
  beforeDestroy() {
    EventBus.$off("exportGraph", this.exportChart);
  },
};
</script>

<style lang="scss">
@import "../../assets/css/bulma_utils";
@import "../../assets/css/colors";

$mobileMaxWidth: 300px;

.chart {
  &__square {
    &--color-1 {
      fill: $snf-blue-light;
    }
    &--color-2 {
      fill: $snf-yellow;
    }
    &--color-3 {
      fill: $snf-green;
    }
    &--color-4 {
      fill: $snf-red-light;
    }
    &--color-5 {
      fill: $snf-violet;
    }
    &--color-6 {
      fill: $snf-blue;
    }
    &--color-7 {
      fill: $snf-yellow-light;
    }
    &--color-8 {
      fill: $snf-green-light;
    }
    &--color-9 {
      fill: $snf-violet-light;
    }
    &--dummy {
      fill: $snf-gray-medium;
    }
  }
}

.waffle {
  &__grid {
    stroke: $snf-gray-light;
    stroke-width: 1;
  }

  &__label {
    fill: $snf-white;
  }
}

.waffle-chart {
  padding: 0 0;

  &__chart,
  &__legend {
    max-width: 588px;
    display: inline-block;
    margin-left: 12px;
    vertical-align: top;

    &--tablet {
      max-width: 478px;
    }
  }

  &__chart {
    margin-right: 12px;

    &--mobile {
      margin-right: 0;
      margin-left: 0;
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
    }
  }
}

.export {
  display: none;
}

.chart-label {
  font-weight: 700;
}

.legend-item {
  display: flex;

  &__text {
    display: inline-block;
    margin-bottom: 0;
    line-height: 24px;
    margin-left: 5px;
  }
}

.chart {
  position: relative;
  &__dummy {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    padding-top: 15rem;

    &--is-visible {
      display: block;
    }

    &--mobile {
      width: $mobileMaxWidth;
      padding-top: 10rem;
    }
  }
}

.chart-dummy {
  &__text {
    width: 100%;
    text-align: center;
  }
}
</style>
