<template>
  <section class="section snf-section margin-top-1">
    <div class="columns snf-section__columns pandemic__columns">
      <div class="container column is-full">
        <h4 class="section-header" v-if="title">
          {{ title }}
        </h4>

        <div v-if="applications.length && showSummary" class="color-gray">
          {{ applications.length }}
          {{ cmsTranslationByKey("SearchResultNumber") }}
        </div>

        <div class="pandemic-table">
          <table class="pandemic-table">
            <tbody class="pandemic-table__body">
              <template v-for="row in applications">
                <tr :key="row.id + 'title'" class="pandemic-table__row">
                  <td
                    class="code pandemic-table__cell pandemic-cell"
                    :colspan="columnIds.length"
                  >
                    <span
                      class="cell__content cell__content-full code application-title"
                    >
                      <router-link
                        class="application-link"
                        :to="{
                          name: 'PandemicDatabaseApplication',
                          params: {
                            applicationNumber:
                              row['ApplicationNumber'] ||
                              row['ApplicationNumberString'],
                            origin: row['Funder_En'].toLowerCase(),
                          },
                        }"
                      >
                        {{ row["Title"] }}
                      </router-link>
                    </span>
                  </td>
                </tr>
                <tr :key="row.id + 'columns1'" class="pandemic-table__row">
                  <td class="code pandemic-table__cell pandemic-cell">
                    <span class="cell__content cell__content-full code">
                      <span v-if="row.ParsedApplicants">
                        <router-link
                          :key="person.personNumber"
                          v-for="person in row.ParsedApplicants"
                          class="application-link"
                          :to="{
                            name: 'PandemicDatabasePerson',
                            params: {
                              personNumber: person.personNumber,
                            },
                          }"
                        >
                          {{ person.personName }};
                        </router-link>
                      </span>
                      <span v-if="!row.ParsedApplicants">
                        {{ row.Applicants }}
                      </span>
                    </span>
                  </td>
                  <td class="code pandemic-table__cell pandemic-cell">
                    <span class="cell__content cell__content-full code color-gray">
                      {{ cmsTranslationByKey("Funder") }}:
                      {{ objectAttributeInLanguage(row, "Funder") }}
                    </span>
                  </td>
                </tr>

                <tr :key="row.id + 'columns2'" class="pandemic-table__row">
                  <td class="code pandemic-table__cell pandemic-cell">
                    <span class="cell__content cell__content-full code">
                      {{ objectAttributeInLanguage(row, "ResearchInstitution") }}
                    </span>
                  </td>
                  <td class="code pandemic-table__cell pandemic-cell">
                    <span class="cell__content cell__content-full code color-gray">
                      {{ cmsTranslationByKey("State") }}:
                      {{ objectAttributeInLanguage(row, "State") }}
                    </span>
                  </td>
                </tr>

                <tr :key="row.id + 'keywords'" class="pandemic-table__row">
                  <td
                    class="code pandemic-table__cell pandemic-cell"
                    :colspan="columnIds.length"
                  >
                    <span class="cell__content cell__content-full code color-gray">
                      {{ row["Keywords"] }}
                    </span>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as _ from "lodash";
import * as log from "loglevel";
import Vue from "vue";
import { mapGetters } from "vuex";
import { cmsTranslationMixin } from "../mixins";

export default {
  name: "ApplicationsTable",
  mixins: [cmsTranslationMixin],
  props: ["applications", "title", "showSummary"],
  components: {},
  data: function () {
    return {
      columnIds: [
        "Applicants",
        "Funder_En",
        "ResearchInstitution_En",
        "EffectiveStartDateFormatted",
      ],
    };
  },
  watch: {
    applications: function (val) {
      this.updateParsedApplicants(val);
    },
  },
  mounted: function () {
    log.debug("ApplicationsTable mounted");
    this.updateParsedApplicants(this.applications);
  },
  computed: {
    ...mapGetters(["selectedLanguage"]),
  },
  methods: {
    updateParsedApplicants: function (applications) {
      if (applications) {
        _.forEach(applications, (application) => {
          if (application.ApplicantNumbers) {
            Vue.set(
              application,
              "ParsedApplicants",
              _.map(
                _.zip(
                  _.split(application.Applicants, ";"),
                  _.split(application.ApplicantNumbers, ";"),
                ),
                (z) => {
                  return {
                    personName: _.trim(z[0]),
                    personNumber: _.trim(z[1]),
                  };
                },
              ),
            );
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
