<template>
  <div>
    {{ cmsTranslationByKey("NoDataForSelectedAttributes") }}
  </div>
</template>

<script>
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "NoDataMessage",
  mixins: [cmsTranslationMixin],
};
</script>

<style lang="scss" scoped></style>
