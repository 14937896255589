<template>
  <div class="pandemic-database" v-if="pageData.colorClass">
    <core-figures-header
      :title="pageData.pageTitle"
      :text="pageData.pageHeaderText"
      :color-class="'cf-header--' + pageData.colorClass"
    />

    <section class="section snf-section snf-section__gray padding-top-1">
      <div class="columns snf-section__columns pandemic__columns">
        <div class="container column is-full">
          <div class="search-form">
            <div class="field">
              <label class="label">
                {{ cmsTranslationByKey("SearchHeader") }}
              </label>
            </div>
            <div class="field is-grouped">
              <div class="control is-expanded">
                <input
                  class="input"
                  type="text"
                  @input="debounceSearchInput"
                  style="border-radius: 0"
                  v-model="searchInputValue"
                  :placeholder="cmsTranslationByKey('ProjektregisterSearchSuggestion')"
                />
              </div>
              <div class="control">
                <button class="snf-button">
                  {{ cmsTranslationByKey("SearchButton") }}
                </button>
              </div>
            </div>
          </div>

          <div
            v-if="facetDataFetched"
            class="field is-grouped filter-sentence-form"
            style="flex-wrap: wrap; justify-content: flex-start"
          >
            <div
              class="control cf-control"
              v-for="(part, index) in parseSentenceResult.sentenceParts"
              :key="part + index"
              :style="{
                'margin-bottom': '0.5em',
                'margin-right': part[0] === '*' ? '0.75rem' : 0,
              }"
            >
              <SolrSelect
                v-if="part[0] === '*'"
                :parsed-entry="parseSentenceResult.parsedEntries[part]"
                @solrSelectValueChanged="solrSelectValueChanged"
              />

              <input
                v-if="part[0] !== '*'"
                class="input cf-input"
                type="text"
                tabindex="-1"
                :style="{
                  border: 'none',
                  width: getTextWidth(part) + 20 + 'px',
                  background: '#f0f0f0',
                }"
                :value="part"
                :aria-label="part"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <ApplicationsTable :applications="parsedSolrResponse" :show-summary="true" />

    <section v-if="$route.query.debug" class="section snf-section column-download">
      <div v-if="solrQuery" style="margin-top: 3em">
        <div style="margin-top: 2em">
          <a :href="solrQuery" target="_blank">{{ solrQuery }}</a>
        </div>
        <pre style="margin-top: 2em" />
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import * as _ from "lodash";
import * as log from "loglevel";
import SolrSelect from "../components/keyFigures/SolrSelect.vue";
import {
  buildEntityQuery,
  buildQuery,
  createSelectedPartsInitial,
  querySentences,
} from "../solrHelpers";
import CoreFiguresHeader from "../components/keyFigures/CoreFiguresHeader.vue";
import { mapGetters } from "vuex";
import {
  getTextWidth,
  pandemicApplicationEntitySubquery,
  updateUrlQuery,
} from "../utils";
import { parseSentence } from "../filterSentenceParser";
import { cmsTranslationMixin } from "../mixins";
import ApplicationsTable from "../components/ApplicationsTable";

const langFilterSentenceKey = {
  fr: "French",
  en: "English",
  de: "German",
};

export default {
  name: "PandemicDatabase",
  mixins: [cmsTranslationMixin],
  components: { ApplicationsTable, SolrSelect, CoreFiguresHeader },
  metaInfo: function () {
    if (this.selectedLanguage && this.cmsData) {
      return {
        title: this.cmsTranslationByKey("TitleCovid"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleCovid"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("ProjektregisterIntroShort"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_covid19.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      entity: "pandemic",
      pageData: {},
      showNoGraphicIE11Modal: false,
      parseSentenceResult: {},
      originalParseSentenceResult: {},
      filterFacets: [],
      numDocsPerPage: 50,
      selectedParts: {},
      solrQuery: "",
      parsedSolrResponse: [],
      solrSentences: [],
      searchInput: "",
      searchInputValue: "",
      facetDataFetched: false,
    };
  },
  mounted: function () {
    if (this.$route.query.q) {
      this.searchInput = this.$route.query.q;
      this.searchInputValue = this.$route.query.q;
    }
    this.loadPageData();
  },
  computed: {
    ...mapGetters(["selectedLanguage", "cmsData", "cmsPageDataArray", "screenSize"]),
  },
  watch: {
    screenSize: function (val) {
      log.debug("watch screenSize", val);
      this.refetchSolrData();
    },
    $route(to, from) {
      log.debug("$route", to, from);
      this.searchInput = to.query.q;
      this.searchInputValue = to.query.q;
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  directives: { SolrSelect },
  methods: {
    closeIE11WarningModal: function () {
      this.showNoGraphicIE11Modal = false;
    },
    getTextWidth: function (text) {
      return getTextWidth(text, "400 16px Source Sans Pro");
    },
    debounceSearchInput: _.debounce(function () {
      this.searchInput = this.searchInputValue;
      let queryParams = _.cloneDeep(this.$route.query);
      if (this.searchInput) {
        queryParams["q"] = this.searchInput;
      } else {
        delete queryParams["q"];
      }
      this.$router.push({ query: queryParams }).catch(() => {});
    }, 500),
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.pageData = _.find(this.cmsPageDataArray, {
          entityUrl: this.entity,
        });
        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      querySentences(this.pageData.sentenceEntity, "pandemic").then((solrSentences) => {
        this.solrSentences = solrSentences;
        let filterSentence =
          this.solrSentences[0][langFilterSentenceKey[this.selectedLanguage]];

        this.parseSentenceResult = {};
        this.originalParseSentenceResult = {};
        this.facetDataFetched = false;

        _.delay(() => {
          const parseSentenceResult = parseSentence(
            filterSentence,
            false,
            this.pageData.solrAttributeYear,
          );
          this.originalParseSentenceResult = parseSentenceResult;
          this.parseSentenceResult = _.cloneDeep(this.originalParseSentenceResult);

          this.filterFacets = _.compact(
            _.map(this.originalParseSentenceResult.parsedEntries, (entry) => {
              return entry.solrFacet;
            }),
          );

          this.fetchFacetData().then(() => {
            _.delay(() => {
              _.forEach(this.parseSentenceResult.parsedEntries, (e) => {
                const routeIndexKey = e.id.replace("*", "s");
                e.selectedIndex = this.$route.query[routeIndexKey] || 0;
                if (e.selectedIndex >= e.selectionValues.length) {
                  e.selectedIndex = 0;
                }
              });
              this.selectedParts = createSelectedPartsInitial(
                this.parseSentenceResult.parsedEntries,
              );
              log.debug(JSON.parse(JSON.stringify(this.selectedParts)));
              log.debug(JSON.parse(JSON.stringify(this.parseSentenceResult)));
              this.facetDataFetched = true;
              this.refetchSolrData();
            }, 0);
          });
        }, 0);
      });
    },
    solrSelectValueChanged: function (newValue) {
      this.selectedParts[newValue.id] = newValue;
      updateUrlQuery(this.$router, this.$route.query, newValue);
      this.refetchSolrData();
    },
    fetchFacetData: function () {
      let solrQuery = buildEntityQuery(null, 0, "pandemic", "pandemic");
      solrQuery += "&facet=on&facet.sort=count";

      this.filterFacets.forEach((facetField) => {
        solrQuery += "&facet.field=" + facetField;
      });

      return axios.get(solrQuery).then((response) => {
        if (this.filterFacets.length > 0) {
          const facetResponse = response.data.facet_counts.facet_fields;

          _.forEach(this.filterFacets, (facetField) => {
            const parsedEntry = _.find(this.parseSentenceResult.parsedEntries, {
              solrFacet: facetField,
            });

            const filterFacetValues = _.map(
              _.chunk(facetResponse[facetField], 2),
              (facetValues) => {
                return {
                  id: parsedEntry.id,
                  text: _.truncate(facetValues[0], { length: 40 }),
                  solrAttribute: facetField,
                  solrValue: facetValues[0],
                };
              },
            );

            parsedEntry.selectionValues = [parsedEntry.selectionValues[0]].concat(
              filterFacetValues,
            );

            _.forEach(parsedEntry.selectionValues, (sv, index) => {
              sv.index = index;
            });
          });
        }
      });
    },
    refetchSolrData: function () {
      this.solrQuery = buildQuery(
        `(${pandemicApplicationEntitySubquery})`,
        this.selectedParts,
        [],
        1000,
        "pandemic",
        "pandemic",
      );

      if (this.searchInput) {
        _.forEach(_.split(this.searchInput, " "), (term) => {
          if (term.length >= 1) {
            this.solrQuery += `+AND+TextSearch:*${term.toLowerCase()}*`;
          }
        });
      }

      this.solrQuery += "&sort=EffectiveStartDate%20desc";

      if (this.filterFacets.length > 0) {
        this.solrQuery += "&facet=on";

        this.filterFacets.forEach((facetField) => {
          this.solrQuery += "&facet.field=" + facetField;
        });
      }

      axios.get(this.solrQuery).then((response) => {
        this.parsedSolrResponse = response.data.response.docs;
      });
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.download-button__div {
  margin-top: 1.5em;
}

.modal-close-button {
  text-transform: none;
  padding: 0.75rem;
  border: 1px solid gray;
  color: white;
  background-color: transparent;
  cursor: pointer;
  margin-right: 1em;
}

.column-download {
  @include mobile() {
    display: none !important;
  }
}

.cf-input {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.cf-control {
  @include mobile() {
    font-size: 0.9rem !important;
  }

  .input {
    @include mobile() {
      font-size: 0.9rem !important;
    }
  }
}

.pandemic__columns {
  @include tablet {
    max-width: 1000px !important;
  }
}

.search-form {
  margin-bottom: 1em;
}

@include desktop() {
  .search-form {
    margin-top: 2em;
  }

  .filter-sentence-form {
    margin-top: 2em;
    margin-bottom: 2em;
  }
}

// table stuff

$padding: 5px;

.pandemic-table {
  width: 100%;

  @include mobile() {
    display: block;
  }

  &__body {
    background-color: $snf-white;

    @include mobile() {
      display: block;
    }
  }

  &__row {
    border: none;

    &:nth-child(4n + 1) {
      td {
        padding-top: 4 * $padding;
      }
    }

    &:nth-child(4n) {
      border-bottom: 1px solid $snf-gray-medium;

      td {
        padding-bottom: 4 * $padding;
      }
    }

    &:last-of-type {
      border: none;
    }

    @include mobile() {
      display: block;
    }
  }

  &__cell {
    padding: $padding;
    padding-left: 0 !important;

    &:last-of-type {
      text-align: left;

      @include desktop() {
        width: 30%;
      }
    }
  }
}

.application-title {
  font-weight: 700;
  font-size: 1.1em;
}

.application-link {
  color: $snf-blue-dark;

  &:hover {
    color: $snf-blue-dark;
    text-decoration: underline;
  }
}

.color-gray {
  color: $snf-gray-dark;
}

.pandemic-cell {
  border: none;

  @include mobile() {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $snf-gray-light;
    text-align: left;
  }

  &__content {
    @include mobile() {
      display: block;
      flex: 0 0 20%;
      text-align: left;

      &-full {
        flex: 0 0 100%;
      }
    }
  }
}

/* make IE11 great again https://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11 */

_:-ms-fullscreen,
.pandemic-cell {
  @include mobile() {
    display: block;
    border-bottom: 1px solid $snf-gray-light;
  }

  &__heading {
    display: none;

    @include mobile() {
      display: block;
      flex: 0 0 35%;
      text-align: left;
      padding-right: $padding;
    }
  }

  &__content {
    @include mobile() {
      display: block;
      flex: 0 0 65%;
      text-align: left;
    }
  }
}
</style>
