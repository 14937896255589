<template>
  <div>
    <div>
      <button
        class="button"
        :class="{ 'is-primary': !showTotal }"
        @click="showTotal = false"
      >
        Total
      </button>
      <button
        class="button"
        :class="{ 'is-primary': showTotal }"
        @click="showTotal = true"
      >
        Percent
      </button>
    </div>
    <div class="success-chart-section">
      <div ref="successChart" id="success-chart" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, computed, onUnmounted, watch } from "vue";
// import * as am4core from "@amcharts/amcharts4/core";
// import * as window.am4charts from "@amcharts/amcharts4/charts";
import * as _ from "lodash";
const successChart = ref();
const graph = ref(null);
const showTotal = ref(false);

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  property: {
    type: String,
    default: "Funding",
  },
  showTotal: {
    type: Boolean,
    default: true,
  },
});

const propertyKey = computed(() => {
  switch (props.property) {
    case "Grants":
    case "Grants_Submitted":
      return "Grants";
    case "Funding":
    case "Funding_submitted":
    default:
      return "Funding";
  }
});
// Grants_Submitted := total
// Grants := approved
// Grants_Submitted - Grants := denied
const filteredData = computed(() => {
  let seriesElements = _.uniq(props.data.map((i) => i.label));
  let initialReduceValue = {};
  seriesElements.forEach((label) => {
    initialReduceValue[label] = { approved: 0, denied: 0 };
  });

  return props.data.reduce((acc, current) => {
    return {
      ...acc,
      [current.label]: {
        approved: acc[current.label].approved + Number(current[propertyKey.value]),
        denied:
          acc[current.label].denied +
          Number(current[`${propertyKey.value}_Submitted`]) -
          Number(current[propertyKey.value]),
      },
    };
  }, initialReduceValue);
});

const addSeries = (chart, category, showTotal) => {
  const series = chart.series.push(new window.am4charts.ColumnSeries());
  series.columns.template.width = window.am4core.percent(80);
  series.columns.template.tooltipText =
    "{name}: {valueY.totalPercent.formatNumber('#.00')}%";
  series.name = category;
  series.dataFields.categoryX = "category";
  series.dataFields.valueY = category;
  series.dataItems.template.locations.categoryX = 0.5;
  series.stacked = true;
  series.tooltip.pointerOrientation = "vertical";

  const bullet = series.bullets.push(new window.am4charts.LabelBullet());
  bullet.interactionsEnabled = false;
  bullet.label.text = "{valueY}";
  bullet.label.fill = window.am4core.color("#ffffff");
  bullet.locationY = 0.5;
  if (showTotal) {
    series.dataFields.valueYShow = "totalPercent";
    bullet.label.text = "{valueY.totalPercent.formatNumber('#.00')}%";
    series.columns.template.tooltipText = "{name}: {valueY}";
  }
};

const createChart = (data, showTotal) => {
  const arr = Object.keys(data).map((key) => ({ category: key, ...data[key] }));
  const dataWithZeroes = arr.map((i) => ({ ...i, none: 0 }));

  const chart = window.am4core.create(successChart.value, window.am4charts.XYChart);
  chart.data = dataWithZeroes;

  chart.legend = new window.am4charts.Legend();

  const categoryAxis = chart.xAxes.push(new window.am4charts.CategoryAxis());
  categoryAxis.dataFields.category = "category";
  categoryAxis.renderer.grid.template.location = 0;
  const label = categoryAxis.renderer.labels.template;
  label.truncate = true;
  label.maxWidth = 120;
  // categoryAxis.renderer.labels.template.rotation = 90;
  // categoryAxis.renderer.labels.template.verticalCenter = "middle";
  // categoryAxis.renderer.labels.template.horizontalCenter = "left";

  const valueAxis = chart.yAxes.push(new window.am4charts.ValueAxis());
  valueAxis.title.text = props.property;
  valueAxis.min = 0;
  valueAxis.calculateTotals = true;

  if (showTotal) {
    valueAxis.max = 100;
    valueAxis.strictMinMax = true;
    valueAxis.renderer.minWidth = 50;
  }

  addSeries(chart, "denied", showTotal);
  addSeries(chart, "approved", showTotal);

  // chart.scrollbarX = new am4core.Scrollbar();
  if (!showTotal) {
    chart.paddingTop = 35;
    chart.maskBullets = false;

    const totalSeries = chart.series.push(new window.am4charts.ColumnSeries());
    totalSeries.dataFields.valueY = "none";
    totalSeries.dataFields.categoryX = "category";
    totalSeries.stacked = true;
    totalSeries.hiddenInLegend = true;
    totalSeries.columns.template.strokeOpacity = 0;

    let totalBullet = totalSeries.bullets.push(new window.am4charts.LabelBullet());
    // totalBullet.dy = -20;
    totalBullet.label.text = "{valueY.total}";
    totalBullet.label.hideOversized = false;
    // totalBullet.label.fontSize = 16;
    // totalBullet.label.background.fill = totalSeries.stroke;
    totalBullet.label.background.fillOpacity = 0.2;
    totalBullet.label.padding(5, 10, 5, 10);
    totalBullet.adapter.add("y", () => {
      return -15;
    });
  }
};

onMounted(() => {
  if (graph.value !== null) {
    graph.value.dispose();
  }
  graph.value = createChart(filteredData.value, showTotal.value);
});
onUnmounted(() => {
  graph.value?.dispose();
});
watch(
  () => ({ ...props }),
  () => {
    graph.value?.dispose();
    graph.value = createChart(filteredData.value, showTotal.value);
  },
);
watch(
  () => showTotal.value,
  () => {
    graph.value?.dispose();
    graph.value = createChart(filteredData.value, showTotal.value);
  },
);
</script>

<style lang="scss">
@import "../../assets/css/colors";
@import "../../assets/css/mixins";
@import "../../assets/css/bulma_utils";
@import "../../../node_modules/bulma/sass/elements/button";

.chart {
  width: 800px;
  height: 600px;
}

.success-chart-section {
  margin-left: 25px;
  margin-right: 25px;
  width: calc(100vw - 50px);
  max-width: 1280px;
  position: relative;
}

#success-chart {
  width: 100%;
  height: calc(100vh - 500px);
  min-height: 500px;
  max-height: 800px;
}
</style>
