<template>
  <nav class="snf-section header-section">
    <div
      class="navbar snf-section__columns columns is-mobile"
      style="max-width: 1426px"
    >
      <div class="navbar-navigation column">
        <div class="nav-snf-logo">
          <router-link to="/">
            <img
              class="navbar-logo"
              :src="'/img/logo_snf_white_' + selectedLanguage + '.svg'"
              alt="Swiss National Science Foundation"
            />
          </router-link>
        </div>
        <div
          class="nav-logo"
          :class="{ 'nav-logo-mobile-fr': selectedLanguage === 'fr' }"
        >
          <router-link
            :to="{ name: 'Home' }"
            class="main-navigation main-link header-link"
            :class="{
              'main-link--selected': routePath === '/',
            }"
          >
            {{ getSnfDataPortalTitle() }}
          </router-link>
        </div>

        <div class="navbar-navigation__toggle">
          <a
            role="button"
            class="navbar-burger burger"
            aria-label="menu"
            :aria-expanded="mobileMenuShown.toString()"
            :class="{ 'is-active': mobileMenuShown }"
            @click="toggleMobileNav"
          >
            <span aria-hidden="true" />
            <span aria-hidden="true" />
            <span aria-hidden="true" />
          </a>
        </div>

        <nav
          class="navbar-navigation__navigation nav-menus"
          v-show="cmsPageDataArray.length"
        >
          <ol class="main-navigation">
            <li class="main-navigation__item main-navigation">
              <dropdown
                class="header-link"
                @key-up-down="handleKeyEvent"
                role="menuitem"
                :id="'cf-submenu'"
                :button-text="cmsTranslationByKey('NavigationMainEntryCF')"
                :selected="routePath.startsWith('/key-figures')"
              >
                <router-link
                  class="dropdown-item"
                  :to="'/key-figures'"
                  :data-index="1"
                  data-menu="cf-submenu"
                >
                  {{ cmsTranslationByKey("NavigationMainEntryCF") }}
                </router-link>
                <router-link
                  v-for="(subPage, index) in cmsPageDataArray.filter(
                    (p) => p.pageType === 'CoreFigures',
                  )"
                  tabindex="-1"
                  data-menu="cf-submenu"
                  :data-index="index + 2"
                  :key="subPage.cmsId"
                  :to="subPage.url"
                  class="dropdown-item"
                >
                  {{ subPage.pageTitle }}
                </router-link>
              </dropdown>
            </li>

            <li class="main-navigation__item">
              <dropdown
                class="header-link"
                @key-up-down="handleKeyEvent"
                role="menuitem"
                :id="'stories-submenu'"
                :button-text="cmsTranslationByKey('NavigationMainEntryDataStories')"
                :selected="routePath.startsWith('/stories')"
              >
                <router-link
                  class="dropdown-item"
                  :to="{ name: 'DataStoryIndex' }"
                  :data-index="1"
                  data-menu="stories-submenu"
                >
                  {{ cmsTranslationByKey("NavigationMainEntryDataStories") }}
                </router-link>
                <router-link
                  class="dropdown-item"
                  to="/stories/documentation"
                  data-menu="stories-submenu"
                  :data-index="2"
                >
                  {{ cmsTranslationByKey("DataStoriesDocumentation") }}
                </router-link>
              </dropdown>
            </li>

            <li v-if="enableGrantSearch" class="main-navigation__item">
              <dropdown
                class="header-link"
                @key-up-down="handleKeyEvent"
                role="menuitem"
                :id="'grantregistry-submenu'"
                :button-text="cmsTranslationByKey('GrantRegistry')"
                :selected="routePath.startsWith('/grants')"
              >
                <router-link
                  class="dropdown-item"
                  to="/grants"
                  :data-index="1"
                  data-menu="grantregistry-submenu"
                >
                  {{ cmsTranslationByKey("GrantRegistry") }}
                </router-link>
                <router-link
                  class="dropdown-item"
                  to="/grants/documentation"
                  data-menu="pandemic-submenu"
                  :data-index="2"
                >
                  {{ cmsTranslationByKey("GrantRegistryDocumentation") }}
                </router-link>
              </dropdown>
            </li>

            <li class="main-navigation__item" v-if="enableGrantSearch">
              <router-link
                class="header-link"
                :class="{
                  'main-navigation__item__link--selected':
                    routePath.startsWith('/datasets'),
                }"
                to="/datasets"
              >
                {{ cmsTranslationByKey("Datasets") }}
              </router-link>
            </li>

            <li class="main-navigation__item">
              <dropdown
                class="header-link"
                @key-up-down="handleKeyEvent"
                role="menuitem"
                :id="'about-submenu'"
                :button-text="cmsTranslationByKey('About')"
                :selected="routePath.startsWith('/about')"
              >
                <router-link
                  class="dropdown-item"
                  to="/about/dataportal"
                  :data-index="1"
                  data-menu="about-submenu"
                >
                  {{ cmsTranslationByKey("AboutDataPortal") }}
                </router-link>
                <router-link
                  class="dropdown-item"
                  to="/about/open-data-policy"
                  data-menu="pandemic-submenu"
                  :data-index="2"
                >
                  {{ cmsTranslationByKey("OpenDataPolicy") }}
                </router-link>
                <router-link
                  class="dropdown-item"
                  to="/about/glossary"
                  data-menu="about-submenu"
                  :data-index="3"
                >
                  {{ cmsTranslationByKey("Glossary") }}
                </router-link>
              </dropdown>
            </li>
          </ol>
        </nav>

        <nav class="nav-languages">
          <ol class="navbar-navigation__language main-navigation">
            <li class="main-navigation__item">
              <a
                aria-label="Deutsch"
                @click="setSelectedLanguage('de')"
                class="header-link"
                :class="{
                  'main-navigation__item__link--selected': selectedLanguage === 'de',
                }"
              >
                DE
              </a>
            </li>

            <li class="main-navigation__item">
              <a
                aria-label="Français"
                @click="setSelectedLanguage('fr')"
                class="header-link"
                :class="{
                  'main-navigation__item__link--selected': selectedLanguage === 'fr',
                }"
              >
                FR
              </a>
            </li>

            <li class="main-navigation__item">
              <a
                aria-label="English"
                @click="setSelectedLanguage('en')"
                class="header-link"
                :class="{
                  'main-navigation__item__link--selected': selectedLanguage === 'en',
                }"
              >
                EN
              </a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div
      class="mobile-navigation"
      :class="{ 'mobile-navigation--is-shown': mobileMenuShown }"
    >
      <div class="mobile-navigation-entries">
        <nav>
          <div @click="mobileMenuKeyFiguresToggle()" class="mobile-nav__title">
            {{ cmsTranslationByKey("NavigationMainEntryCF") }}
            <span v-show="mobileMenuKeyFiguresShown" style="float: right">
              <img src="/img/arrow-up.svg" />
            </span>
            <span v-show="!mobileMenuKeyFiguresShown" style="float: right">
              <img src="/img/arrow-down.svg" />
            </span>
          </div>

          <ul
            v-show="mobileMenuKeyFiguresShown"
            class="mobile-nav-list"
            @click="toggleMobileNav()"
          >
            <li class="mobile-nav-list__item">
              <router-link :to="'/key-figures'">
                {{ cmsTranslationByKey("NavigationMainEntryCF") }}
              </router-link>
            </li>
            <li
              v-for="subPage in cmsPageDataArray.filter(
                (p) => p.pageType === 'CoreFigures',
              )"
              :key="subPage.cmsId"
              class="mobile-nav-list__item"
            >
              <router-link :key="subPage.title" :to="subPage.url">
                {{ subPage.pageTitle }}
              </router-link>
            </li>
          </ul>
        </nav>

        <nav class="margin-top-2">
          <div @click="mobileMenuStoriesToggle()" class="mobile-nav__title">
            {{ cmsTranslationByKey("NavigationMainEntryDataStories") }}
            <span v-show="mobileMenuStoriesShown" style="float: right">
              <img src="/img/arrow-up.svg" />
            </span>
            <span v-show="!mobileMenuStoriesShown" style="float: right">
              <img src="/img/arrow-down.svg" />
            </span>
          </div>

          <ul
            v-show="mobileMenuStoriesShown"
            class="mobile-nav-list"
            @click="toggleMobileNav()"
          >
            <li class="mobile-nav-list__item">
              <router-link to="/stories">
                {{ cmsTranslationByKey("NavigationMainEntryDataStories") }}
              </router-link>
            </li>
            <li class="mobile-nav-list__item">
              <router-link to="/stories/documentation">
                {{ cmsTranslationByKey("DataStoriesDocumentation") }}
              </router-link>
            </li>
          </ul>
        </nav>

        <nav class="margin-top-2" v-if="enableGrantSearch">
          <div @click="mobileMenuGrantRegistryToggle()" class="mobile-nav__title">
            {{ cmsTranslationByKey("GrantRegistry") }}
            <span v-show="mobileMenuGrantRegistryShown" style="float: right">
              <img src="/img/arrow-up.svg" />
            </span>
            <span v-show="!mobileMenuGrantRegistryShown" style="float: right">
              <img src="/img/arrow-down.svg" />
            </span>
          </div>

          <ul
            v-show="mobileMenuGrantRegistryShown"
            class="mobile-nav-list"
            @click="toggleMobileNav()"
          >
            <li class="mobile-nav-list__item">
              <router-link to="/grants">
                {{ cmsTranslationByKey("GrantRegistry") }}
              </router-link>
            </li>
            <li class="mobile-nav-list__item">
              <router-link to="/grants/documentation">
                {{ cmsTranslationByKey("GrantRegistryDocumentation") }}
              </router-link>
            </li>
          </ul>
        </nav>

        <nav class="margin-top-2" v-if="enableGrantSearch">
          <div class="mobile-nav__title" @click="mobileOpenRoute('Datasets')">
            {{ cmsTranslationByKey("Datasets") }}
          </div>
        </nav>

        <nav class="margin-top-2">
          <div @click="mobileMenuAboutToggle()" class="mobile-nav__title">
            {{ cmsTranslationByKey("About") }}
            <span v-show="mobileMenuAboutShown" style="float: right">
              <img src="/img/arrow-up.svg" />
            </span>
            <span v-show="!mobileMenuAboutShown" style="float: right">
              <img src="/img/arrow-down.svg" />
            </span>
          </div>

          <ul
            v-show="mobileMenuAboutShown"
            class="mobile-nav-list"
            @click="toggleMobileNav()"
          >
            <li class="mobile-nav-list__item">
              <router-link to="/about/dataportal">
                {{ cmsTranslationByKey("AboutDataPortal") }}
              </router-link>
            </li>
            <li class="mobile-nav-list__item">
              <router-link to="/about/open-data-policy">
                {{ cmsTranslationByKey("OpenDataPolicy") }}
              </router-link>
            </li>
            <li class="mobile-nav-list__item">
              <router-link to="/about/glossary">
                {{ cmsTranslationByKey("Glossary") }}
              </router-link>
            </li>
          </ul>
        </nav>
      </div>

      <nav class="mobile-nav-lang">
        <ul class="lang-selection" @click="toggleMobileNav()">
          <li class="lang-item">
            <a
              aria-label="Deutsch"
              @click="setSelectedLanguage('de')"
              :class="{
                'lang-item--selected': selectedLanguage === 'de',
              }"
            >
              DE
            </a>
          </li>
          <li class="lang-item">
            <a
              aria-label="Français"
              @click="setSelectedLanguage('fr')"
              :class="{
                'lang-item--selected': selectedLanguage === 'fr',
              }"
            >
              FR
            </a>
          </li>
          <li class="lang-item">
            <a
              aria-label="English"
              @click="setSelectedLanguage('en')"
              :class="{
                'lang-item--selected': selectedLanguage === 'en',
              }"
            >
              EN
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </nav>
</template>

<script>
import Dropdown from "./HeaderDropdown.vue";
import { mapActions, mapGetters } from "vuex";
import * as log from "loglevel";
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "Header",
  mixins: [cmsTranslationMixin],
  props: {},
  components: { Dropdown },
  data: function () {
    return {
      showCoreFiguresSubmenu: false,
      mobileMenuKeyFiguresShown: false,
      mobileMenuAboutShown: false,
      mobileMenuGrantRegistryShown: false,
      mobileMenuStoriesShown: false,
    };
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "cmsData",
      "screenSize",
      "mobileMenuShown",
      "cmsPageDataArray",
      "cmsPageDataObject",
      "enableGrantSearch",
    ]),
    routePath: function () {
      return this.$route.path;
    },
  },
  watch: {
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  mounted() {
    this.loadPageData();
  },
  methods: {
    ...mapActions(["setSelectedLanguage", "showMobileMenu", "hideMobileMenu"]),
    toggleMobileNav() {
      if (this.mobileMenuShown) {
        this.mobileMenuAboutShown = false;
        this.mobileMenuStoriesShown = false;
        this.mobileMenuKeyFiguresShown = false;
        this.mobileMenuGrantRegistryShown = false;
        this.hideMobileMenu();
      } else {
        if (this.$route.path.startsWith("/about")) {
          this.mobileMenuAboutShown = true;
          this.mobileMenuStoriesShown = false;
          this.mobileMenuGrantRegistryShown = false;
          this.mobileMenuKeyFiguresShown = false;
        } else if (this.$route.path.startsWith("/stories")) {
          this.mobileMenuAboutShown = false;
          this.mobileMenuStoriesShown = true;
          this.mobileMenuGrantRegistryShown = false;
          this.mobileMenuKeyFiguresShown = false;
        } else if (this.$route.path.startsWith("/grants")) {
          this.mobileMenuAboutShown = false;
          this.mobileMenuStoriesShown = false;
          this.mobileMenuGrantRegistryShown = true;
          this.mobileMenuKeyFiguresShown = false;
        } else if (this.$route.path.startsWith("/key-figures")) {
          this.mobileMenuAboutShown = false;
          this.mobileMenuStoriesShown = false;
          this.mobileMenuGrantRegistryShown = false;
          this.mobileMenuKeyFiguresShown = true;
        } else {
          this.mobileMenuAboutShown = false;
          this.mobileMenuStoriesShown = false;
          this.mobileMenuGrantRegistryShown = false;
          this.mobileMenuKeyFiguresShown = false;
        }
        this.showMobileMenu();
      }
    },
    mobileMenuKeyFiguresToggle() {
      this.mobileMenuKeyFiguresShown = !this.mobileMenuKeyFiguresShown;
    },
    mobileMenuStoriesToggle() {
      this.mobileMenuStoriesShown = !this.mobileMenuStoriesShown;
    },
    mobileMenuAboutToggle() {
      this.mobileMenuAboutShown = !this.mobileMenuAboutShown;
    },
    mobileMenuGrantRegistryToggle() {
      this.mobileMenuGrantRegistryShown = !this.mobileMenuGrantRegistryShown;
    },
    mobileOpenRoute(routeName) {
      this.hideMobileMenu();
      if (this.$route.name !== routeName) {
        this.$router.push({
          name: routeName,
        });
      }
    },
    handleKeyEvent(showItems, value) {
      const menuId = document.activeElement.dataset["menu"];
      const menuitemIndex = parseInt(document.activeElement.dataset.index, 10);
      const menuElements = document.querySelectorAll(`[data-menu=${menuId}]`);
      if (menuitemIndex + value > 1) {
        if (menuElements[menuitemIndex + value]) {
          menuElements[menuitemIndex + value].focus();
        }
      } else {
        menuElements[1].focus();
      }
    },
    loadPageData() {
      log.debug("Header loadPageData");
    },
  },
};
</script>

<style lang="scss">
@import "../assets/css/_bulma_utils";
@import "../assets/css/_colors";

$line-height: 50px;

.header-link {
  color: $snf-blue-dark;

  &:hover {
    color: $snf-red-light;
  }

  div button {
    color: $snf-blue-dark;

    &:hover {
      color: $snf-red-light;
    }
  }
}

.header-section {
  background: white;
  color: $snf-blue-dark;
  min-height: 76px;
}

.navbar {
  height: $line-height;
  line-height: $line-height;
  //font-weight: 700;
}

.navbar-logo {
  height: 40px;
}

.navbar-navigation {
  background: white;
  display: flex !important;
  flex-direction: row;
  justify-content: space-between;

  &__toggle {
    @include desktop() {
      display: none;
      justify-content: flex-start;
    }
  }

  &__navigation,
  &__language {
    display: none;
    @include desktop() {
      display: block;
    }
  }

  &__navigation {
    margin-left: unquote("max(16px, 2vw)");
  }

  &__language {
    // go to right side
    margin-left: auto;

    .main-navigation {
      &__item {
        margin-right: 10px;
      }
    }
  }
}

.nav-logo {
  flex: 1 1 auto;
  text-align: left;
  font-weight: 700;
}

@include mobile() {
  .nav-logo-mobile-fr {
    margin-left: -28px;
  }
}

.nav-menus {
}

.nav-languages {
  display: flex;
  flex: 0 0 0;
}

@include tablet() {
  .nav-logo {
    margin-top: -1px;
    font-size: 1.5rem;
    text-align: right;
  }

  .nav-languages {
    flex: 0 0 100px;
  }
}

.mobile-navigation {
  display: none;
  background-color: white;

  &--is-shown {
    display: block;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    min-height: 100vh;
    z-index: 50;
    @include desktop() {
      display: none;
    }
  }
}

.mobile-navigation-entries {
  // Fallback for browsers that do not support Custom Properties
  height: calc(100vh - 194px);
  // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
  height: calc(var(--vh, 1vh) * 100 - 134px);
  overflow-x: scroll;
}

.main-navigation {
  list-style: none;
  padding: 0;

  &__item {
    display: inline-block;
    margin-right: 24px;

    &__link--selected {
      border-top: 1px solid;
      border-color: $snf-red-light;
      padding-top: 4px;
    }
  }
}

.main-link {
  &--selected {
    //border-top: 2px solid;
    //border-color: $snf-red-light;
    padding-top: 6px;
  }
}

@media (min-width: 1024px) and (max-width: 1380px) {
  .header-section {
    min-height: 146px;
  }

  .navbar-navigation {
    flex-wrap: wrap;
  }

  .nav-logo {
    flex: 1 0 164px;
  }

  .nav-languages {
    flex: 1 0 300px;
  }

  .nav-menus {
    order: 10;
    margin-left: 0 !important;
    flex: 0 0 960px;
  }
}

@include mobile() {
  .nav-logo {
    margin-top: 4px;
    text-align: right;
  }
  .main-link {
    margin-left: 12px;
  }
}

.mobile-nav {
  &__title {
    color: $snf-black;
    line-height: 48px;
    font-size: 1.2rem;
    margin-left: 1em;
    margin-right: 2em;
    border-bottom: 1px solid $snf-gray-medium;
  }

  &-list {
    &__item {
      line-height: 42px;
      margin-left: 2em;
      margin-right: 2em;
      border-bottom: 1px solid $snf-gray-medium;
    }
  }
}

.mobile-nav-lang {
  width: 100%;
  position: absolute;
  background: $snf-blue-dark;
  padding: 20px 30px;

  .lang-selection {
    display: flex;
    flex-direction: row;
  }

  .lang-item {
    padding-right: 3em;

    a {
      color: white;

      &:hover {
        color: $snf-red-light;
      }
    }

    &--selected {
      border-top: 1px solid;
      border-color: $snf-red-light;
      padding-top: 4px;
    }
  }
}
</style>
