import * as _ from "lodash";

export function getAllMatches(source, regex) {
  // code from https://github.com/lodash/lodash/issues/2459#issuecomment-230255219
  // replacement for `matchAll`
  const matches = [];
  source.replace(regex, function () {
    matches.push({
      match: arguments[0],
      offset: arguments[arguments.length - 2],
      groups: Array.prototype.slice.call(arguments, 1, -2),
    });
    return arguments[0];
  });
  return matches;
}

export function parseSentence(
  filterSentence,
  levelAttribute = false,
  solrAttributeYear = "CallDecisionYear",
) {
  const re = /(\*\d)\s*(\[.*?])/g;
  const results = getAllMatches(filterSentence, re);

  let parsedSentence = filterSentence;
  let parsedEntries = {};
  results.forEach((result) => {
    parsedSentence = parsedSentence.replace(result.groups[1], "");
    parsedEntries[result.groups[0]] = parseEntryPattern(
      result.match,
      solrAttributeYear,
    );
  });

  let result = {
    parsedSentence: parsedSentence,
    parsedEntries: parsedEntries,
    sentenceParts: parseParts(parsedSentence),
  };

  if (levelAttribute) {
    if (_.isString(levelAttribute)) {
      result.levelInfo = {
        fieldIndex: null,
        level1Field: "",
        level2Field: levelAttribute,
      };
    } else {
      const levelFieldObject = _.find(parsedEntries, (e) => {
        return (
          e.selectionValues[0].solrAttribute !== e.selectionValues[1].solrAttribute
        );
      });
      result.levelInfo = {
        fieldIndex: levelFieldObject.id,
        level1Field: levelFieldObject.selectionValues[1].solrAttribute.replace(
          /(Id$|Code$)/,
          "",
        ),
        level2Field: levelFieldObject.selectionValues[0].solrAttribute.replace(
          /(Id$|Code$)/,
          "",
        ),
      };
    }
  }

  return result;
}

export function parseParts(parsedSentence) {
  return parsedSentence.split(/\s+/);
}

export function parseEntryPattern(entryPattern, solrAttributeYear) {
  solrAttributeYear = solrAttributeYear || "CallDecisionYear";
  // replace all newlines in input entryPattern
  // and empty space between single patterns
  entryPattern = entryPattern.replace(/(?:\r\n|\r|\n)/g, "");
  entryPattern = entryPattern.replace(/,\s*{/g, ",{");

  // split input part "*2[{all;FundingInstrumentReportingId;00000000-0000-0000-0000-000000000000}]" -> *2, {all;...}
  const reFilterSentence = /(.*?)(\[.*?])/;
  // eslint-disable-next-line no-unused-vars
  let [_ignore, patternId, selectionPattern] = reFilterSentence.exec(entryPattern);
  patternId = patternId.trim();

  // split entries and remove square brackets {}
  const patternNoBrackets = selectionPattern.substring(1, selectionPattern.length - 1);
  const entriesUnparsed = _.map(patternNoBrackets.split("},"), (s) =>
    s.replace(/[{}]/g, ""),
  );

  const result = {
    id: patternId,
    selectionPattern: selectionPattern,
    selectionValues: _.flatMap(entriesUnparsed, (e, index) => {
      const parts = e.split(";");

      if (parts.length === 3) {
        return {
          id: patternId,
          index: index,
          text: parts[0].trim(),
          solrAttribute: parts[1].trim(),
          solrValue: parts[2].trim(),
        };
      } else if (parts.length === 2) {
        return {
          id: patternId,
          index: index,
          text: parts[0].trim(),
          solrAttribute: "",
          solrValue: parts[1],
        };
      } else if (parts.length === 1) {
        if (parts[0].indexOf("--") !== -1) {
          // year part
          const [startYearStr, endYearStr] = parts[0].split("--");
          const startYear = parseInt(startYearStr, 10);
          const endYear = parseInt(endYearStr, 10);
          return _.map(_.range(startYear, endYear - 1, -1), (year, index) => {
            return {
              id: patternId,
              index: index,
              text: year.toString(),
              solrAttribute: solrAttributeYear,
              solrValue: year,
            };
          });
        }
        if (parts[0].indexOf("==") !== -1) {
          // VALUES_FROM_SOLR part (used in pandemic filter sentence)
          return {
            id: patternId,
            index: index,
            text: "VALUES_FROM_SOLR",
            solrAttribute: parts[0].split("==")[0],
            solrValue: "VALUES_FROM_SOLR",
          };
        }
      }
    }),
  };

  const solrFacetSelectionValue = _.find(result.selectionValues, (v) => {
    return v.solrValue === "VALUES_FROM_SOLR";
  });

  result.solrFacet = solrFacetSelectionValue
    ? solrFacetSelectionValue.solrAttribute
    : "";

  return result;
}
