<template>
  <div>
    <section class="snf-section background__blue-light" v-if="cmsPageDataObject">
      <div class="snf-section__columns columns home__columns">
        <div class="column">
          <div class="title-column">
            <h1 class="key-figures-title" v-html="titleText"></h1>
            <p class="lead" v-html="cfKpiSentence" />
          </div>

          <div class="key-figures-tiles">
            <div
              v-for="subPage in cmsPageDataArray.filter(
                (p) => p.pageType === 'CoreFigures',
              )"
              :key="subPage.pageTitle"
              class="key-figure-tile"
            >
              <div class="key-figure-tile__img">
                <img :src="subPage.icon" alt="icon" />
              </div>
              <div class="key-figure-tile__text">
                <h4 class="key-figure-tile__title">
                  {{ subPage.pageTitle }}
                </h4>
                <router-link
                  :key="subPage.title"
                  :to="subPage.url"
                  class="explore-link"
                >
                  {{ cmsTranslationByKey("CoreFiguresExploreData") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="snf-section" v-if="cmsPageDataObject">
      <div class="snf-section__columns columns home__columns">
        <div class="column bar-chart-column">
          <div class="text">
            <h3>{{ cfTitleSentence }}</h3>
            <div class="lead" v-html="cfResultSentence"></div>
            <div style="margin-top: 0.5em">
              <a href="/key-figures/funding-instruments" class="explore-link">
                {{ cmsTranslationByKey("CoreFiguresExploreData") }}
              </a>
            </div>
          </div>

          <div class="chart">
            <div class="bar-chart">
              <div
                class="bar"
                v-for="item in cfPictureData"
                :key="item.label"
                :style="{
                  height: (item.Funding / cfPictureData[0].Funding) * 200 + 'px',
                }"
              >
                <div class="bar-number">
                  {{ numberToStringFormat(item.Funding) }}
                </div>

                <div
                  class="bar-label"
                  :style="{
                    top: (item.Funding / cfPictureData[0].Funding) * 200 + 4 + 'px',
                  }"
                >
                  {{
                    item.label === "Wissenschaftskommunikation"
                      ? "Wissenschafts- kommunikation"
                      : item.label
                  }}
                </div>
              </div>
            </div>

            <div class="bar-legend">
              <div
                class="bar-legend-item"
                v-for="item in cfPictureData"
                :key="item.label"
              >
                <div class="dot"></div>
                <div>{{ item.label }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as _ from "lodash";
import { cmsTranslationMixin } from "@/mixins";
import { fetchFullKeyFiguresData, loadKpiSentence } from "@/solrHelpers";

export default {
  name: "KeyFiguresHome",
  mixins: [cmsTranslationMixin],
  components: {},
  metaInfo: function () {
    if (this.cmsPageDataObject) {
      return {
        title: this.cmsTranslationByKey("TitleCoreFigures"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleCoreFigures"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cfKpiSentenceWithoutTags,
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_key_figures.png`,
          },
        ],
      };
    }
    return {};
  },
  data: function () {
    return {
      cfKpiSentence: "",
      cfKpiSentenceWithoutTags: "",
      cfPageData: {},
      cfPictureData: [],
      cfResultSentence: "",
      cfTitleSentence: "",
    };
  },
  mounted: function () {
    this.loadPageData();
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "cmsData",
      "screenSize",
      "cmsPageDataArray",
      "cmsPageDataObject",
    ]),
    cfKpiSentenceShort: function () {
      return this.cfKpiSentence
        .replace(/Informieren Sie sich.*/, "")
        .replace(/Pour en savoir.*/, "")
        .replace(/You can find more.*/, "");
    },
    titleText: function () {
      return this.cmsTranslationByKey("TitleCoreFigures")
        .replace(
          "Swiss National Science Foundation",
          '<span class="tablet-no-wrap">Swiss National Science Foundation</span>',
        )
        .replace(
          "Fonds national Suisse",
          '<span class="tablet-no-wrap">Fonds national Suisse</span>',
        )
        .replace(
          "Schweizerischen Nationalfonds",
          '<span class="tablet-no-wrap">Schweizerischen Nationalfonds</span>',
        );
    },
  },
  watch: {
    $route(to, from) {
      this.loadPageData();
    },
    selectedLanguage: function (val) {
      this.loadPageData();
    },
    cmsData: function (val) {
      this.loadPageData();
    },
  },
  methods: {
    loadPageData: function () {
      if (this.selectedLanguage && this.cmsPageDataArray.length > 0) {
        this.cfPageData = _.find(this.cmsPageDataArray, {
          entityUrl: _.camelCase("funding-instruments"),
        });

        this.changeLanguage();
      }
    },
    changeLanguage: function () {
      fetchFullKeyFiguresData(this.selectedLanguage, this.cfPageData, {}, true).then(
        (result) => {
          this.cfPictureData = result.cfPictureData;
          this.cfResultSentence = result.cfResultSentence;

          this.cfTitleSentence = result.cfParseSentenceResult.parsedSentence;
          _.forEach(result.cfSelectedParts, (part) => {
            this.cfTitleSentence = this.cfTitleSentence.replace(part.id, part.text);
          });
        },
      );

      loadKpiSentence(this.selectedLanguage).then((kpiSentence) => {
        this.cfKpiSentence = kpiSentence;
      });
      loadKpiSentence(this.selectedLanguage, false).then((kpiSentence) => {
        this.cfKpiSentenceWithoutTags = kpiSentence;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

.home__columns {
  margin: 0 auto;
  max-width: 1024px;
}

.title-section {
  border-top: 1px solid $snf-gray-light;
}

.title-column {
  max-width: 624px;

  h1 {
    font-size: 36px;
  }
}

.lead {
  font-size: 20px;
  line-height: 32px;
}

.explore-link {
  color: inherit;
  font-weight: 700;
  border-bottom: 2px solid;
  text-decoration: none;

  &:hover {
    color: $snf-blue-font;
  }
}

.bar-chart {
  display: flex;
  align-items: flex-end;
  height: 220px;
  margin-top: 32px;

  .bar {
    position: relative;
    background: $snf-blue-light;
    flex: 1 1 20%;
    margin-right: 4px;

    &:nth-child(2) {
      background: $snf-yellow;
    }

    &:nth-child(3) {
      background: $snf-green;
    }

    &:nth-child(4) {
      background: $snf-violet;
    }

    &:nth-child(5) {
      background: $snf-red-light;
    }

    .bar-number {
      position: absolute;
      top: -24px;
      text-align: center;
      width: 100%;
      font-weight: 700;
      white-space: nowrap;
    }

    .bar-label {
      display: none;
      position: absolute;
      text-align: center;
      width: 100%;
      font-size: 14px;
      color: $snf-black;
    }
  }
}

.bar-legend {
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .bar-legend-item {
    margin: 0.25em 1em;
    display: flex;
    align-items: center;

    .dot {
      background-color: $snf-blue-light;
    }

    &:nth-child(2) .dot {
      background-color: $snf-yellow;
    }

    &:nth-child(3) .dot {
      background-color: $snf-green;
    }

    &:nth-child(4) .dot {
      background-color: $snf-red-light;
    }

    &:nth-child(5) .dot {
      background-color: $snf-violet;
    }
  }

  .dot {
    height: 16px;
    width: 16px;
    background-color: #bbb;
    border-radius: 50%;
    line-height: 24px;
    display: inline-block;
    margin-right: 8px;
  }
}

.key-figure-tile {
  border: 1px solid white;
  border-bottom: none;
  display: flex;
  padding: 24px;
  background: white;

  &:last-child {
    border-bottom: 1px solid white;
  }

  &__title {
    @include mobile() {
      font-size: 18px;
    }
  }

  &__text {
    margin-left: 24px;
    flex: 1 1 auto;
  }

  &__img {
    flex: 0 0 60px;
  }
}

@include tablet() {
  .home__columns {
    margin: 1em auto !important;
  }

  .title-section {
    border-top: 1px solid $snf-gray-medium;
  }

  .title-column h1 {
    font-size: 48px;
  }

  .bar-chart-column {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 48px 0;

    .text {
      flex: 1 0 40%;
      margin-left: 16px;
    }

    .chart {
      flex: 1 0 40%;
      order: -1;
      margin-right: 16px;

      .bar-chart {
        margin-top: 0;
      }
    }
  }

  .key-figures-tiles {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .key-figure-tile {
    width: 40%;
    flex: 1 0 auto;
    border-bottom: 1px solid white;
    margin-top: 16px;
    &:nth-child(odd) {
      margin-right: 8px;
    }
    &:nth-child(even) {
      margin-left: 8px;
    }
  }
}

.background__blue-light {
  background: $snf-blue-light-70;
}

.background__blue-light-background {
  background: $snf-blue-light-background;
}

@include desktop() {
  .bar-chart-column {
    .chart {
      .bar-legend {
        display: none;
      }

      .bar-label {
        display: block;
      }
    }
  }
}
</style>
