<template>
  <div>
    <table class="data-table">
      <thead class="data-table__header">
        <tr class="data-table__header-row">
          <th v-for="heading in header" :key="heading" class="data-table__heading">
            {{ heading }}
          </th>
        </tr>
      </thead>
      <tbody class="data-table__body">
        <tr v-for="row in dataWithTotalAtEnd" :key="row.id" class="data-table__row">
          <td
            v-for="(key, index) in columnIds"
            :key="key"
            class="code data-table__cell cell"
            :style="cellBackgroundColor(row[key], key, row)"
          >
            <span
              class="cell__heading"
              :class="{
                'cell__heading-hide': formatDisplayData(row[key])
                  ? formatDisplayData(row[key]).length > 8
                  : false,
              }"
            >
              {{ header[index] }}
            </span>
            <span
              class="cell__content code"
              :class="{
                'cell__content-full': formatDisplayData(row[key])
                  ? formatDisplayData(row[key]).length > 8
                  : false,
              }"
            >
              {{ formatDisplayData(row[key]) }}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import * as _ from "lodash";
import { tweenColor } from "@/utils";

const idSeparator = "|";

export default {
  name: "DataTable",
  props: {
    data: Array,
    withGradient: {
      type: Boolean,
      default: false,
    },
    lang: {
      type: String,
      default: "de",
    },
  },
  data: function () {
    return {
      minColorRgb: [0xd9, 0xf0, 0xfb],
      middleColorRgb: [0x82, 0xcf, 0xf4],
      maxColorRgb: [0x04, 0x55, 0x75],
    };
  },
  computed: {
    columnIds() {
      return this.propertyToArray("Original");
    },
    header() {
      return this.propertyToArray(this.lang.toUpperCase());
    },
    dataWithTotalAtEnd() {
      if (this.data) {
        const totalRow = this.data.find((row) => row.Forschungseinrichtung === "Total");

        if (totalRow) {
          const dataWithoutTotal = this.data.filter(
            (row) => row.Forschungseinrichtung !== "Total",
          );
          return [...dataWithoutTotal, totalRow];
        } else {
          return this.data;
        }
      }
      return [];
    },
  },
  methods: {
    propertyToArray(propName) {
      if (this.dataWithTotalAtEnd.length > 0 && propName in this.data[0]) {
        return this.dataWithTotalAtEnd[0][propName]
          .split(idSeparator)
          .map((id) => id.trim());
      } else {
        return [];
      }
    },
    formatDisplayData(data) {
      const number = Number(data);

      if (isNaN(number)) {
        return data;
      }

      return number.toFixed(2);
    },
    secondMaxByColumn(columnKey) {
      const values = this.dataWithTotalAtEnd
        .map((row) => parseFloat(row[columnKey]))
        .filter((value) => _.isFinite(value));
      return values.sort((a, b) => b - a)[1];
    },
    cellBackgroundColor(value, columnKey, rowData) {
      const result = {};

      if (
        rowData &&
        rowData.Forschungseinrichtung &&
        rowData.Forschungseinrichtung === "Total"
      ) {
        return {};
      }

      if (["MINT", "LS", "SSH", "Total"].includes(columnKey)) {
        const numberValue = parseFloat(value);
        const columnMax = this.secondMaxByColumn("Total");
        if (
          this.withGradient &&
          this.withGradient &&
          _.isFinite(numberValue) &&
          numberValue > 0 &&
          columnMax > 0
        ) {
          const percent = Math.min(1.0, Math.max(0, numberValue / columnMax));
          const percentRgb = tweenColor(
            this.minColorRgb,
            this.middleColorRgb,
            this.maxColorRgb,
            percent,
            0.25,
          );
          result[
            "background-color"
          ] = `rgb(${percentRgb[0]}, ${percentRgb[1]}, ${percentRgb[2]})`;

          if (percent > 0.3) {
            result["color"] = "white";
          }
        }
      }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/bulma_utils";
@import "../assets/css/colors";

/* inspiration from https://css-tricks.com/responsive-data-tables/ */

$padding: 10px;

.data-table {
  table-layout: fixed;
  width: 100%;

  @include mobile() {
    display: block;
  }

  &__header {
    background-color: $snf-white;
    border-bottom: 1px solid $snf-gray-medium;

    @include mobile() {
      display: block;
    }
  }

  &__header-row {
    @include mobile() {
      display: block;
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  }

  &__heading {
    padding: $padding;

    text-align: right;
    &:first-of-type {
      text-align: left;
    }
  }

  &__body {
    background-color: $snf-white;

    @include mobile() {
      display: block;
    }
  }

  &__row {
    border-bottom: 1px solid #ddd;

    &:last-of-type {
      border: none;
    }

    @include mobile() {
      display: block;
    }
  }

  &__cell {
    padding: $padding;

    text-align: right;

    &:first-of-type {
      text-align: left;
    }
  }
}

.cell {
  border: none;

  @include mobile() {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $snf-gray-light;
    text-align: left;
  }

  &__heading {
    display: none;

    @include mobile() {
      display: block;
      flex: 0 0 80%;
      text-align: left;
      padding-right: $padding;

      &-hide {
        display: none;
      }
    }
  }

  &__content {
    @include mobile() {
      display: block;
      flex: 0 0 20%;
      text-align: left;

      &-full {
        flex: 0 0 100%;
      }
    }
  }
}

/* make IE11 great again https://stackoverflow.com/questions/20541306/how-to-write-a-css-hack-for-ie-11 */

_:-ms-fullscreen,
.cell {
  @include mobile() {
    display: block;
    border-bottom: 1px solid $snf-gray-light;
  }

  &__heading {
    display: none;

    @include mobile() {
      display: block;
      flex: 0 0 35%;
      text-align: left;
      padding-right: $padding;
    }
  }

  &__content {
    @include mobile() {
      display: block;
      flex: 0 0 65%;
      text-align: left;
    }
  }
}
</style>
