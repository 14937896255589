<template>
  <header class="snf-section cf-header content-header" :class="[colorClass]">
    <div class="columns snf-section__columns">
      <div class="content-header__section contentheader-section column">
        <h1 class="contentheader-section__title cf-heading">
          {{ title }}
        </h1>
      </div>
      <nav class="content-header__section column">
        <div class="cf-description" v-html="text"></div>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: "CoreFiguresHeader",
  props: ["title", "text", "colorClass"],
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/bulma_utils";
@import "../../assets/css/colors";

.cf-header {
  color: $snf-blue-dark;

  .cf-description {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: normal;

    a {
      color: $snf-blue-dark;
      text-decoration: underline;
    }
  }

  @include tablet {
    min-height: 10em;
  }

  &--color-1 {
    background-color: $snf-blue-light-70;
  }
}
</style>
