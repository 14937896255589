var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('table',{staticClass:"data-table"},[_c('thead',{staticClass:"data-table__header"},[_c('tr',{staticClass:"data-table__header-row"},_vm._l((_vm.header),function(heading){return _c('th',{key:heading,staticClass:"data-table__heading"},[_vm._v(" "+_vm._s(heading)+" ")])}),0)]),_c('tbody',{staticClass:"data-table__body"},_vm._l((_vm.dataWithTotalAtEnd),function(row){return _c('tr',{key:row.id,staticClass:"data-table__row"},_vm._l((_vm.columnIds),function(key,index){return _c('td',{key:key,staticClass:"code data-table__cell cell",style:(_vm.cellBackgroundColor(row[key], key, row))},[_c('span',{staticClass:"cell__heading",class:{
              'cell__heading-hide': _vm.formatDisplayData(row[key])
                ? _vm.formatDisplayData(row[key]).length > 8
                : false,
            }},[_vm._v(" "+_vm._s(_vm.header[index])+" ")]),_c('span',{staticClass:"cell__content code",class:{
              'cell__content-full': _vm.formatDisplayData(row[key])
                ? _vm.formatDisplayData(row[key]).length > 8
                : false,
            }},[_vm._v(" "+_vm._s(_vm.formatDisplayData(row[key]))+" ")])])}),0)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }