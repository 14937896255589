<template>
  <footer class="footer">
    <div class="snf-section">
      <div class="snf-section__columns columns">
        <div class="column column__contact">
          <div class="footer-link">
            <a
              class="footer-list__link"
              target="_blank"
              :href="getContactUrlInLanguage()"
            >
              {{ getContactNameInLanguage() }}
            </a>
          </div>
          <!--
          <div class="footer-link">
            <router-link
              class="footer-list__link"
              to="/impressum"
            >
              Data Sharing Policy
            </router-link>
          </div>
          -->
          <div class="footer-link">
            <router-link class="footer-list__link" to="/impressum">
              Impressum
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import { cmsTranslationMixin } from "@/mixins";

export default {
  name: "CfFooter",
  mixins: [cmsTranslationMixin],
  components: {},
  computed: {
    ...mapGetters(["selectedLanguage"]),
  },
  methods: {
    getContactUrlInLanguage() {
      if (this.selectedLanguage === "en") {
        return "https://www.snf.ch/en/X5jbA5udARKhcIk8/page/aboutus/contact";
      } else if (this.selectedLanguage === "fr") {
        return "https://www.snf.ch/fr/X5jbA5udARKhcIk8/page/apropos/contact";
      }
      return "https://www.snf.ch/de/X5jbA5udARKhcIk8/page/ueberuns/kontakt";
    },
    getContactNameInLanguage() {
      if (this.selectedLanguage === "en") {
        return "Contact";
      } else if (this.selectedLanguage === "fr") {
        return "Contact";
      }
      return "Kontakt";
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/css/bulma_utils";
@import "../assets/css/colors";

.footer {
  background-color: white;
  color: $snf-black;
  border-top: 1px solid $snf-gray-light;

  a:hover {
    color: $snf-red-light;
  }

  .divider {
    margin-left: 1em;
    margin-right: 1em;
  }

  .column__contact {
    a {
      &:hover {
        color: $snf-red-light;
      }
    }

    display: flex;
    flex-direction: row-reverse;

    .footer-link {
      margin-left: 2em;
      margin-top: 8px;
      margin-bottom: 8px;
    }
  }

  @include mobile() {
    a {
      display: block;
    }
    .divider {
      margin: 0;
    }
    .column__contact {
      text-align: left;
      flex-direction: column-reverse;

      .footer-link {
        margin-left: 0;
      }
    }
    height: 100%;
  }
}
</style>
