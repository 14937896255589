<template>
  <div class="data-story-index" :lang="selectedLanguage">
    <section
      id="tag-filter"
      class="tag-filter"
      :class="{
        'tag-filter--visible': dataStoriesTagFilterShown,
      }"
    >
      <div>
        <div class="tag-filter-header">
          <h2 class="tag-filter-title">
            {{ cmsTranslationByKey("FilterTagsMenuTitle") }}
          </h2>
          <div class="tag-filter-close">
            <a
              role="button"
              class="tag-filter-close-button"
              @click="setDataStoriesTagFilterShown(false)"
            ></a>
          </div>
        </div>
        <div class="tag-filter-list">
          <a
            class="tag-filter-tag"
            :class="[
              selectedTag && tag.TagId === selectedTag.TagId
                ? 'tag-filter-tag--selected'
                : '',
            ]"
            v-for="tag in sortedTagsUsed"
            :key="tag.TagId"
            @click="selectTag(tag)"
          >
            {{ tag.label }}
          </a>
        </div>
      </div>
    </section>

    <section class="ds-section title-section" v-if="cmsPageDataArray.length > 0">
      <div class="data-story-header">
        <h1 class="data-story-title">
          {{ cmsTranslationByKey("TitleDataStories") }}
        </h1>

        <div class="data-story-filter" @click="clickTagFilter()">
          <span>
            {{ cmsTranslationByKey("FilterTagsButton") }}
            {{
              selectedTag.TagId
                ? selectedTag.label
                : cmsTranslationByKey("FilterTagsMenuAll")
            }}
          </span>

          <span style="float: right">
            <img
              :src="selectedTag.TagId ? '/img/close.svg' : '/img/arrow-right.svg'"
              width="16"
              height="16"
            />
          </span>
        </div>
      </div>
    </section>

    <Loader v-if="showLoader"></Loader>
    <section v-else class="ds-section story-container">
      <div
        class="story-tile"
        :class="[
          story.cssClass,
          index === 0 || (story.ThumbnailExtension && index % 3 === 0)
            ? 'story-tile--tall'
            : '',
          story.ThumbnailExtension && index % 3 === 1 ? 'story-tile--wide' : '',
          story.ThumbnailExtension && index % 3 === 2 ? 'story-tile--wide-reverse' : '',
        ]"
        v-for="(story, index) in stories"
        :key="story.name"
        @click="openStory(story)"
      >
        <router-link
          :to="{
            name: 'DataStory',
            params: {
              htmlFilename: story.Name,
            },
          }"
          class="story-tile-image"
          v-show="index !== 0"
        >
          <div
            v-if="story.ThumbnailLink"
            class="story-tile-img__img"
            :style="{
              'background-image': `url(${story.ThumbnailLink})`,
              'min-height': '200px',
            }"
          ></div>
          <div
            v-else-if="story.ThumbnailExtension"
            class="story-tile-img__img"
            style="min-height: 200px"
          ></div>
        </router-link>

        <div class="story-tile-text">
          <div class="story-category">
            <span v-if="story.TopStory">Feature Story</span>
          </div>

          <h2 class="story-title" :class="{ 'story-title--main': index === 0 }">
            <router-link
              :to="{
                name: 'DataStory',
                params: {
                  htmlFilename: story.Name,
                },
              }"
            >
              {{ story.title }}
            </router-link>
          </h2>

          <p class="story-description" v-show="index === 0">
            {{ story.shortDescription }}
          </p>

          <div class="story-author">
            {{ story.PublicationDate | dateFormat }}
          </div>

          <div class="story-link">
            <!--            <router-link-->
            <!--              :to="{-->
            <!--                name: 'DataStory',-->
            <!--                params: {-->
            <!--                  htmlFilename: story.Name,-->
            <!--                },-->
            <!--              }"-->
            <!--            >-->
            <!--              {{ cmsTranslationByKey("ButtonToStory") }}-->
            <!--              {{ readOnLanguagesText(story.Languages) }}-->
            <!--            </router-link>-->
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import * as _ from "lodash";
import * as log from "loglevel";
import { loadDataStories } from "../solrHelpers";
import { mapActions, mapGetters } from "vuex";
import { cmsTranslationMixin } from "../mixins";
import Loader from "@/components/Loader";

export default {
  name: "DataStoryIndex",
  mixins: [cmsTranslationMixin],
  components: { Loader },
  data: function () {
    return {
      stories: [],
      selectedTag: {
        TagId: null,
      },
      showLoader: false,
    };
  },
  metaInfo: function () {
    if (this.cmsTranslationData) {
      return {
        title: this.cmsTranslationByKey("TitleDataStories"),
        meta: [
          {
            vmid: "og:title",
            property: "og:title",
            content: this.cmsTranslationByKey("TitleDataStories"),
          },
          {
            vmid: "og:description",
            property: "og:description",
            content: this.cmsTranslationByKey("DataStoriesIntro"),
          },
          {
            vmid: "og:image",
            property: "og:image",
            content: `${window.location.origin}/img/meta_image_data_stories.png`,
          },
        ],
      };
    }
    return {};
  },
  mounted: function () {
    this.showLoader = true;
    if (!this.dataStoriesTags.length) {
      this.loadingDataStoriesTags = true;
      this.loadDataStoriesTags().then(() => {
        this.loadPageData();
        this.loadingDataStoriesTags = false;
      });
    } else {
      this.loadPageData();
    }
  },
  computed: {
    ...mapGetters([
      "selectedLanguage",
      "cmsData",
      "cmsPageDataArray",
      "screenSize",
      "dataStoriesTagFilterShown",
      "dataStoriesTags",
    ]),
    sortedTagsUsed: function () {
      return [
        {
          label: this.cmsTranslationByKey("FilterTagsMenuAll"),
          TagId: null,
        },
        ..._.orderBy(
          _.map(_.filter(this.dataStoriesTags, { usedInStory: true }), (t) => {
            if (this.selectedLanguage === "en") {
              t.label = t.English;
            } else if (this.selectedLanguage === "fr") {
              t.label = t.French;
            } else {
              t.label = t.German;
            }

            t.capitalizedLabel = _.capitalize(t.label);

            return t;
          }),
          ["capitalizedLabel"],
          ["asc"],
        ),
      ];
    },
  },
  watch: {
    $route(to, from) {
      log.debug("$route", to, from);
      this.loadPageData();
    },
    screenSize: function (val) {
      log.debug("watch screenSize", val);
    },
    selectedLanguage: function (val) {
      log.debug("watch selectedLanguage", val);
      if (this.loadingDataStoriesTags) {
        // CF-739: Workaround when data stories are still loading do not make a new `loadPageData`
        // the promise in mounted will do it
        return;
      }
      this.loadPageData();
    },
  },
  methods: {
    ...mapActions(["setDataStoriesTagFilterShown", "loadDataStoriesTags"]),

    selectTag(tag) {
      this.selectedTag = tag;
      this.loadStories();
      this.setDataStoriesTagFilterShown(false);

      if (this.selectedTag.TagId) {
        if (this.$route.query.tag !== this.selectedTag.TagId) {
          this.$router.push({ query: { tag: this.selectedTag.TagId } }).catch(() => {});
        }
      } else {
        if (this.$route.query.tag) {
          this.$router.push({ query: {} }).catch(() => {});
        }
      }
    },

    clickTagFilter() {
      if (this.selectedTag.TagId) {
        this.selectTag(this.sortedTagsUsed[0]);
      } else {
        this.setDataStoriesTagFilterShown(true);
      }
    },

    loadPageData: function () {
      if (this.selectedLanguage) {
        this.changeLanguage();
      }
    },

    loadStories: function () {
      this.showLoader = true;

      loadDataStories(
        this.selectedLanguage,
        this.selectedTag && this.selectedTag.TagId ? [this.selectedTag.TagId] : null,
      ).then((stories) => {
        /*
        const initialStories = _.cloneDeep(stories);
        _.forEach(_.range(5), () => {
          stories = stories.concat(_.cloneDeep(initialStories));
        });
        */

        this.stories = _.map(stories, (s, index) => {
          s.cssClass = "bg-story-default";

          if (index === 0) {
            s.cssClass = `bg-story-main`;
          } else {
            const cssClassPostfixes = ["0", "1", "2", "3"];
            s.cssClass = `bg-story-${
              cssClassPostfixes[(index - 1) % cssClassPostfixes.length]
            }`;
          }

          return s;
        });

        this.showLoader = false;
      });
    },

    changeLanguage: function () {
      const routeTag =
        _.find(this.sortedTagsUsed, { TagId: this.$route.query.tag }) ||
        this.sortedTagsUsed[0];
      this.selectTag(routeTag);
    },

    openStory: function (story) {
      this.$router.push({ name: "DataStory", params: { htmlFilename: story.Name } });
    },
  },
};
</script>

<style lang="scss" scoped="true">
@import "../assets/css/colors";
@import "../assets/css/mixins";
@import "../assets/css/bulma_utils";

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.data-story-index {
  border-top: $snf-gray-light 1px solid;
  hyphens: auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 700;
  }

  margin-bottom: -16px;
}

.title-section {
}

.data-story-title {
  font-size: 40px;
  margin: 8px 16px;
  padding: 8px 8px;
}

.data-story-filter {
  margin: 8px 16px;
  padding: 0 8px;
  border-bottom: $snf-gray-medium 1px solid;
  cursor: pointer;
}

.story-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 1em;
}

.story-tile {
  background: white;
  width: 100%;
  position: relative;
  padding: 8px;

  .story-tile-text {
    padding: 16px 24px;
  }

  .story-tile-image {
    display: block;
  }

  .story-tile-img__img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .story-title {
    font-size: 28px;
    line-height: 1.14em;
    margin-bottom: 0.25em;
  }

  .story-title--main {
    font-size: 36px;
  }

  .story-description {
    margin-top: 1em;
    line-height: 1.3em;
    font-size: 20px;
  }

  .story-category {
    text-transform: capitalize;
  }

  .story-link {
    margin-top: 2em;
    margin-bottom: 1em;

    a {
      border: $snf-black 1px solid;
      padding: 12px;

      &:hover {
        color: white;
        border-color: $snf-blue-dark;
        background: $snf-blue-dark;
      }
    }
  }
}

@include tablet() {
  .story-tile {
    width: 50%;
  }
}

@include desktop() {
  .ds-section {
    margin-left: auto;
    margin-right: auto;
    max-width: 1426px;
  }

  .story-tile {
    width: 33.3333%;

    transition: transform 0.3s ease;
    &:hover {
      transform: scale(1.015);
      cursor: pointer;
    }

    .story-title {
      font-size: unquote("min(28px, 2.1vw)");
    }

    .story-title--main {
      font-size: unquote("min(36px, 2.5vw)");
    }

    .story-description {
      font-size: unquote("min(20px, 1.6vw)");
    }
  }

  @supports (display: grid) {
    $height: 260px;

    .data-story-header {
      display: flex;
      justify-content: space-between;
    }

    .data-story-title {
      font-size: 48px;
      margin: 24px 16px;
    }

    .data-story-filter {
      width: 30%;
      margin: 0 24px;
      border-bottom: $snf-gray-medium 1px solid;
      height: 24px;
      align-self: center;
    }

    .story-container {
      //column-count: 3;
      //column-gap: 0;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-auto-rows: $height;
      grid-auto-flow: row dense;
    }

    $story-tile-padding: 8px;
    $story-tile-margin: 8px;

    .story-tile {
      margin: 8px;
      height: $height - 2 * $story-tile-margin;
      width: calc(100% - 2 * 8px); // 2 * $story-tile-margin;
      overflow: hidden;
      grid-column: span 1;
      padding: $story-tile-padding;
      grid-row: span 1;
      background-color: beige;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .story-link {
        position: absolute;
        bottom: 24px;
      }

      &--tall {
        height: 2 * $height - 2 * $story-tile-margin;
        grid-row: span 2;

        .story-tile-image {
          min-height: $height - 2 * $story-tile-padding - 2 * $story-tile-margin;
          margin-bottom: $story-tile-padding;
        }

        .story-tile-text {
          margin-top: $story-tile-padding + $story-tile-margin;
        }
      }

      &--wide {
        grid-column: span 2;
        flex-direction: row;

        .story-tile-image {
          width: 100%;
          min-width: calc(50% - 8px);
          margin-right: $story-tile-padding;
        }

        .story-tile-text {
          width: 100%;
          margin-left: $story-tile-padding;
        }
      }

      &--wide-reverse {
        grid-column: span 2;
        flex-direction: row-reverse;

        .story-tile-image {
          width: 100%;
          min-width: calc(50% - 8px);
          margin-left: $story-tile-padding;
        }

        .story-tile-text {
          width: 100%;
          margin-right: $story-tile-padding;
        }
      }
    }
  }
}

.bg-story-main {
  background: $snf-blue-dark;
  color: white;

  .story-tile-img__img {
    background-color: darken($snf-blue-dark, 20);
  }

  .story-link {
    a {
      border: white 1px solid;

      &:hover {
        color: $snf-blue-dark;
        border-color: $snf-blue-dark;
        background: white;
      }
    }
  }

  a {
    color: white;
  }
}

.bg-story-0 {
  background: $snf-red-light;

  .story-tile-img__img {
    background-color: darken($snf-red-light, 10);
  }
}

.bg-story-1 {
  background: $snf-yellow;

  .story-tile-img__img {
    background-color: darken($snf-yellow, 10);
  }
}

.bg-story-2 {
  background: $snf-green-light;

  .story-tile-img__img {
    background-color: darken($snf-green-light, 10);
  }
}

.bg-story-3 {
  background: $snf-blue-light;

  .story-tile-img__img {
    background-color: darken($snf-blue-light, 10);
  }
}

.tag-filter {
  height: 100%;
  width: 0;
  max-width: 100vw;
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  background-color: white;
  overflow-x: hidden;
  margin-top: 76px;
  transition: 0.5s;
  border-left: $snf-gray-medium 1px solid;
  border-top: $snf-gray-medium 1px solid;
  visibility: visible;

  @include mobile() {
    margin-top: 0;
    transition: 0.2s;
  }
}

.tag-filter--visible {
  visibility: visible;
  width: 500px;
}

.tag-filter-header {
  display: flex;
  justify-content: space-between;
}

.tag-filter-title {
  font-size: 32px;
  margin: 20px 32px;
}

.tag-filter-close {
  margin: 0;
}

.tag-filter-close-button {
  position: absolute;
  right: 32px;
  top: 26px;
  width: 32px;
  height: 32px;

  &:hover {
    opacity: 0.5;
  }

  &:before,
  &:after {
    position: absolute;
    left: 15px;
    content: " ";
    height: 33px;
    width: 2px;
    background-color: $snf-black;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.tag-filter-list {
  margin: 20px;
  display: flex;
  flex-wrap: wrap;
}

.tag-filter-tag {
  border: $snf-gray-dark 1px solid;
  padding: 6px 12px;
  margin: 8px;

  &--selected {
    background: $snf-blue-dark;
    color: white;
    font-weight: 700;
  }

  &:hover {
    color: white;
    border-color: $snf-blue-dark;
    background: $snf-blue-dark;
  }
}
</style>
